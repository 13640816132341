
export type PortfolioReserveUserPreferences = {
    profileId?: string;
    editablePortfolioReserve?: boolean;
    clickedPortfolioReserveTargetLengthInput?: boolean;
    clickedLifestyleMinimumRiskInput?: boolean;
    editedMinimumRisk?: boolean;
    navigatedBeforeSavingPortfolioReserve?: boolean;
    isMonteCarloLoading?: boolean;
}

export type PortfolioReserveOptions = {
    showReserveTargetDetails:  boolean;
    showRiskImpact:  boolean;
    showOutcomeRange: boolean;
    monteCarloSelectedPercentile: number
}

export const emptyPortfolioReserveOptions : PortfolioReserveOptions = {
    showReserveTargetDetails:  false,
    showRiskImpact:  false,
    showOutcomeRange: false,
    monteCarloSelectedPercentile: 5
}

export const emptyPortfolioReserveUserPreferences: PortfolioReserveUserPreferences = {
    editablePortfolioReserve: false
}
