import {useHistory, useLocation, useParams} from "react-router-dom";
import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import React, {useContext, useEffect, useRef, useState} from "react";
import AssetsViewContext from "../../common/AssetsViewContext";
import {RouteWithAssetId, RouteWithProfileIdAndAssetId} from "../../../routes/types";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {DomesticTrustAccount, LegalAgreement} from "../../models/InvestmentProgram";
import {PartiallyOwnedInvestmentAccountDetails} from "./PartiallyOwnedInvestmentAccountDetails";
import TaxDetails from "../../TaxDetails/TaxDetails";
import {TaxDetailsType} from "../../models/TaxDetails";
import {assetsApiClient} from "../../AssetsApiClient";
import {HoldingSummary} from "../../models/Holding";
import Ownership from "../../Ownership";
import {
    getDefaultOwnershipDetails,
    getTaxableLiabilityPaidByPortfolioForDeferredAccounts,
    getTaxableLiabilityPaidByPortfolioForTaxableAccounts
} from "../../formHelpers";
import {useAppSelector} from "../../../store/hooks";
import {selectProfile} from "../../../ClientManagement/ClientProfile/activeProfileSlice";
import {LegalEntityFormData, OwnershipDetailsFormData} from "../../models/Ownership";
import {MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import {clientManagementApiClient} from "../../../ClientManagement/ClientManagementApiClient";
import {AccountSummary} from "../../AccountsCommon/AccountSummary";
import {AssetClassifications} from "../../models/AssetClassifications";
import {mapToOwnershipDetailsFormData, mapToOwnershipWriteModel} from "../../Ownership/mappers";
import {validateOwnershipDetails} from "../../Ownership/validation";
import {RequiredFieldsBanner} from "../../../components";
import AlertBanner from "../../../components/AlertBanner/AlertBanner";
import {
    PartiallyOwnedInvestmentAccountReadModel,
    PartiallyOwnedInvestmentAccountWriteModel
} from "../../models/PartiallyOwnedInvestmentAccount";
import {NO_OP} from "../../../constants/common";
import {selectClientAssets} from "../../clientAssetsSlice";

export function EditPartiallyOwnedInvestment() {
    const location = useLocation();
    const history = useHistory();
    const profile = useAppSelector(selectProfile);
    const {profileId, assetId} = useParams<RouteWithProfileIdAndAssetId>();
    const viewType = useContext(AssetsViewContext);

    const [account, setAccount] = useState<PartiallyOwnedInvestmentAccountWriteModel>({
        id: '',
        profileId: '',
        ordinal: -1,
        name: '',
        legalAgreementName: '',
        legalAgreementNumber: '',
        legalAgreementTypeCode: '',
        taxStatus: 'Taxable',
        institution: '',
        entityType: '',
        financialAccountPersistentId: '',
        accountNumber: '',
        accountStatus: '',
        isEntityCapitalGains: true,
        isLiabilityPaidByPortfolio: false,
        ownershipCategory: 'Sole',
        memberOwnerships: [],
        legalEntityOwnerships: []
    });
    const [domesticTrustHoldings, setDomesticTrustHoldings] = useState<HoldingSummary>();
    const [taxDetails, setTaxDetails] = useState<TaxDetailsType>({
        isEntityCapitalGains: true,
        isLiabilityPaidByPortfolio: false,
    });
    const [unrealizedCapitalGainsTax, updateUnrealizedCapitalGainsTax] = useState<number | null>(null);
    const [totalInvestablePresentValue, updateTotalInvestablePresentValue] = useState<number | null | undefined>(null);
    const [deferredTaxLiability, updateDeferredTaxLiability] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const mounted = useRef(false);

    const defaultOwnershipDetails = getDefaultOwnershipDetails(profile.primaryContact.id!);
    const [ownershipDetailsFormData, updateOwnershipDetailsFormData] = useState<OwnershipDetailsFormData>(defaultOwnershipDetails);
    const [memberGroup, setMemberGroup] = useState<MemberGroup>();
    const [legalEntities, setLegalEntities] = useState<LegalEntityFormData[]>([]);
    const [classifications, setClassifications] = useState<AssetClassifications>();
    const [isOwnershipLessThan100, setIsOwnershipLessThan100] = useState(false);
    const [isOutOfEstateOwnershipZero, setIsOutOfEstateOwnershipZero] = useState(false);
    const [isRequiredFieldsBannerShown, setIsRequiredFieldsBannerShown] = useState(false);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
    const [requiredFieldsBannerMessage, setRequiredFieldsBannerMessage] = useState<string>();
    const {accounts, partiallyOwnedInvestmentAccounts, investmentProgram} = useAppSelector(selectClientAssets)!;
    const taxableLiabilityPaidByPortfolio = getTaxableLiabilityPaidByPortfolioForTaxableAccounts(accounts, investmentProgram, partiallyOwnedInvestmentAccounts)!;
    const deferredLiabilityPaidByPortfolio = getTaxableLiabilityPaidByPortfolioForDeferredAccounts(accounts, investmentProgram, partiallyOwnedInvestmentAccounts)!;

    useEffect(() => {
        if (assetId) {
            setIsLoading(true);
            assetsApiClient.getPartiallyOwnedInvestment(profileId, assetId)
                .then(response => {
                    if (!mounted.current) return;
                    mapPartiallyOwnedInvestmentAccountToWriteModel(response);
                    mapPartiallyOwnedInvestmentAccountOwnership(response);
                })
                .finally(() => {
                    if (!mounted.current) return;
                    setIsLoading(false);
                });
        } else {
            mapLegalAgreementToWriteModel(location.state.legalAgreement, location.state.account);
        }
    }, [assetId])

    useEffect(() => {
        loadHoldings();
    }, [account.financialAccountPersistentId])

    const loadHoldings = (updatedTaxDetails?: TaxDetailsType) => {
        if (account.financialAccountPersistentId.length === 0) {
            return;
        }

        assetsApiClient.getDomesticTrustHoldingsForPartiallyOwnedAccount(
            profileId,
            account.financialAccountPersistentId,
            updatedTaxDetails?.isEntityCapitalGains ?? account.isEntityCapitalGains ?? false,
            updatedTaxDetails?.isLiabilityPaidByPortfolio ?? account.isLiabilityPaidByPortfolio ?? false,
            account.taxStatus)
            .then(holdingsResponse => {
                if (!mounted.current) return;
                setDomesticTrustHoldings(holdingsResponse);
                updateUnrealizedCapitalGainsTax(holdingsResponse.unrealizedCapitalGainsTax);
                updateDeferredTaxLiability(holdingsResponse.deferredTaxLiability);
                updateTotalInvestablePresentValue(holdingsResponse.totalInvestablePresentValue);
            })
            .catch(e => console.error(e));
    }

    const handleTaxDetailsChange = (updatedTaxDetails: TaxDetailsType) => {
        setTaxDetails(updatedTaxDetails);
        loadHoldings(updatedTaxDetails);
    };

    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            clientManagementApiClient.getMemberGroup(profile.id),
            assetsApiClient.getLegalEntities(profile.id)])
            .then(([memberGroupResponse, legalEntityResponse]) => {
                setMemberGroup(memberGroupResponse);
                setLegalEntities(legalEntityResponse)
            })
            .catch(e => console.error(e))
            .finally(() => setIsLoading(false))
    }, [profileId])

    useEffect(() => {
        assetsApiClient.getAssetClassifications().then(
            (assetClassificationResponse) => {
                setClassifications(assetClassificationResponse);
            }
        )
    }, [])

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    }, []);

    const navigateToAssetsView = () => {
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
    }

    function navigateToHoldingsView(partiallyOwnedInvestmentAccountId: String) {
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}/PartiallyOwnedInvestmentAccountHoldings/${partiallyOwnedInvestmentAccountId}`);
    }

    const mapPartiallyOwnedInvestmentAccountToWriteModel = (partiallyOwnedInvestmentAccount: PartiallyOwnedInvestmentAccountReadModel) => {
        const {
            number,
            typeCode,
            domesticTrustAccounts,
            marketEstateValue,
            ...rest
        } = partiallyOwnedInvestmentAccount;
        const domesticTrustAccount = domesticTrustAccounts[0];

        setAccount({
            ...account,
            ...rest,
            financialAccountPersistentId: domesticTrustAccount.financialAccountPersistentId,
            legalAgreementNumber: number,
            legalAgreementTypeCode: typeCode,
            accountNumber: domesticTrustAccount.accountNumber,
            accountStatus: domesticTrustAccount.accountStatus
        });
        setTaxDetails({
            isEntityCapitalGains: rest.isEntityCapitalGains,
            isLiabilityPaidByPortfolio: rest.isLiabilityPaidByPortfolio,
        });
    };

    const mapPartiallyOwnedInvestmentAccountOwnership = (partiallyOwnedInvestmentAccount: PartiallyOwnedInvestmentAccountReadModel) => {
        updateOwnershipDetailsFormData(
            mapToOwnershipDetailsFormData(partiallyOwnedInvestmentAccount)
        );
    };

    const mapLegalAgreementToWriteModel = (legalAgreement: LegalAgreement, domesticTrustAccount: DomesticTrustAccount) => {
        let liabilityPaidByPortfolio = null;
        if (legalAgreement.taxStatus === 'Taxable') {
            liabilityPaidByPortfolio = taxableLiabilityPaidByPortfolio;
        } else if (legalAgreement.taxStatus === 'Deferred') {
            liabilityPaidByPortfolio = deferredLiabilityPaidByPortfolio;
        }
        setAccount({
            ...account,
            financialAccountPersistentId: domesticTrustAccount.financialAccountPersistentId,
            name: legalAgreement.name,
            legalAgreementName: legalAgreement.name,
            legalAgreementNumber: legalAgreement.legalAgreementNumber,
            legalAgreementTypeCode: legalAgreement.legalAgreementTypeCode,
            taxStatus: legalAgreement.taxStatus,
            institution: legalAgreement.institution,
            entityType: legalAgreement.entityType,
            accountNumber: domesticTrustAccount.accountNumber,
            accountStatus: domesticTrustAccount.accountStatus
        });
        setTaxDetails({
            isEntityCapitalGains: legalAgreement.isEntityCapitalGains,
            isLiabilityPaidByPortfolio: liabilityPaidByPortfolio,
        });
    };

    const handleClickOnSave = () => {
        const {isValid} = validateForm();

        if (isValid) {
            setIsSaveButtonDisabled(true);

            const {
                id,
                profileId: otherProfileId,
                ordinal,
                memberOwnerships,
                legalEntityOwnerships,
                ...request
            } = account;

            assetsApiClient.postPartiallyOwnedInvestment(profileId, {
                ...request,
                ...mapToOwnershipWriteModel(ownershipDetailsFormData),
                ...taxDetails
            })
                .then(response => {
                    if (!mounted.current) return;

                    if (response) {
                        navigateToAssetsView();
                    }
                })
                .finally(() => {
                    if (!mounted.current) return;
                    setIsSaveButtonDisabled(false)
                });
        }
    };

    const validateForm = () => {
        const ownershipValidation = validateOwnershipDetails(ownershipDetailsFormData);
        const isOwnershipPercentageInvalid = !ownershipValidation.isTotalOwnedPercentageEqualTo100
            || ownershipValidation.isTotalOutOfEstateOwnedPercentageEqualTo0;

        setIsOwnershipLessThan100(!ownershipValidation.isTotalOwnedPercentageEqualTo100)
        setIsOutOfEstateOwnershipZero(ownershipValidation.isTotalOutOfEstateOwnedPercentageEqualTo0);

        const isRequiredFieldEmpty = isAnyRequiredFieldEmpty();

        if (isRequiredFieldEmpty) {
            showRequiredFieldsBanner(true);
        } else if (ownershipValidation.isTotalOutOfEstateOwnedPercentageEqualTo0) {
            showRequiredFieldsBanner(
                true,
                'Out-of-estate ownership must be entered to save a Partially Owned Investment Account.');
        } else {
            showRequiredFieldsBanner(false);
        }

        return {
            isValid: !isRequiredFieldEmpty && !isOwnershipPercentageInvalid
        };
    };

    const showRequiredFieldsBanner = (show: boolean, message?: string) => {
        setIsRequiredFieldsBannerShown(show);
        setRequiredFieldsBannerMessage(message);
    }

    const isAnyRequiredFieldEmpty = () => {
        const isNameBlank = !account.name.trim();

        const isOwnershipDataMissing = ownershipDetailsFormData?.legalEntityOwnerships.some((ownership) => {
            return !ownership.name.trim() || !ownership.type;
        });

        return isNameBlank || isOwnershipDataMissing;
    }

    const handleClickOnCancel = () => {
        navigateToAssetsView();
    };

    const handleViewHoldingsClick = async() => {
        const {isValid} = validateForm();
        if (isValid) {
            const {
                id,
                profileId: otherProfileId,
                ordinal,
                memberOwnerships,
                legalEntityOwnerships,
                ...request
            } = account;

           await assetsApiClient.postPartiallyOwnedInvestment(profileId, {
                ...request,
                ...mapToOwnershipWriteModel(ownershipDetailsFormData),
                ...taxDetails
            }).then(response => {
                if (response) {
                    navigateToHoldingsView(response.id);
                }
            })
        }
    };

    const renderZeroOutOfEstateAlertSubheader = () => {
        return isOutOfEstateOwnershipZero
            ? (
                <AlertBanner showAlert className="left-right-alert-banner zero-out-of-estate-alert" icon="warning">
                    Add out-of-estate ownership to this account.
                </AlertBanner>
            )
            : undefined;
    }

    const handleChangeAccount = (updatedAccount: PartiallyOwnedInvestmentAccountWriteModel) => {
        setAccount(updatedAccount);
    };

    if (isLoading || !(domesticTrustHoldings) || !memberGroup || !legalEntities || !classifications) {
        return (<LoadingIndicator/>);
    }

    return (
        <div className="edit-partially-owned-investment">
            <DataEntryHeader
                title={(assetId ? "Edit " : "Add ") + account.name}
                primaryButtonText="Save"
                secondaryButtonText="Cancel"
                onPrimaryButtonClick={handleClickOnSave}
                onSecondaryButtonClick={handleClickOnCancel}
                disablePrimaryButton={isSaveButtonDisabled}
            />
            <RequiredFieldsBanner showAlert={isRequiredFieldsBannerShown} message={requiredFieldsBannerMessage}
                                  itemType="Partially Owned Investment Account"/>
            <div className="partially-owned__form layout-data-entry-form">
                <article>
                    <section>
                        <PartiallyOwnedInvestmentAccountDetails
                            account={account}
                            onChangeAccount={handleChangeAccount}
                        />

                        {(account.taxStatus === "Taxable" || account.taxStatus === "Deferred") &&
                            <TaxDetails taxDetails={taxDetails}
                                        updateTaxDetails={handleTaxDetailsChange}
                                        taxStatus={account.taxStatus}
                                        isRequiredFieldsBannerShown={false}
                                        unrealizedCapitalGainsTax={unrealizedCapitalGainsTax}
                                        deferredTaxLiability={deferredTaxLiability}
                                        totalInvestablePresentValue={totalInvestablePresentValue}
                                        handleUnrealizedCapitalGainsTaxChange={NO_OP}
                                        handleLiabilityPaidByPortfolioChange={NO_OP}
                            />
                        }
                    </section>
                    <Ownership
                        onFormDataChange={updateOwnershipDetailsFormData}
                        formData={ownershipDetailsFormData}
                        totalAssetValue={domesticTrustHoldings.totalMarketValue}
                        isOwnershipPercentageErrorBannerShown={isOwnershipLessThan100}
                        memberGroup={memberGroup}
                        legalEntities={legalEntities}
                        updateLegalEntities={setLegalEntities}
                        subheader={renderZeroOutOfEstateAlertSubheader()}
                    />
                </article>
                <AccountSummary
                    assetType={'partiallyOwnedInvestmentAccount'}
                    holdings={domesticTrustHoldings}
                    onClick={handleViewHoldingsClick}
                    unrealizedCapitalGainsTax={domesticTrustHoldings.unrealizedCapitalGainsTax}
                    deferredTaxLiability={domesticTrustHoldings.deferredTaxLiability}
                    classifications={classifications}
                />
            </div>
        </div>
    )
}