import React, {useCallback, useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {GeneralInflowDetails} from "../models/GeneralInflow";
import {assetsApiClient} from "../AssetsApiClient";
import {GeneralInflowForm} from "./GeneralInflowForm";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import LoadingIndicator from "../../pages/LoadingIndicator";
import AddFormContainer from "../AddFormContainer";
import {InvestorGroupType, MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import InflowTimeFrameCalculator from "./InflowTimeFrameCalculator";
import {mapToMemberOwnershipWriteModel} from "../Ownership/mappers";
import AssetsViewContext from "../common/AssetsViewContext";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";

const AddGeneralInflow: React.FC = () => {
    const history = useHistory();
    const viewType = useContext(AssetsViewContext);
    const profile = useAppSelector(selectProfile)!;
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType | null>(null);
    const [taxRateForProfile, setTaxRateForProfile] = useState<number>(0);
    const [memberGroup, setMemberGroup] = useState<MemberGroup>();

    const getInitialGeneralInflow = useCallback(
        (): GeneralInflowDetails => {
            const inflowTimeFrameCalculator = new InflowTimeFrameCalculator(investorGroup!);
            const initialInflowTimeFrame = inflowTimeFrameCalculator.initialize(new Date(Date.now()), profile.primaryContact.id!);
            const memberOwnership = {
                memberId: profile.primaryContact.id!,
                percentage: "100.00",
                isRevocableTrust: false
            };

            return {
                type: "Salary",
                description: "Salary",
                taxRate: String(taxRateForProfile),
                grossAnnualFlow: 0,
                netAnnualFlow: 0,
                isInflowWillFundLifestyleGoal: true,
                isHighRisk: false,
                willAdjustWithInflation: true,
                presentValue: 0,
                memberOwnerships: [memberOwnership],
                inflowReserveLength: 0,
                totalPresentValue: 0,
                interestRate: 0,
                lifestyleGoalAligned: 0,
                excessFutureInflow: 0,
                inflowReservePresentValue: 0,
                ordinal: -1,
                isDisclosureReviewed: false,
                ...initialInflowTimeFrame
            }
        },
        [profile, investorGroup, taxRateForProfile]
    );

    useEffect(() => {
        let componentUnmounted = false;

        Promise.all([
            clientManagementApiClient.getInvestorGroup(profile.id),
            assetsApiClient.getTaxRate(profile.id),
            clientManagementApiClient.getMemberGroup(profile.id)
        ]).then(([investorGroupResponse, taxRateResponse, memberGroupResponse]) => {
            if (componentUnmounted) return;
            setInvestorGroup(investorGroupResponse);
            setTaxRateForProfile(taxRateResponse);
            setMemberGroup(memberGroupResponse);
        }).catch(error => console.error('Could not fetch asset details', error.message));

        return () => {
            componentUnmounted = true;
        }
    }, [profile.id]);

    const handleSave = async (generalInflow: GeneralInflowDetails) => {
        const {
            description,
            type,
            trustInflowType,
            grossAnnualFlow,
            taxRate,
            memberOwnerships,
            netAnnualFlow,
            yearsUntilFlow,
            yearsOfFlow,
            startDate,
            endDate,
            isInflowWillFundLifestyleGoal,
            isHighRisk,
            willAdjustWithInflation,
            inflowReserveLength,
            isDisclosureReviewed
        } = generalInflow;
        await assetsApiClient.postGeneralInflow({
            profileId: profile.id,
            description,
            type,
            trustInflowType,
            memberOwnerships: memberOwnerships.map(memberOwner => mapToMemberOwnershipWriteModel(memberOwner)),
            grossAnnualFlow,
            taxRate: parseFloat(taxRate),
            netAnnualFlow,
            yearsUntilFlow,
            yearsOfFlow,
            startDate,
            endDate,
            isInflowWillFundLifestyleGoal,
            isHighRisk,
            willAdjustWithInflation,
            inflowReserveLength,
            isDisclosureReviewed,
        }).then(res => {
            if (res.status === 201) {
                history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
            }
        });
    }

    if (!investorGroup || !taxRateForProfile || !memberGroup) {
        return <LoadingIndicator/>
    }

    return (
        <div className="general-inflow">
            <AddFormContainer
                modalTitle="Asset"
                form={(handleCancel) => (
                    <GeneralInflowForm
                        profileId={profile.id}
                        formatTitle={() => "Add General Inflow"}
                        onSave={handleSave}
                        onCancel={handleCancel}
                        initialGeneralInflow={getInitialGeneralInflow()}
                        investorGroup={investorGroup}
                        memberGroup={memberGroup}
                        releaseToggles={releaseToggles!}
                    />
                )}
            />
        </div>
    )
}

export default AddGeneralInflow;
