import Highcharts, { SeriesOptionsType } from "highcharts";
import {GoalFundingResponse } from "./models/Funding";

export type FundingGraphProps = {
    displayName: string;
    fundingAge: number;
    maxAge: number;
    startAge: number;
    fundingInfo: GoalFundingResponse;
}

export const CreateFundingGraphOptions: (props: FundingGraphProps) => Highcharts.Options
    = (props: FundingGraphProps) => {
    let {displayName, fundingAge, maxAge, startAge, fundingInfo} = props;
    return {
        chart: {
            type: 'area',
            marginRight: 50,
            marginLeft: 50
        },
        title: {
            text: "Asset Allocation and Excess, by " + displayName + "'s age",
            align: 'left',
            x: 40,
            y: 42,
            style: {
                fontSize: '15px',
                fontFamily: 'RobotoCondensed',
                fontWeight: '400',
                color: '#6B6E6F',
                display: 'flex',
                justifyContent: 'space-between',
                textTransform: 'uppercase'
            }
        },
        subtitle: {
            text: `${maxAge - startAge} year planning period`,
            align: 'right',
            x: -40,
            y: 42,
            style: {
                fontSize: '15px',
                fontFamily: 'RobotoCondensed',
                fontWeight: '400',
                color: '#6B6E6F',
                display: 'flex',
                justifyContent: 'space-between',
                textTransform: 'uppercase'
            }
        },
        accessibility: {
            enabled: true
        },
        legend: {
            borderWidth: 0,
            align: 'right',
            itemStyle: {
                color: '#000000',
                fontWeight: '500',
                fontSize: '13px',
                fontFamily: 'Roboto'
            },
            x: -40,
            labelFormatter: function () {
                let n: string = "";
                if (this.name == 'Excess') {
                    n = 'Investable Excess'
                }
                if (this.name == 'RA') {
                    n = 'Risk Assets'
                }
                if (this.name == 'RCA') {
                    n = 'Risk Control'
                }
                return n;
            }
        },
        credits: {
            enabled: false
        },
        xAxis: {
            type: 'linear',
            tickWidth: 0,
            lineColor: '#EAEAEA',
            gridLineWidth: 1,
            gridLineColor: '#CECECE',
            gridZIndex: 5,
            labels: {
                distance: 15,
                style: {
                    fontSize: '13px',
                    fontFamily: 'RobotoCondensed',
                    fontWeight: '400',
                    color: '#6B6E6F',
                    textAlign: 'center'
                }
            },
            minPadding: 0,
            maxPadding: 0,
            min: startAge,
            max: maxAge,
            plotLines: getPlotLines(fundingAge ?? 0),
        },
        yAxis: {
            title: {
                text: ''
            },
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            labels: {
                enabled: false
            },
            showFirstLabel: false,
        },
        tooltip: {
            formatter (this: Highcharts.TooltipFormatterContextObject) {
                const reversedPoints = this.points?.reverse();
                let tooltip='<span style="font-family: Roboto, sans-serif; font-weight: 800; color: #085459;"><b>AGE ' + this.key +'</b></span><table>';
                if(reversedPoints) {
                    reversedPoints.forEach((point: Highcharts.TooltipFormatterContextObject) => {
                        let displayPercentage: string;
                        if(point.series.name == 'Excess') {
                            displayPercentage = '';
                        }
                        else {
                            displayPercentage = point.percentage.toFixed(0) + '%';
                        }
                        tooltip+='<tr><td style="color:' + point.color + '; fill:'+ point.color + '; font-family: Roboto, sans-serif; font-size: 18px; padding-right:3px"><b>\u25CF</b></td>' +
                            '<td style="font-family: Roboto, sans-serif; font-weight:400; color:#3D4042; padding-right: 7px; text-align: left">' +
                            point.series.name + '</td>' +
                            '<td style="font-family: Roboto, sans-serif; font-weight:400; color:#3D4042; padding-right: 7px; text-align: left">' + displayPercentage + '</td>' +
                            '<td style="font-family: Roboto, sans-serif; font-weight:400; color:#3D4042; text-align: left">' +
                            point.y?.toLocaleString('en-US', {style: "currency", currency: "USD", maximumFractionDigits: 0, minimumFractionDigits: 0}) + '</td></tr>';
                    });
                }
                return tooltip + '</table>';
            },
            useHTML: true,
            shared: true
        },
        plotOptions: {
            series: {
                pointStart: startAge,
                states: {
                    hover: {
                        enabled: false
                    }
                }
            },
            area: {
                stacking: 'percent',
                marker: {
                    enabled: false
                }
            }
        },
        series: [
            {
                name: 'Excess',
                data: [0, 0],
                color: "#7DE0E6",
                fillColor: "#7DE0E6",
                fillOpacity: 1,
                threshold: null,
                legendIndex: 1
            } as SeriesOptionsType,
            {
                name: 'RA',
                data: fundingInfo.fundedByRiskAssetsAtYear,
                color: "#00A0AA",
                fillColor: "#00A0AA",
                fillOpacity: 1,
                threshold: null,
                legendIndex: 0
            } as SeriesOptionsType,
            {
                name: 'RCA',
                data: fundingInfo.fundedByRiskControlAtYear,
                color: "#104866",
                fillColor: "#104866",
                fillOpacity: 1,
                threshold: null,
                legendIndex: 2,
            } as SeriesOptionsType
        ]
    }
}

const getPlotLines: (age: number) => Highcharts.XAxisPlotLinesOptions[] = (age: number) => {
    const horizontalOffsetToCenterLabel: number = (Math.log10(age) + 1) * -0.5;
    const horizontalRotationAngle: number = 0;
    return [{
        value: age,
        width: 5,
        color: 'white',
        zIndex: 10,
        label: {
            align: 'center',
            textAlign: 'center',
            color: '#0A7A82',
            rotation: horizontalRotationAngle,
            useHTML: true,
            y: 125,
            x: horizontalOffsetToCenterLabel,
            formatter: function () {
                return `<div class="funding-chart-plot-line">${age}</div>`;
            },
        },
    }];
}