import React, {ReactElement} from "react";
import {LegalEntityFormData, OwnershipDetailsFormData} from "../models/Ownership";
import NonSoleOwnership from "./NonSole/NonSoleOwnership";
import SoleOwnership from "./Sole/SoleOwnership";
import {MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import {doesCategoryAllowNonClientOwnerships} from "./mappers";
import {isLivingMember} from "../../ClientManagement/mappers";

export type OwnershipProps = {
    totalAssetValue?: number | null,
    formData: OwnershipDetailsFormData,
    onFormDataChange: (updatedFormData: OwnershipDetailsFormData) => void,
    isOwnershipPercentageErrorBannerShown: boolean,
    memberGroup: MemberGroup,
    legalEntities: LegalEntityFormData[],
    updateLegalEntities: (legalEntities: LegalEntityFormData[]) => void,
    disableOutOfEstate?: boolean,
    subheader?: ReactElement
    isProfileWithProposalsOrArchived?: boolean
}

export default function OwnershipContainer({
                                               totalAssetValue,
                                               formData,
                                               onFormDataChange,
                                               isOwnershipPercentageErrorBannerShown,
                                               memberGroup,
                                               legalEntities,
                                               updateLegalEntities,
                                               disableOutOfEstate = false,
                                               subheader,
                                               isProfileWithProposalsOrArchived = false
                                           }: OwnershipProps) {

    if (!memberGroup || !legalEntities) return <React.Fragment/>;

    const handleUpdateLegalEntity = (legalEntityFormData: LegalEntityFormData) => {
        if (!legalEntities.find(entity => entity.name === legalEntityFormData.name)) {
            const updatedResults = [...legalEntities, legalEntityFormData];
            updateLegalEntities(updatedResults);
        }
    };

    const onChangeOwnership = (updatedFormData: OwnershipDetailsFormData) => {
        const newCategory = updatedFormData.ownershipCategory;
        const previousCategory = formData.ownershipCategory;
        if (newCategory !== previousCategory) {
            updatedFormData.soleOwnerType = "Individual";
            updatedFormData.memberOwnerships = [{
                memberId: memberGroup.primaryMember.id,
                percentage: "100.00",
                isRevocableTrust: false
            }];

            if (newCategory !== "Sole") {
                updatedFormData.soleOwnerType = undefined;

                if (memberGroup.partnerMember?.id && isLivingMember(memberGroup.partnerMember)) {
                    updatedFormData.memberOwnerships[0].percentage = "50.00";
                    updatedFormData.memberOwnerships.push({
                        memberId: memberGroup.partnerMember.id,
                        percentage: "50.00",
                        isRevocableTrust: false
                    })
                }
            }

            const doesNewCategoryAllowNonClientOwnership = doesCategoryAllowNonClientOwnerships(newCategory);
            updatedFormData.legalEntityOwnerships = doesNewCategoryAllowNonClientOwnership ? formData.legalEntityOwnerships : [];
            updatedFormData.nonClientMemberOwnerships = doesNewCategoryAllowNonClientOwnership ? formData.nonClientMemberOwnerships : [];
        }
        onFormDataChange(updatedFormData);
    }

    const isSoleOwnership = formData.ownershipCategory === "Sole";

    if (isSoleOwnership) {
        return (
            <SoleOwnership
                totalAssetValue={totalAssetValue}
                formData={formData}
                onFormDataChange={onChangeOwnership}
                memberGroup={memberGroup}
                legalEntities={legalEntities}
                onAddLegalEntity={handleUpdateLegalEntity}
                disableOutOfEstate={disableOutOfEstate}
                subheader={subheader}
                isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
            />
        )
    }

    return (
        <NonSoleOwnership
            totalAssetValue={totalAssetValue}
            formData={formData}
            onFormDataChange={onChangeOwnership}
            handleUpdateLegalEntity={handleUpdateLegalEntity}
            legalEntities={legalEntities}
            memberGroup={memberGroup}
            isOwnershipPercentageErrorBannerShown={isOwnershipPercentageErrorBannerShown}
            disableOutOfEstate={disableOutOfEstate}
            subheader={subheader}
            isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
        />
    );

}


