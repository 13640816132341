import {Col, Grid, Row} from "xps-react";
import React, {ChangeEvent, useEffect, useMemo, useState} from "react";
import {Page, PageName, ReportConfig, ReportSection, ReportType,} from "../models/Reporting";
import {getReportSections, initialSelectedPagesForAnnualReport} from "../ReportingConstants";
import ReportSetupHeader from "./ReportSetupHeader";
import {Button, Icon} from "../../components";
import ReportSetupPagesSection from "./ReportSetupPagesSection";
import {
    selectAdviceLibraryDocuments,
    selectPracticeDocuments,
    selectSelectedClientDocuments,
    selectSelectedMyDocuments,
    selectSelectedPagesForReport,
    selectSelectedReportType,
    selectShowDrawerForAdviceLibrary,
    selectShowDrawerForClientDocuments,
    selectShowDrawerForMyDocuments,
    selectShowDrawerForPracticeDocuments,
    setSelectedAdviceLibraryDocuments,
    setSelectedClientDocuments,
    setSelectedMyDocuments,
    setSelectedPagesForAnnualReport,
    setSelectedPracticeDocuments,
    setSelectedReportType,
    setShowDrawerForAdviceLibrary,
    setShowDrawerForClientDocuments,
    setShowDrawerForMyDocuments,
    setShowDrawerForPracticeDocuments
} from "./ReportingSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectAdviceLibrarySubMenus, selectPracticeDocumentsSubMenus} from "../../Resources/menuStructureSlice";
import AdvisorDocumentSetupSection from "./AdvisorDocumentSetupSection";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {
    ClientMeetingDocumentItem,
    ClientMeetingDocumentsResponse,
    ClientMeetingFolderItem
} from "src/Resources/models/ClientDocuments";
import DocumentSetupSection from "./DocumentSetupSection";
import {
    selectApprovedProfileFromApi,
    selectClientDocuments,
    selectMyDocuments,
    setClientDocumentsResponse,
    setMyDocumentsResponse
} from "src/Resources/clientMeetingDocumentSlice";
import SelectedDocumentsSection from "./SelectedDocumentsSection";
import {useMsal} from "@azure/msal-react";
import {msalUtils} from "../../MsalUtils";
import {resourcesApiClient} from "../../Resources/ResourcesApiClient";
import {emptyClientMeetingDocumentsSubMenusData} from "../../Resources/ResourcesConstant";
import {selectProfile, selectResourceCode} from "../../ClientManagement/ClientProfile/activeProfileSlice";

export type ReportSetupProps = {
    navigateToEditReportPage: (param:string) => void;
};

function DocumentSummarySection(props: { documentName: string, onDelete: () => void }) {
    return <div

        aria-label={`Document ${props.documentName}`}
        className="document-summary-item"
    >
        <div className="document-summary-item__left-content">
            <Icon className="marginleft-12 marginright-12" name="file_outline"/>
            <span className="document-summary-item__name">{props.documentName}</span>
        </div>
        <Button
            onClick={props.onDelete}
            kind="borderless"
            icon="only"
            size="small"
            iconName={"delete"}
        />
    </div>;
}

function setSortOrderForFiles(documentItems: ClientMeetingDocumentItem[], sortOrder: any, index: number) {
    documentItems.forEach(document => {
        sortOrder[document.id] = index;
        ++index;
    })
    return index;
}

function setSortOrderForFolders(folderItems: ClientMeetingFolderItem[], sortOrder: any, index: number) {
    folderItems.forEach(folderItem => {
        index = setSortOrderForFiles(folderItem.driveItems, sortOrder, index);
        index = setSortOrderForFolders(folderItem.folderItems, sortOrder, index);
    })
    return index;
}

function getSortOrderOfClientDocuments(clientMeetingDocumentsSubMenu: ClientMeetingDocumentsResponse) {
    const sortOrder: any = {};
    let index = 0;
    index = setSortOrderForFiles(clientMeetingDocumentsSubMenu.documentItems, sortOrder, index);
    setSortOrderForFolders(clientMeetingDocumentsSubMenu.folderItems, sortOrder, index);
    return sortOrder;
}

const GreyedOutPages = ['assetsSummaryDetailed', 'goalsSummaryDetailed', 'familyTree', 'currentVsProposed', 'reviewPortfolioReserve', 'assetReliance'];

const ReportSetup: React.FC<ReportSetupProps> = ({
                                                     navigateToEditReportPage,
                                                 }) => {
    const [usePlanSummaryAsCoverPage, setUsePlanSummaryAsCoverPage] =
        useState<boolean>(true);

    const dispatch = useAppDispatch();
    const selectedPagesForAnnualReportFromRedux = useAppSelector(selectSelectedPagesForReport);
    const selectedPagesForAnnualReport: ReportConfig = JSON.parse(JSON.stringify(selectedPagesForAnnualReportFromRedux));

    const clientDocumentsSubMenus = useAppSelector(selectClientDocuments);

    const profileResponse = useAppSelector(selectProfile);

    const selectedClientDocuments = useAppSelector(selectSelectedClientDocuments);
    const openClientDocumentsDrawer = useAppSelector(selectShowDrawerForClientDocuments);

    const myDocumentsSubMenus = useAppSelector(selectMyDocuments);
    const selectedMyDocuments = useAppSelector(selectSelectedMyDocuments);
    const openMyDocumentsDrawer = useAppSelector(selectShowDrawerForMyDocuments);

    const adviceLibrarySubMenus = useAppSelector(selectAdviceLibrarySubMenus);
    const selectedAdviceLibraryDocuments = useAppSelector(selectAdviceLibraryDocuments);
    const openAdviceLibraryDrawer = useAppSelector(selectShowDrawerForAdviceLibrary);

    const openPracticeDocumentsDrawer = useAppSelector(selectShowDrawerForPracticeDocuments);
    const selectedPracticeDocuments = useAppSelector(selectPracticeDocuments);
    const practiceDocumentsSubMenus = useAppSelector(selectPracticeDocumentsSubMenus);
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const approvedProfileFromApi = useAppSelector(selectApprovedProfileFromApi);
    const msal = useMsal();
    const currentUserId = msalUtils.getLanId(msal);
    const resourceCode = useAppSelector(selectResourceCode);
    const initialReportType = useAppSelector(selectSelectedReportType);

    useEffect(() => {
        dispatch(setShowDrawerForAdviceLibrary(false));
    }, [dispatch]);


    const defaultAdviceLibrarySortOrderMap: any = {};
    const defaultPracticeDocumentsSortOrderMap: any = {};
    const defaultClientDocumentsSortOrderMap: any = useMemo(
        () => getSortOrderOfClientDocuments(clientDocumentsSubMenus),
        [clientDocumentsSubMenus]
    );
    const defaultMyDocumentsSortOrderMap: any = useMemo(
        () => getSortOrderOfClientDocuments(myDocumentsSubMenus),
        [myDocumentsSubMenus]
    );

    let index = 0;
    adviceLibrarySubMenus.advisorDocumentSubMenus.forEach(submenu => {
        submenu.subMenuItems.forEach((item) => {
            defaultAdviceLibrarySortOrderMap[item.documentLabel] = index;
            index++;
        })
    })

    practiceDocumentsSubMenus.advisorDocumentSubMenus.forEach(submenu => {
        submenu.subMenuItems.forEach((item) => {
            defaultPracticeDocumentsSortOrderMap[item.documentLabel] = index;
            index++;
        })
    })

    const toggleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = e.target;
        const selectedPagesConfig: ReportConfig = {
            ...selectedPagesForAnnualReport,
            [name]: {
                isEnabled: checked,
            },
        };
        dispatch(setSelectedPagesForAnnualReport(selectedPagesConfig));
    };

    const isAnyPageCheckedInSection = (pageSection: ReportSection) => {
        const pagesList = pageSection.pagesList as PageName[];
        return pagesList.some(
            (page) => selectedPagesForAnnualReport[page]?.isEnabled
                && (GreyedOutPages.indexOf(page) === -1 || initialReportType === 'Other Report')
        );
    };

    const toggleAllPagesInSection = (
        pageSection: ReportSection,
        checked: boolean
    ) => {
        // if annual report then only below shud be called...
        const pages = pageSection.pages.filter((page) => !page.isDisabled && !page.isGreyedOut);
        const selectedPageList = pages.reduce(
            (pagesAccumulator, page) => ({
                ...pagesAccumulator,
                [page.value]: {
                    isEnabled: checked,
                },
            }),
            {}
        );
        dispatch(setSelectedPagesForAnnualReport({
            ...selectedPagesForAnnualReport,
            ...selectedPageList,
        }));
    };

    const onChangeReportType = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        dispatch(setSelectedReportType(value as ReportType));
        if (value === "Annual Report") {
            dispatch(setSelectedPagesForAnnualReport(initialSelectedPagesForAnnualReport));
        } else {
            Object.keys(selectedPagesForAnnualReport).forEach((pageName) => {
                selectedPagesForAnnualReport[pageName as PageName] = {
                    isEnabled: false,
                };
            });
            dispatch(setSelectedPagesForAnnualReport(selectedPagesForAnnualReport));
        }
    };

    function isChecked(page: Page) {
        const isPageEnabled = !page.isDisabled;
        let isPageSelected: boolean;
        isPageSelected =
            selectedPagesForAnnualReport[page.value as PageName]?.isEnabled;
        return isPageEnabled && isPageSelected;
    }

    const toggleUsePlanSummaryAsCoverPage = (
        e: ChangeEvent<HTMLInputElement>
    ) => {
        const {checked} = e.target;
        setUsePlanSummaryAsCoverPage(checked);
    };

    const handleAttachClientDocuments = () => {
        const documentNames = [...selectedClientDocuments];
        documentNames.sort((document1, document2) =>
            defaultClientDocumentsSortOrderMap[document1.id] - defaultClientDocumentsSortOrderMap[document2.id]);

        dispatch(setSelectedClientDocuments(documentNames));
        dispatch(setShowDrawerForClientDocuments(false));
    };

    const handleAttachMyDocuments = () => {
        const documentNames = [...selectedMyDocuments];
        documentNames.sort((document1, document2) =>
            defaultMyDocumentsSortOrderMap[document1.id] - defaultMyDocumentsSortOrderMap[document2.id]);

        dispatch(setSelectedMyDocuments(documentNames));
        dispatch(setShowDrawerForMyDocuments(false));
    };

    const handleAttachAdviceLibraryDocuments = () => {
        const documentNames = [...selectedAdviceLibraryDocuments];
        documentNames.sort((document1, document2) =>
            defaultAdviceLibrarySortOrderMap[document1] - defaultAdviceLibrarySortOrderMap[document2]);

        dispatch(setSelectedAdviceLibraryDocuments(documentNames));
        dispatch(setShowDrawerForAdviceLibrary(false));
    };

    const handleAttachPracticeDocuments = () => {
        const documentNames = [...selectedPracticeDocuments];
        documentNames.sort((document1, document2) =>
            defaultPracticeDocumentsSortOrderMap[document1] - defaultPracticeDocumentsSortOrderMap[document2]);

        dispatch(setSelectedPracticeDocuments(documentNames));
        dispatch(setShowDrawerForPracticeDocuments(false));
    };

    const deleteAdviceLibraryDocument = (deletedDocumentName: string) => {
        const updatedDocumentNames = selectedAdviceLibraryDocuments.filter(
            (documentName) => documentName !== deletedDocumentName
        );
        dispatch(setSelectedAdviceLibraryDocuments(updatedDocumentNames));
    };

    const deletePracticeDocument = (deletedDocumentName: string) => {
        const updatedDocumentNames = selectedPracticeDocuments.filter(
            (documentName) => documentName !== deletedDocumentName
        );
        dispatch(setSelectedPracticeDocuments(updatedDocumentNames));
    };

    const deleteClientDocument = (deletedDocumentId: string) => {
        const updatedDocumentNames = selectedClientDocuments.filter(
            (document) => document.id !== deletedDocumentId
        );
        dispatch(setSelectedClientDocuments(updatedDocumentNames));
    };

    const deleteMyDocument = (deletedDocumentId: string) => {
        const updatedDocumentNames = selectedMyDocuments.filter(
            (document) => document.id !== deletedDocumentId
        );
        dispatch(setSelectedMyDocuments(updatedDocumentNames));
    };

    function saveSelectedAdviceLibraryDocuments(updatedDocumentNames: string[]) {
        dispatch(setSelectedAdviceLibraryDocuments(updatedDocumentNames));
    }

    function saveShowDrawerForAdviceLibraryDocuments(showOrHide: boolean) {
        dispatch(setShowDrawerForAdviceLibrary(showOrHide));
    }

    function saveSelectedPracticeDocuments(updatedDocumentNames: string[]) {
        dispatch(setSelectedPracticeDocuments(updatedDocumentNames));
    }

    function saveShowDrawerForPracticeDocuments(showOrHide: boolean) {
        dispatch(setShowDrawerForPracticeDocuments(showOrHide));
    }

    const reportSections = getReportSections(releaseToggles!, initialReportType,profileResponse.isActivePortfolioReserve);

    if(!releaseToggles) {
        return <LoadingIndicator />
    }

    const onRefreshSharePointDocs = () => {

        const clientName = (approvedProfileFromApi.primaryContact.firstName).concat(" ", approvedProfileFromApi.primaryContact.lastName);
        resourcesApiClient.getClientMeetingDocuments(approvedProfileFromApi.id, clientName, currentUserId, resourceCode).then((clientMeetingDocumentsResponse: ClientMeetingDocumentsResponse) => {
            dispatch(setClientDocumentsResponse({...clientMeetingDocumentsResponse,isSharePointDown:false}));
        }).catch((_error)=>{
            dispatch(setClientDocumentsResponse({...emptyClientMeetingDocumentsSubMenusData,isSharePointDown:true}));
        });

        resourcesApiClient.getMyDocuments(currentUserId,approvedProfileFromApi.id).then((clientMeetingDocumentsResponse: ClientMeetingDocumentsResponse) => {
            dispatch(setMyDocumentsResponse({...clientMeetingDocumentsResponse,isSharePointDown:false}));
        }).catch((_error)=>{
            dispatch(setMyDocumentsResponse({...emptyClientMeetingDocumentsSubMenusData,isSharePointDown:true}));
        });
    }

    return (
        <div className="report-setup">
            <section className="report-setup__config">
                <Grid>
                    <ReportSetupHeader
                        onChangeReportType={onChangeReportType}
                        selectedReportType={initialReportType}
                        usePlanSummaryAsCoverPage={usePlanSummaryAsCoverPage}
                        toggleUsePlanSummaryAsCoverPage={toggleUsePlanSummaryAsCoverPage}
                    />
                </Grid>
            </section>
            <section className="report-setup__content">
                <div className="report-setup__content__title marginleft-xxxl marginbottom-xl">
                    Content to Include
                </div>
                <Grid>
                    <Row>
                        {reportSections.map((pageColumn) => (
                            <Col
                                key={pageColumn.columnNumber}
                                className="display-flex flex-column"
                                xs={4}
                            >
                                {pageColumn.sections
                                    .filter((pageSection) => !pageSection.isDisabled)
                                    .map((pageSection) => (
                                        <ReportSetupPagesSection
                                            key={pageSection.sectionTitle}
                                            pageSection={pageSection}
                                            isHeaderChecked={isAnyPageCheckedInSection(pageSection)}
                                            toggleSectionHeader={(
                                                e: ChangeEvent<HTMLInputElement>
                                            ) => {
                                                const {checked} = e.target;
                                                toggleAllPagesInSection(pageSection, checked);
                                            }}
                                            isPageChecked={isChecked}
                                            toggleCheckbox={toggleCheckbox}
                                            navigateToEditReportPage={navigateToEditReportPage}
                                        />
                                    ))}
                            </Col>
                        ))}
                        <Col className="display-flex flex-column"></Col>
                    </Row>
                </Grid>
                <Grid className="advisor-documents-container">
                    <Row className="advisor-document-row">
                        {releaseToggles.enableClientDocument && <SelectedDocumentsSection
                            title="Client Documents"
                            onAttach={() => dispatch(setShowDrawerForClientDocuments(true))}
                        >
                            {selectedClientDocuments.map((document: ClientMeetingDocumentItem) => (
                                <DocumentSummarySection
                                    key={document.name}
                                    documentName={document.name}
                                    onDelete={() => deleteClientDocument(document.id)}
                                />
                            ))}
                        </SelectedDocumentsSection>}

                        <SelectedDocumentsSection
                            title="Advice Library"
                            onAttach={() => dispatch(setShowDrawerForAdviceLibrary(true))}
                        >
                            {selectedAdviceLibraryDocuments.map((documentName: string) => (
                                <DocumentSummarySection
                                    key={documentName}
                                    documentName={documentName}
                                    onDelete={() => deleteAdviceLibraryDocument(documentName)}
                                />
                            ))}
                        </SelectedDocumentsSection>

                        {releaseToggles.enableClientDocument && <SelectedDocumentsSection
                            title="My Documents"
                            onAttach={() => dispatch(setShowDrawerForMyDocuments(true))}
                        >
                            {selectedMyDocuments.map((document: ClientMeetingDocumentItem) => (
                                <DocumentSummarySection
                                    key={document.name}
                                    documentName={document.name}
                                    onDelete={() => deleteMyDocument(document.id)}
                                />
                            ))}
                        </SelectedDocumentsSection>}

                        <SelectedDocumentsSection
                            title="Practice Documents"
                            onAttach={() => dispatch(setShowDrawerForPracticeDocuments(true))}
                        >
                            {selectedPracticeDocuments.map((documentName: string) => (
                                <DocumentSummarySection
                                    key={documentName}
                                    documentName={documentName}
                                    onDelete={() => deletePracticeDocument(documentName)}
                                />
                            ))}
                        </SelectedDocumentsSection>
                    </Row>
                </Grid>
            </section>

            <DocumentSetupSection
                drawerLabel={"Client Documents"}
                documentsSubMenus={clientDocumentsSubMenus}
                onAttach={handleAttachClientDocuments}
                openDocumentDrawer={openClientDocumentsDrawer}
                selectedDocuments={selectedClientDocuments}
                setSelectedDocuments={(updatedDocumentNames: ClientMeetingDocumentItem[]) => dispatch(setSelectedClientDocuments(updatedDocumentNames))}
                setShowDrawerForDocument={(showOrHide: boolean) => dispatch(setShowDrawerForClientDocuments(showOrHide))}
                onRefreshSharePointDocs={onRefreshSharePointDocs}
            />

            <DocumentSetupSection
                drawerLabel={"My Documents"}
                documentsSubMenus={myDocumentsSubMenus}
                onAttach={handleAttachMyDocuments}
                openDocumentDrawer={openMyDocumentsDrawer}
                selectedDocuments={selectedMyDocuments}
                setSelectedDocuments={(updatedDocumentNames: ClientMeetingDocumentItem[]) => dispatch(setSelectedMyDocuments(updatedDocumentNames))}
                setShowDrawerForDocument={(showOrHide: boolean) => dispatch(setShowDrawerForMyDocuments(showOrHide))}
                onRefreshSharePointDocs={onRefreshSharePointDocs}
            />
            
            <AdvisorDocumentSetupSection
                drawerLabel={"Advice Library"}
                advisorDocumentsSubMenuArray={adviceLibrarySubMenus.advisorDocumentSubMenus}
                onAttach={handleAttachAdviceLibraryDocuments}
                openAdvisorDocumentDrawer={openAdviceLibraryDrawer}
                selectedAdvisorDocuments={selectedAdviceLibraryDocuments}
                setSelectedAdvisorDocuments={saveSelectedAdviceLibraryDocuments}
                setShowDrawerForAdvisorDocument={saveShowDrawerForAdviceLibraryDocuments}
            />

            <AdvisorDocumentSetupSection
                drawerLabel={"Practice Documents"}
                advisorDocumentsSubMenuArray={practiceDocumentsSubMenus.advisorDocumentSubMenus}
                onAttach={handleAttachPracticeDocuments}
                openAdvisorDocumentDrawer={openPracticeDocumentsDrawer}
                selectedAdvisorDocuments={selectedPracticeDocuments}
                setSelectedAdvisorDocuments={saveSelectedPracticeDocuments}
                setShowDrawerForAdvisorDocument={saveShowDrawerForPracticeDocuments}
            />
        </div>
    );
};

export default ReportSetup;
