import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import {Col, Container, Row} from 'react-grid-system';
import {AccordionTable, Icon} from "../../../components";
import {useAppDispatch} from "../../../store/hooks";
import {setAssetRelianceStacks} from "../AssetRelianceSlice";
import {AssetRelianceLeftBorder, AssetRelianceRightBorder} from "../AssetRelianceTitleGrid";
import {AssetRelianceStack, EMPTY_ASSET_RELIANCE_RESPONSE} from "../../models/AssetRelianceResponse";
import {
    COLOR_ASSET_SHORTFALL_ACCENT,
    COLOR_ASSETS_ACCOUNTS,
    COLOR_EXCESS_ASSETS_ACCENT,
    COLOR_GOALS,
    COLOR_NT_GREY
} from "../../../constants/colors";
import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import AssetStackCheckbox from "./AssetStackCheckbox";
import {ShowInReviewTableDisplay} from "../TableDisplay/ShowInReviewTableDisplay";
import {SaveAssetRelianceRequest, SaveAssetRelianceStack} from "../../models/AssetRelianceRequest";
import {getStackIdentity, hasPositiveExcessAssets} from "../AssetRelianceUtil";
import classNames, {Mapping as ClassNamesMapping} from "classnames";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import {CellClassParameters} from "../../../components/AccordionTable/TableRowComponent";
import {EditAssetRelianceResponse} from "./EditAssetRelianceResponse";
import {DecoratedTableRow} from "../../../components/AccordionTable/AccordionTable";
import {AssetTableRow} from "../TableDisplay/AssetTableDisplay";
import _ from "lodash";

const FIRST_COLUMN_WIDTH = 310;
const MAX_SELECTED_CHECKBOXES = 6;

type EditAssetRelianceContentProps = {
    assetRelianceResponse: EditAssetRelianceResponse;
    handleSave: (payload: SaveAssetRelianceRequest) => Promise<void>;
    handleCancel: () => void;
}

const EditAssetRelianceContent: React.FC<EditAssetRelianceContentProps> = ({
                                                                               assetRelianceResponse,
                                                                               handleSave,
                                                                               handleCancel
                                                                           }) => {
    const [isSaveButtonDisabled, updateSaveButtonDisabled] = useState(false);
    const [atMaxCheckedBoxCount, updateAtMaxCheckedBoxCount] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState<number>();
    const dispatch = useAppDispatch();

    const resetHoveredIndex = useCallback(() => {
        setHoveredIndex(undefined);
    }, [setHoveredIndex]);

    const allAssetsStackIdentity = useMemo(() => getStackIdentity(
        assetRelianceResponse.allAssetsStack
        ?? EMPTY_ASSET_RELIANCE_RESPONSE.allAssetsStack
    ), [assetRelianceResponse.allAssetsStack.stackSequenceNumber]);

    const [renderDiscardChanges, setRenderDiscardChanges] = useState<boolean>(false);

    const initialCheckboxStatusesState = {[allAssetsStackIdentity]: true};
    const [checkboxStatuses, dispatchCheckboxStatuses] = useReducer(showInReviewCheckboxReducer, initialCheckboxStatusesState);

    const [headerDisplayNames, dispatchHeaderDisplayNames] = useReducer(assetStackDisplayNamesReducer, {
        [allAssetsStackIdentity]: assetRelianceResponse.allAssetsStack.name
    });

    useEffect(() => {
        const checkedBoxCount = Object.values(checkboxStatuses).filter(status => status).length;
        updateAtMaxCheckedBoxCount(checkedBoxCount >= MAX_SELECTED_CHECKBOXES);
    }, [checkboxStatuses]);

    useEffect(() => {
        dispatchCheckboxStatuses({
            type: 'reInitialize',
            payload: _.compact([...assetRelianceResponse.assetStacks, assetRelianceResponse.investablePortfolioAssetsStack]).reduce(
                (identity, stack) => {
                    identity[getStackIdentity(stack)] = stack.excluded;
                    return identity;
                },
                {...initialCheckboxStatusesState}
            ),
        });
    }, [assetRelianceResponse.assetStacks, assetRelianceResponse.investablePortfolioAssetsStack]);

    const createAssetRelianceSaveRequestPayload = () => {
        const assetRelianceStacksToSave: SaveAssetRelianceStack[] = _.compact([
            ...assetRelianceResponse.assetStacks,
            assetRelianceResponse.investablePortfolioAssetsStack
        ]).map((stack) => {
            const stackIdentity = getStackIdentity(stack);
            return ({
                stackAssetId: stack.stackAssetId,
                stackAssetType: stack.stackAssetType,
                stackSequenceNumber: stack.stackSequenceNumber,
                displayName: headerDisplayNames[stackIdentity] ?? stack.name,
                excluded: !!checkboxStatuses[stackIdentity],
            });
        });

        return {assetRelianceStacks: assetRelianceStacksToSave};
    }

    const renderCheckboxFn = (uniqueId: string) => {
        const isChecked = !!checkboxStatuses[uniqueId];
        // 'All Assets' checkbox is always checked & disabled
        const isDisabled = uniqueId === allAssetsStackIdentity || (atMaxCheckedBoxCount && !isChecked);
        return <AssetStackCheckbox isChecked={isChecked}
                                   isDisabled={isDisabled}
                                   onChange={(e) => {
                                       dispatchCheckboxStatuses({
                                           type: 'setChecked',
                                           payload: {uniqueId, isChecked: e.target.checked},
                                       });
                                   }}/>;
    };
    const showInReview = new ShowInReviewTableDisplay(
        assetRelianceResponse.columnCounter,
        assetRelianceResponse.columnStacks,
        renderCheckboxFn,
    );

    const handleSaveButtonClick = async () => {
        updateSaveButtonDisabled(true);
        await handleSave(createAssetRelianceSaveRequestPayload());
    }

    const onDiscardChanges = () => {
        assetRelianceResponse.columnAssetStacks.forEach((stack) => {
                dispatchHeaderDisplayNames({uniqueId: getStackIdentity(stack), displayName: stack.name});
            }
        )
        setRenderDiscardChanges(false);
    }

    const createHoverClasses = (isAssetRow: boolean) => ({
                                                             isBlank,
                                                             columnIndex,
                                                             rowIndex
                                                         }: CellClassParameters): ClassNamesMapping[] => {
        const isHoveredCell = hoveredIndex !== undefined
            && (hoveredIndex >= 0)
            && (hoveredIndex + 1 === columnIndex) || (isAssetRow && rowIndex === hoveredIndex)
            && (columnIndex <= rowIndex!);
        return [
            {"empty-hover-cell": isBlank && isHoveredCell},
            {"hover-cell": isHoveredCell}
        ];
    };
    const createNonAssetHoverClasses = createHoverClasses(false);
    const createAssetHoverClasses = createHoverClasses(true);

    const onRowReorder = (result: DropResult) => {
        if (result.destination) {
            const source = result.source.index;
            const destination = result.destination.index;
            const start = Math.min(source, destination);
            const end = Math.max(source, destination);
            const shiftSequenceNumber = destination > source ? ShiftDirection.UP : ShiftDirection.DOWN;
            const rowStacks = assetRelianceResponse.rowStacks;
            const destinationStackSeqNumber = rowStacks[destination].stackSequenceNumber;

            const stacks = rowStacks.map((stack, index) => {
                let updatedStackSequenceNumber: number = stack.stackSequenceNumber;
                if (index == source) {
                    updatedStackSequenceNumber = destinationStackSeqNumber
                } else if ((start <= index) && (index <= end)) {
                    updatedStackSequenceNumber += shiftSequenceNumber
                }
                return {
                    ...stack,
                    assets: stack.assets.map((asset) => ({...asset})),
                    stackSequenceNumber: updatedStackSequenceNumber,
                    excluded: !!checkboxStatuses[getStackIdentity(stack)]
                }
            });

            dispatch(setAssetRelianceStacks(stacks));
        }
    }

    const decorateAssetAccordionRow = (
        index: number,
        decoratedRow: DecoratedTableRow<number, AssetTableRow<number>>
    ): DecoratedTableRow<number, AssetTableRow<number>> => {
        const key = decoratedRow.reactElement.key;
        return {
            ...decoratedRow,
            reactElement: decoratedRow.row.isLiability
                ? decoratedRow.reactElement
                : <Draggable key={key} draggableId={String(key)} index={index}>
                    {(provided) =>
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            {decoratedRow.reactElement}
                        </div>
                    }
                </Draggable>
        };
    };

    const decorateAssetAccordionContent = (decoratedRows: DecoratedTableRow<number, AssetTableRow<number>>[]) => {
        return <DragDropContext onDragEnd={onRowReorder}>
            <Droppable droppableId='accordion-table-dropzone'>{(provided) =>
                <div ref={provided.innerRef} {...provided.droppableProps}>
                    {
                        decoratedRows
                            .filter(decoratedRow => !decoratedRow.row.isLiability)
                            .map(decoratedRow => decoratedRow.reactElement)
                    }
                    {provided.placeholder}
                </div>
            }</Droppable>
            {
                decoratedRows
                    .filter(decoratedRow => decoratedRow.row.isLiability)
                    .map(decoratedRow => decoratedRow.reactElement)
            }
        </DragDropContext>;
    };

    const excessAssetsAccentColor = hasPositiveExcessAssets(assetRelianceResponse.allAssetsStack.excessAssets) ? COLOR_EXCESS_ASSETS_ACCENT : COLOR_ASSET_SHORTFALL_ACCENT;
    return (
        <div>
            <DataEntryHeader
                title='Edit Asset Reliance'
                primaryButtonText="Save"
                onPrimaryButtonClick={handleSaveButtonClick}
                disablePrimaryButton={isSaveButtonDisabled}
                secondaryButtonText={renderDiscardChanges ? 'Discard Changes' : 'Cancel'}
                onSecondaryButtonClick={renderDiscardChanges ? onDiscardChanges : handleCancel}
            />
            <div className="edit-asset-reliance-page__grid-container">
                <Container fluid className="edit-asset-reliance-container">
                    <div className="grid_content">
                        <Row align="center" className="row edit-asset-reliance-header">
                            <Col width={FIRST_COLUMN_WIDTH}
                                 style={{minWidth: FIRST_COLUMN_WIDTH}}/>
                            <Col md={1.5} className="title-container col">
                                <div className="title">
                                    {AssetRelianceLeftBorder}
                                    <span>All Assets</span>
                                    {AssetRelianceRightBorder}
                                </div>
                            </Col>
                            <AssetHeaderColumns
                                assetStacks={assetRelianceResponse.columnAssetStacks}
                                hoveredIndex={hoveredIndex}
                                updateHoveredIndex={setHoveredIndex}
                                resetHoveredIndex={resetHoveredIndex}
                                updateHeaderDisplayName={dispatchHeaderDisplayNames}
                                updateRenderDiscardChanges={() => setRenderDiscardChanges(true)}
                                renderDiscardChanges={renderDiscardChanges}
                            />
                            {assetRelianceResponse.investablePortfolioAssetsStack &&
                                <Col md={1.5} className="asset-header-container col">
                                    <div className="asset-header">
                                        <span className="padding-lg">Investable Portfolio Assets Only</span>
                                    </div>
                                </Col>
                            }
                        </Row>
                        <AccordionTable
                            accordionTableId={`${(assetRelianceResponse.profileId)}-edit-asset-reliance-show-in-review`}
                            additionalCellClasses={createNonAssetHoverClasses}
                            collapsedAccentColor={COLOR_NT_GREY}
                            ariaLabel="show-in-review-row"
                            tableDisplayData={showInReview}
                            titleCellWidth={FIRST_COLUMN_WIDTH}
                        />
                        <AccordionTable
                            accordionTableId={`${(assetRelianceResponse.profileId)}-edit-asset-reliance-excess-assets`}
                            additionalCellClasses={createNonAssetHoverClasses}
                            collapsedAccentColor={excessAssetsAccentColor}
                            ariaLabel="excess-assets-row"
                            tableDisplayData={assetRelianceResponse.excessAssetsTableDisplay}
                            titleCellWidth={FIRST_COLUMN_WIDTH}
                        />
                        <AccordionTable
                            accordionTableId={`${(assetRelianceResponse.profileId)}-edit-asset-reliance-assets`}
                            additionalCellClasses={createAssetHoverClasses}
                            ariaLabel="assets-row"
                            collapsedAccentColor={COLOR_ASSETS_ACCOUNTS}
                            contentDecorator={decorateAssetAccordionContent}
                            tableDisplayData={assetRelianceResponse.assetTableDisplay}
                            titleCellWidth={FIRST_COLUMN_WIDTH}
                            onTitleCellMouseEnter={(row, rowIndex) => {
                                if (!row.isLiability) {
                                    setHoveredIndex(rowIndex);
                                }
                            }}
                            onTitleCellMouseLeave={(row) => {
                                if (!row.isLiability) {
                                    resetHoveredIndex();
                                }
                            }}
                            rowDecorator={decorateAssetAccordionRow}
                        />
                        <AccordionTable
                            accordionTableId={`${(assetRelianceResponse.profileId)}-edit-asset-reliance-goals`}
                            additionalCellClasses={createNonAssetHoverClasses}
                            collapsedAccentColor={COLOR_GOALS}
                            ariaLabel="goals-row"
                            tableDisplayData={assetRelianceResponse.goalTableDisplay}
                            titleCellWidth={FIRST_COLUMN_WIDTH}
                        />
                    </div>
                </Container>
            </div>
        </div>
    )
}

const AssetHeaderColumns: React.FC<{
    assetStacks: AssetRelianceStack[],
    hoveredIndex: number | undefined,
    updateHoveredIndex: (i: number) => void,
    resetHoveredIndex: () => void,
    updateHeaderDisplayName: React.Dispatch<{ uniqueId: string, displayName: string }>
    updateRenderDiscardChanges: () => void;
    renderDiscardChanges: boolean;
}> = ({
          assetStacks,
          ...rest
      }) => {
    return <>
        <b>{AssetRelianceLeftBorder}</b>
        {
            assetStacks.map((stack, index) => <AssetHeaderColumn
                key={`AssetHeaderColumn-${getStackIdentity(stack)}`}
                stack={stack}
                stackArrayIndex={index}
                {...rest}
            />)
        }
    </>;
}

const AssetHeaderColumn: React.FC<{
    stack: AssetRelianceStack;
    stackArrayIndex: number;
    hoveredIndex: number | undefined;
    updateHoveredIndex: (i: number) => void;
    resetHoveredIndex: () => void;
    updateHeaderDisplayName: React.Dispatch<{ uniqueId: string, displayName: string }>
    updateRenderDiscardChanges: () => void;
    renderDiscardChanges: boolean;
}> = ({
          stack,
          stackArrayIndex,
          hoveredIndex,
          updateHoveredIndex,
          resetHoveredIndex,
          updateHeaderDisplayName,
          updateRenderDiscardChanges,
          renderDiscardChanges
      }) => {
    const [showEditIcon, setShowEditIcon] = useState(false);
    const [isHeaderEditable, setIsHeaderEditable] = useState(false);
    const [tmpStackHeaderName, setTmpStackHeaderName] = useState(stack.name);

    useEffect(() => {
        if (!renderDiscardChanges) setTmpStackHeaderName(stack.name)
    }, [renderDiscardChanges])

    return <Col
        onMouseEnter={() => {
            setShowEditIcon(true)
            updateHoveredIndex(stackArrayIndex);
        }}
        onMouseLeave={() => {
            setShowEditIcon(false)
            resetHoveredIndex();
        }}
        md={1.5}
        className={classNames("asset-header-container col", {
            "asset-header-container-hovered": stackArrayIndex === hoveredIndex
        })}>
        <div className="asset-header">
            <div className="edit-header-name-hover">
                {showEditIcon &&
                    <div onClick={() => setIsHeaderEditable(!isHeaderEditable)}
                         onMouseDown={(e) => e.preventDefault()}
                    >
                        <Icon className="dds-icons icon icon--size-14" name="edit"/>
                    </div>
                }
            </div>
            <div className="padding-lg edit-header-input-container">
                {isHeaderEditable ?
                    <textarea
                        onBlur={() => setIsHeaderEditable(false)}
                        className='edit-header-input'
                        data-testid='edit-header'
                        maxLength={40}
                        autoFocus={true}
                        defaultValue={tmpStackHeaderName ?? `${stack.name}`}
                        onChange={(event) => {
                            const newHeader = event.target.value;
                            updateHeaderDisplayName({
                                uniqueId: getStackIdentity(stack),
                                displayName: newHeader
                            })
                            setTmpStackHeaderName(newHeader);
                            updateRenderDiscardChanges();
                        }}
                    /> :
                    tmpStackHeaderName
                }
            </div>
            {AssetRelianceRightBorder}
        </div>
    </Col>;
};

type ShowInReviewReInitializeCheckboxAction = {
    type: 'reInitialize';
    payload: Record<string, boolean>;
};

type ShowInReviewSetCheckedCheckboxAction = {
    type: 'setChecked';
    payload: {
        uniqueId: string;
        isChecked: boolean;
    }
};

const showInReviewCheckboxReducer = (
    state: Record<string, boolean>,
    action: ShowInReviewReInitializeCheckboxAction | ShowInReviewSetCheckedCheckboxAction
) => {
    if (action.type === 'reInitialize') {
        return {...action.payload};
    }
    return ({
        ...state,
        [action.payload.uniqueId]: action.payload.isChecked,
    });
};

const assetStackDisplayNamesReducer = (
    state: Record<string, string>,
    action: {
        uniqueId: string;
        displayName: string;
    }
) => {
    return ({
        ...state,
        [action.uniqueId]: action.displayName,
    });
};

enum ShiftDirection {
    UP = -1,
    DOWN = 1
}

export default EditAssetRelianceContent;