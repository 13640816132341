import { get } from "src/core/api";
import { GoalFundingResponse } from "./models/Funding";

const extractBody = async (res: Response) => {
    return await res.json();
};

const getGoalFundingOverTime = async (profileId: string, queryParams?: {selectedReserveTarget: string}, abortSignal?: AbortSignal): Promise<GoalFundingResponse> => {
    return get(`/wealth/goalFunding/${profileId}`, {
        queryParams,
        signal: abortSignal
    }).then(extractBody);
}

export const fundingApiClient = {
    getGoalFundingOverTime
}