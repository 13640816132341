import React, {useEffect, useState} from 'react';
import LoadingIndicator from "../../pages/LoadingIndicator";
import {reportingApiClient} from "../ReportingApiClient";
import {App_Conf} from "../../core/app_conf";
import {RouteWithId} from "../../routes/types";
import {useParams} from "react-router-dom";
import {Document, Page} from "react-pdf";
import {AdvisorDocumentMap, GenerateReportRequest, ReportConfig} from "../models/Reporting";
import {useAppSelector} from "../../store/hooks";
import {
    selectFamilyTreeViewportBounds,
    selectIsExtendedFamilySelected,
    selectIsOtherMembersSelected
} from "../../ClientManagement/FamilyTree/FamilyTreeSlice";
import {
    selectAdviceLibraryDocuments, selectOptionsForNWOTReport,
    selectPracticeDocuments,
    selectSelectedClientDocuments,
    selectSelectedMyDocuments,
    selectSelectedPagesForReport
} from "./ReportingSlice";
import usePageViewTimer from "../../hooks/usePageViewTimer";
import {selectAdviceLibrarySubMenus, selectPracticeDocumentsSubMenus} from "../../Resources/menuStructureSlice";
import ReportingConnectionFailedPage from './ReportingConenctionFailedPage'

type PreviewReportProps = {
    displayName: string,
    isGenerating: boolean,
    setIsGenerating: (value: boolean) => void,
    generatedReportUrl: string,
    setGeneratedReportUrl: (url: string) => void
}

const PreviewReport = ({
                           displayName,
                           isGenerating,
                           setIsGenerating,
                           generatedReportUrl,
                           setGeneratedReportUrl
                       }: PreviewReportProps) => {
    const {id} = useParams<RouteWithId>();
    const [noOfPages, setNoOfPages] = useState(0);
    const familyTreeViewportBounds = useAppSelector(selectFamilyTreeViewportBounds);
    const nwotOptions = useAppSelector(selectOptionsForNWOTReport);
    const isOtherMembersSelected = useAppSelector(selectIsOtherMembersSelected);
    const isExtendedFamilySelected = useAppSelector(selectIsExtendedFamilySelected);
    const selectedPagesForAnnualReportFromRedux = useAppSelector(selectSelectedPagesForReport);
    const selectedClientDocuments = useAppSelector(selectSelectedClientDocuments);
    const selectedMyDocuments = useAppSelector(selectSelectedMyDocuments);
    const selectedAdviceLibraryDocuments = useAppSelector(selectAdviceLibraryDocuments);
    const selectedPracticeDocuments = useAppSelector(selectPracticeDocuments);
    const reportConfig: ReportConfig = JSON.parse(JSON.stringify(selectedPagesForAnnualReportFromRedux));
    const pagesList = Array.apply(null, Array(noOfPages));
    const adviceLibrarySubMenu = useAppSelector(selectAdviceLibrarySubMenus);
    const practiceDocumentSubMenu = useAppSelector(selectPracticeDocumentsSubMenus);
    const [hasError, setHasError] = useState(false);

    usePageViewTimer('Generate Report Page Load Timer (milliseconds)', isGenerating);

    useEffect(() => {
        setIsGenerating(true);
    }, []);

    useEffect(() => {
        if (isGenerating) {
            generate()
                .then(() => {setIsGenerating(false);
                    setHasError(false)})
                .catch((error) => {
                    setIsGenerating(false);
                    console.error('Failed to Generate Report:', error.message);
                    setHasError(true);
                });
        }

        return () => {
            URL.revokeObjectURL(generatedReportUrl);
        }
    }, [isGenerating]);


    const generate = async () => {
        URL.revokeObjectURL(generatedReportUrl);

        if (reportConfig.familyTree !== null && reportConfig.familyTree.isEnabled) {
            reportConfig.familyTree.familyTreeScreenShotDetails = {
                familyMembersDetails: familyTreeViewportBounds,
                isOtherMembersSelected: isOtherMembersSelected,
                isExtendedFamilySelected: isExtendedFamilySelected
            };
        }
        if(reportConfig.netWorthOverTime !== null && reportConfig.netWorthOverTime.isEnabled) {
            reportConfig.netWorthOverTime.netWorthOverTimeOptions = {
                discountRate: nwotOptions.discountRate,
                insuranceIncluded:nwotOptions.insuranceIncluded
            }
        }
        const advisorDocumentDetailsLocal: AdvisorDocumentMap = {};
        advisorDocumentDetailsLocal["AdviceLibrary"] = {
            advisorDocumentMap: {},
            menuTitle: "AdviceLibrary"
        }
        adviceLibrarySubMenu.advisorDocumentSubMenus.forEach(submenu => {
            let documentsList: string[] = [];
            submenu.subMenuItems.forEach(item => {
                if (selectedAdviceLibraryDocuments.includes(item.documentLabel)) {
                    documentsList.push(item.documentLabel)
                }
            });

            if (documentsList.length !== 0) {
                advisorDocumentDetailsLocal["AdviceLibrary"].advisorDocumentMap[submenu.subMenuLabel] = documentsList;
            }
        });

        advisorDocumentDetailsLocal["PracticeDocuments"] = {
            advisorDocumentMap: {},
            menuTitle: "PracticeDocuments"
        }
        practiceDocumentSubMenu.advisorDocumentSubMenus.forEach(submenu => {
            let doclist: string[] = [];
            submenu.subMenuItems.forEach(item => {
                if (selectedPracticeDocuments.includes(item.documentLabel)) {
                    doclist.push(item.documentLabel)
                }
            });

            if (doclist.length !== 0) {
                advisorDocumentDetailsLocal["PracticeDocuments"].advisorDocumentMap[submenu.subMenuLabel] = doclist;
            }
        });

        const generateReportRequest: GenerateReportRequest = {
            pageUrl: `${App_Conf.APP_URL}/ReportPreview/${id}`,
            config: {
                ...reportConfig,
                coverPage: {
                    ...reportConfig.coverPage,
                    isEnabled: true
                },
                disclaimerPage: {
                    ...reportConfig.disclaimerPage,
                    isEnabled: true
                }
            },
            documentMap: advisorDocumentDetailsLocal,
            clientDocuments: selectedClientDocuments.map(clientDocument => {
                const extension = clientDocument.name.split('.').pop();
                return clientDocument.id +"."+ extension;
            }),
            myDocuments: selectedMyDocuments.map(myDocument => {
                const extension = myDocument.name.split('.').pop();
                return myDocument.id +"."+ extension;
            })
        };
        const blobPDF = await reportingApiClient.generateReport(generateReportRequest,id);
        setIsGenerating(false);
        const blobUrl = URL.createObjectURL(blobPDF);
        setGeneratedReportUrl(blobUrl);
    };

    if (!displayName) {
        return <LoadingIndicator/>
    }

    if (isGenerating) {
        return <LoadingIndicator className="loading-generate-report"
                                 displayMessage="Generating Report"
                                 displaySubText="Please wait as this might take a few minutes..."
        />
    }

    return (
        <>
            {hasError ? <ReportingConnectionFailedPage onRefresh={()=> setIsGenerating(true)}/> :
        <div className='preview-and-send' data-theme="print">
            <Document
                className="pdf-preview-pane__container"
                file={generatedReportUrl}
                error="PDF is missing"
                onLoadSuccess={({numPages}) => {
                    setNoOfPages(numPages)
                }}
            >
                {pagesList
                    .map((_page, index) =>
                        <Page
                            key={index}
                            pageNumber={index + 1}
                            scale={120 / 60}
                        />
                    )
                }
            </Document></div>}
        </>
    );
};

export default PreviewReport;
