import React from "react";
import {BarChartSidebar} from "../components";
import {formatCurrency} from "../utils/format";
import {BarChartGroup} from "./mappers";
import {
    ASSET_TITLING_BENEFICIARY_DESIGNATION,
    ASSET_TITLING_JOINT_TENANCY,
    ASSET_TITLING_REVOCABLE_TRUST, ASSET_TITLING_WILL
} from "../constants/colors";

const createBarChartData = (barChartGroup: BarChartGroup) => {
    const barChartData = [];
    if (barChartGroup["Joint Tenancy"] > 0) {
        barChartData.push({
            label: "Joint Tenancy",
            className: "",
            color: ASSET_TITLING_JOINT_TENANCY,
            total: barChartGroup["Joint Tenancy"],
        });
    }
    if (barChartGroup["Beneficiary Designation"] > 0) {
        barChartData.push({
            label: "Beneficiary Designation",
            className: "",
            color: ASSET_TITLING_BENEFICIARY_DESIGNATION,
            total: barChartGroup["Beneficiary Designation"],
        });
    }
    if (barChartGroup["Will"] > 0) {
        barChartData.push({
            label: "Will",
            className: "",
            color: ASSET_TITLING_WILL,
            total: barChartGroup["Will"],
        });
    }
    if (barChartGroup["Revocable Trust"] > 0) {
        barChartData.push({
            label: "Revocable Trust",
            className: "",
            color: ASSET_TITLING_REVOCABLE_TRUST,
            total: barChartGroup["Revocable Trust"],
        });
    }

    return barChartData
}

export function AssetTitlingBarChartSidebar({profileDisplayName, barChartGroup, currentNetWorthAtDeath, title}:
                                                {
                                                    profileDisplayName: string,
                                                    barChartGroup: BarChartGroup,
                                                    currentNetWorthAtDeath: number,
                                                    title: string
                                                })
{
    return (
        <BarChartSidebar
            data={createBarChartData(barChartGroup)}
            noDataText=""
            displayName={profileDisplayName}
            title={title}>
            <div>
                <span className="condensed-subtitle margintop-xxxl">
                    CURRENT NET WORTH
                </span>
                <div className="h1 margintop-sm marginbottom-xxxl"
                     aria-label={"Current Net Worth"}>
                    {formatCurrency(currentNetWorthAtDeath)}
                </div>
            </div>
        </BarChartSidebar>
    );
}