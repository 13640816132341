import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {clientManagementApiClient} from "../ClientManagementApiClient";
import {FamilyTreeType} from "../models/FamilyTreeType";
import {RouteWithId} from "../../routes/types";
import {useAppDispatch, useAppSelector} from "src/store/hooks";
import {
    emptyMemberForm,
    getEmptyFamilyFormInteractions,
    resetFamilyTreeToDefaults,
    SaveAction,
    selectChangedAParent,
    selectFamilyTree,
    selectIsExtendedFamilySelected,
    selectIsOpen,
    selectIsOtherMembersSelected,
    setChangedAParent,
    setEditMemberType,
    setFamilyFormInteractions,
    setFamilyMemberForm,
    setFamilyMemberFormSnapshot,
    setFamilyMode,
    setFamilyTree,
    setIsExtendedFamilySelected,
    setIsOpen,
    setIsOtherMembersSelected,
    setIsSaveButtonClicked,
    setListOfStates,
    setRelatedTo,
    setRelationshipStates,
    setSaveAction,
} from "./FamilyTreeSlice";
import {getRelationshipsState} from "../FamilyRelationshipService";
import {getFamilyMemberForm, MemberType} from "../models/MemberType";
import {ReferenceDataType} from "src/models/referenceData/ReferenceDataType";
import {referenceDataClient} from "src/core/ReferenceDataClient";
import LoadingIndicator from "../../pages/LoadingIndicator";
import AddOrEditFamilyMember from "./AddFamilyMember/AddOrEditFamilyMember";
import {InvestorGroupType} from "../models/InvestorGroupType";
import {setProfile} from "../ClientProfile/activeProfileSlice";
import {ProfileResponse} from "../models/ProfileResponse";
import {FamilyMode} from "./AddFamilyMember/FamilyMode";
import FamilyTreeGraph from "./FamilyTreeGraph";
import {FamilyRelationshipType} from "../models/FamilyRelationshipType";
import {selectParticipatingInMeeting} from "../Meeting/meetingSlice";
import FamilyTreeTag from "./FamilyTreeTag";
import {COLOR_NT_AQUA_800, COLOR_NT_GREY_550, COLOR_WHITE} from "../../constants/colors";
import {PresentationPaneButton, PresentationPaneHeader} from "../../components";
import usePageViewTimer from "../../hooks/usePageViewTimer";
import FamilyTreeZoomControls from "./FamilyTreeZoomControls";
import {useAppInsights} from "../../AppInsights";
import {MeetingParticipation} from "../Meeting/Meeting";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../../components/Modal/Error/GenericErrorModal";
import {FamilyRelationship} from "../models/FamilyRelationship";
import ModalWrapper from "../../components/Modal/ModalWrapper/ModalWrapper";
import {wealthManagementApiClient} from "../WealthManagementApiClient";
import {generateFamilyTreeNodeData} from "./GenerateNodeLinkData";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import {selectApprovedProfile} from "../ClientProfile/approvedProfileSlice";

export const EditableFamilyTree = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(resetFamilyTreeToDefaults());
        return () => {
            dispatch(resetFamilyTreeToDefaults());
        };
    }, []);

    return <FamilyTree isEditable={true}/>;
};

type FamilyTreeProps = {
    isEditable?: boolean;
}

const FamilyTree = ({isEditable = false}: FamilyTreeProps) => {
    let {id} = useParams<RouteWithId>();
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const archivedParam = queryParams.get("archived");
    const readOnlyParam = queryParams.get("readOnly");
    const dispatch = useAppDispatch();
    const familyTree = useAppSelector(selectFamilyTree);
    const isOpen = useAppSelector(selectIsOpen);
    const showOtherMembers = useAppSelector(selectIsOtherMembersSelected);
    const showExtendedFamily = useAppSelector(selectIsExtendedFamilySelected);
    const changedAParent = useAppSelector(selectChangedAParent);
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const approvedProfile = useAppSelector(selectApprovedProfile);
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    const [
        defaultPersonalPlanningHorizon,
        updateDefaultPersonalPlanningHorizon,
    ] = useState(100);
    const [isLoading, setIsLoading] = useState(true);
    const [isInitialized, setIsInitialized] = useState(false);
    const [planningPeriod, setPlanningPeriod] = useState('');
    const appInsights = useAppInsights();
    const participatingInMeeting = useAppSelector(selectParticipatingInMeeting)!;
    const [genericError, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const [showArchivedModal, setShowArchivedModal] = React.useState(false);
    const [showEditableProfileModal, setShowEditableProfileModal] = React.useState(false);
    const [disableExtendedFamilyButton, setDisableExtendedFamilyButton] = useState(true);

    usePageViewTimer('Family Tree Page Load Timer (milliseconds)', isLoading);

    const handleErrorCloseButton = () => {
        setGenericError({...genericError, isOpen: false});
    }

    useEffect(() => {
        if (archivedParam) {
            setShowArchivedModal(true);
        }
    }, [archivedParam])

    useEffect(() => {
        if (readOnlyParam && releaseToggles?.enableBlockProfileEditsWithActiveProposals) {
            setShowEditableProfileModal(true);
        }
    }, [readOnlyParam, releaseToggles?.enableBlockProfileEditsWithActiveProposals])

    useEffect(() => {
        if (!isInitialized) {
            dispatch(setIsOpen(false));
            dispatch(setFamilyFormInteractions(getEmptyFamilyFormInteractions()));
            dispatch(setIsSaveButtonClicked(false));
            setIsInitialized(true)
        }
    }, [isInitialized])

    useEffect(() => {
        if (location.state?.editPrimaryContact && familyTree?.primaryContact.id) {
            location.state.editPrimaryContact = false;
            handleEditFamilyMember(familyTree.primaryContact, FamilyMode.IMMEDIATE);
        }
        clientManagementApiClient.getInvestorGroup(id)
            .then((investorGroup: InvestorGroupType) => {
                setPlanningPeriod(String(investorGroup.planningPeriod.numberOfYears));
            }).catch(error => {
            console.error('Could not fetch investor group', error.message)
        })
        // the global profile state needs updating when the family tree changes
        clientManagementApiClient.getProfile(id)
            .then((profileResponse: ProfileResponse) => {
                dispatch(setProfile(profileResponse))
            }).catch(error => {
            console.error('Could not get profile', error.message);
        });
    }, [familyTree]);

    useEffect(() => {
        let partnerHasExtendedFamily = false
        let relationships = familyTree?.primaryContact.family;
        let primaryHasExtendedFamily = relationships ? relationships?.some(relation => relation.type === FamilyRelationshipType.PARENT) : false

        if (relationships) {
            let maybePartner = getPartnerMember(relationships)
            if (maybePartner) {
                partnerHasExtendedFamily = maybePartner.family ? maybePartner.family?.some(relation => relation.type === FamilyRelationshipType.PARENT) : false
            }
        }

        let noExtendedFamily = !primaryHasExtendedFamily && !partnerHasExtendedFamily
        if (noExtendedFamily) {
            dispatch(setIsExtendedFamilySelected(false))
        } else {
            if (changedAParent) {
                dispatch(setIsExtendedFamilySelected(true));
                dispatch(setChangedAParent(false));
            }
        }
        setDisableExtendedFamilyButton(noExtendedFamily);
    }, [familyTree]);

    const getPartnerMember = (relationships: FamilyRelationship[]): MemberType | null => {
        let partnerMemberRelations = relationships
            .filter(
                relation =>
                    relation.type === FamilyRelationshipType.SPOUSE ||
                    relation.type === FamilyRelationshipType.EX_SPOUSE ||
                    relation.type === FamilyRelationshipType.DOMESTIC_PARTNER ||
                    relation.type === FamilyRelationshipType.SIGNIFICANT_OTHER
            );
        if (partnerMemberRelations.length > 0) {
            return partnerMemberRelations[0].fromMember;
        }
        return null;
    };

    useEffect(() => {
        clientManagementApiClient.getFamily(id)
            .then((newFamily: FamilyTreeType) => {
                dispatch(setFamilyTree(newFamily));
                setIsLoading(false);
            }).catch((error) => {
            setGenericError({
                isOpen: true,
                header: "Communication Failure",
                message: "There has been a communication failure.  The data you see may not be accurate, please refresh your browser.  If this error continues, please contact the support team.",
                operationId: error.headers.get('trace-id')
            });
            setIsLoading(false);
            console.error('Failed to retrieve family tree', error.message);
        })
    }, [id, isOpen])

    useEffect(() => {
        referenceDataClient
            .getReferenceData()
            .then((referenceData: ReferenceDataType) => {
                dispatch(
                    setListOfStates(
                        Object.entries(referenceData.statesOfResidency).map(
                            ([, value]) => value
                        ).sort()
                    )
                );
                updateDefaultPersonalPlanningHorizon(
                    referenceData.defaultPersonalPlanningHorizon
                );
            }).catch((error) => {
            setGenericError({
                isOpen: true,
                header: "Communication Failure",
                message: "There has been a communication failure. The data you see may not be accurate, please refresh your browser.  If this error continues, please contact the support team.",
                operationId: error.operationId
            });
        })
    }, []);

    const handleEditFamilyMember = useCallback((fromMember: MemberType, familyMode: FamilyMode) => {
        dispatch(setSaveAction(SaveAction.Edit));
        dispatch(setFamilyMode(familyMode));
        const familyMemberFormToEdit = getFamilyMemberForm(fromMember)
        dispatch(setFamilyMemberForm(familyMemberFormToEdit));
        dispatch(setFamilyMemberFormSnapshot(familyMemberFormToEdit));
        resetForm();
        dispatch(setRelationshipStates(getRelationshipsState(fromMember.id, familyTree!.primaryContact)));
        dispatch(setRelatedTo(familyTree!.primaryContact));
        dispatch(setEditMemberType(fromMember));
    }, [familyTree]);

    function resetForm() {
        dispatch(setIsOpen(true));
        dispatch(setFamilyFormInteractions(getEmptyFamilyFormInteractions()));
        dispatch(setIsSaveButtonClicked(false));
    }

    function prepareToAddNewMember() {
        dispatch(setSaveAction(SaveAction.Create));
        const blankMemberForm = {
            ...emptyMemberForm,
            personalPlanningHorizon: defaultPersonalPlanningHorizon,
        };
        dispatch(setFamilyMemberForm(blankMemberForm));
        dispatch(setFamilyMemberFormSnapshot(blankMemberForm));
        resetForm();
    }

    function handleAddFamilyMember() {
        appInsights.trackEvent({
            name: 'FamilyTree',
            properties:
                {
                    screen: 'Family Tree',
                    action: 'Add Family Member Button Click',
                    meetingStatus: participatingInMeeting ? MeetingParticipation.IN_MEETING : MeetingParticipation.OUT_OF_MEETING,
                    data: 'Add Family Member Clicked'
                }
        })

        prepareToAddNewMember();
        dispatch(setFamilyMode(FamilyMode.IMMEDIATE));
        dispatch(setRelationshipStates([{
            memberId: familyTree!.primaryContact.id,
            type: null,
            fullName: `${familyTree!.primaryContact.firstName} ${familyTree!.primaryContact.lastName}`
        }]))
        dispatch(setRelatedTo(familyTree!.primaryContact))
    }

    function handleShowOtherMembers() {
        dispatch(setIsOtherMembersSelected(!showOtherMembers))
    }

    function handleShowExtendedFamily() {
        dispatch(setIsExtendedFamilySelected(!showExtendedFamily))
    }

    if (isLoading) {
        return <LoadingIndicator/>
    }

    const titleRowChildren = () => (
        <div>
            <PresentationPaneButton
                iconName="add"
                className="add-family-member"
                onClick={handleAddFamilyMember}
                disabled={isProfileWithProposalsOrArchived}
            >
                Add Family Member
            </PresentationPaneButton>
            <AddOrEditFamilyMember isOpen={isOpen || false}
                                   updateIsOpen={(newState => dispatch(setIsOpen(newState)))}
                                   profileId={id}/>
        </div>
    );

    const familyTreeNodeData = generateFamilyTreeNodeData(
        familyTree!,
        handleEditFamilyMember,
        () => {
            prepareToAddNewMember();
            dispatch(setFamilyMode(FamilyMode.OTHER));
            dispatch(setRelatedTo(familyTree!.primaryContact));
            const {firstName, lastName} = familyTree!.primaryContact;
            dispatch(setRelationshipStates([{
                memberId: familyTree!.primaryContact.id,
                fullName: `${firstName} ${lastName}`,
                type: FamilyRelationshipType.OTHER
            }]));
        },
        showOtherMembers,
        isEditable && !isProfileWithProposalsOrArchived,
        showExtendedFamily,
        isProfileWithProposalsOrArchived
    );
    return (
        <div className="family-tree-page">
            <PresentationPaneHeader
                displayName={familyTree?.displayName || ""}
                title="Family Tree"
                titleRow={titleRowChildren()}
            >
            </PresentationPaneHeader>
            <GenericErrorModal
                errorModalData={genericError}
                onClickButton={handleErrorCloseButton}
                buttonText={'Close'}
            />
            <div className="family-tree-page__tree">
                <div className={"tag-container"}>
                    <div style={{display: 'flex', marginLeft: '64px'}}>
                        <FamilyTreeTag
                            name={"Immediate Family"}
                            disabled={true}
                            backgroundColor={COLOR_NT_AQUA_800}
                            textColor={COLOR_WHITE}
                        />
                        <FamilyTreeTag
                            name={"Extended Family"}
                            disabled={disableExtendedFamilyButton}
                            toggle={handleShowExtendedFamily}
                            backgroundColor={showExtendedFamily ? COLOR_NT_AQUA_800 : COLOR_WHITE}
                            textColor={showExtendedFamily ? COLOR_WHITE : COLOR_NT_GREY_550}
                        />
                        <FamilyTreeTag
                            name={"Other Members"}
                            toggle={handleShowOtherMembers}
                            backgroundColor={showOtherMembers ? COLOR_NT_AQUA_800 : COLOR_WHITE}
                            textColor={showOtherMembers ? COLOR_WHITE : COLOR_NT_GREY_550}
                        />
                    </div>
                    <label id="planning_period_label">
                        {
                            familyTree?.primaryContact
                                ? planningPeriod
                                : ''
                        } Year Planning Period
                    </label>
                </div>
                <FamilyTreeZoomControls/>
                <FamilyTreeGraph
                    familyTreeData={familyTreeNodeData.familyTreeNodeData}
                    familyTreeRelationshipData={familyTreeNodeData.familyTreeLinkData}
                />
                <ModalWrapper
                    isOpen={showArchivedModal}
                    id="confirm-clone-proposal"
                    headerText="This Profile/Proposal Cannot be Edited"
                    buttons={[
                        {
                            text: "VIEW ONLY",
                            onClick: () => setShowArchivedModal(false)
                        },
                        {
                            text: "SAVE NEW PROPOSAL",
                            primary: true,
                            onClick: () => {
                                wealthManagementApiClient.cloneArchivedProposal(id, approvedProfile.id)
                                    .then(() => history.push(`/profile/${approvedProfile.id}`));
                            }
                        }
                    ]}
                >
                    <span>You will not be able to save changes to this Profile/Proposal.</span>
                    <span>Would you like to save as a new proposal to make changes?</span>
                </ModalWrapper>
                <ModalWrapper
                    isOpen={showEditableProfileModal}
                    id="confirm-non-editable"
                    headerText="You will not be able to save changes to this Profile."
                    buttons={[
                        {
                            text: "OK",
                            onClick: () => setShowEditableProfileModal(false)
                        }
                    ]}
                >
                    <span>In order to make changes, you must create a new Proposal from this Profile.</span>
                </ModalWrapper>
            </div>
        </div>
    );
};

export default FamilyTree;
