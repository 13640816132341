import {get, post} from "../core/api";
import {MenuStructureResponse} from "./models/AdviceLibrary";
import {ClientMeetingDocumentsResponse, LinkUnlinkPWClientRequest} from "./models/ClientDocuments";

const getAdviceLibraryDocument = async (menuTitle: string, documentLabel: string, subMenuLabel: string): Promise<Blob> => {
    const response = await get(`/advisory/adviceLibrary/getDocumentFromCache`, {
        queryParams: {
            menuTitle,
            documentLabel,
            subMenuLabel
        }
    });
    return response.blob();
}

const getMenuStructure = async (): Promise<MenuStructureResponse> => {
    const response = await get(`/advisory/adviceLibrary/menu`);
    return response.json();
}

const getClientMeetingDocuments = async (profileId: string, clientName: string, ownerId:string, resourceCode:string) : Promise<ClientMeetingDocumentsResponse> => {
    const response = await get(`/advisory/client/documents`, {
        queryParams: {
            profileId,
            clientName,
            ownerId,
            resourceCode
        }
    });
    return response.json();
}

const getMyDocuments = async (lanId:string, profileId: string) : Promise<ClientMeetingDocumentsResponse> => {
    const response = await get(`/advisory/my/documents/${profileId}`, {
        queryParams: {
            lanId
        }
    });
    return response.json();
}

const getClientMeetingDocumentBlob = async (itemId: string, documentType: string, profileId: string, extension: string) : Promise<Blob> => {
    const response = await get(`/advisory/client/documents/getDocumentBlob/${profileId}`, {
        queryParams: {
            itemId,
            documentType,
            extension
        }
    });
    return response.blob();
}

const linkUnlinkPWClient = async (profileId: string, reqBody: LinkUnlinkPWClientRequest): Promise<Response> => {
    return post(`/advisory/client/documents/linkUnlinkClient/${profileId}`, reqBody);
}

export const resourcesApiClient = {
    getAdviceLibraryDocument,
    getMenuStructure,
    getClientMeetingDocuments,
    getMyDocuments,
    getClientMeetingDocumentBlob,
    linkUnlinkPWClient
};