import {ActiveFormAsset, InEstateAssetTotals} from "../../clientAssetsSlice";
import {
    COLOR_ASSETS_ACCOUNTS,
    COLOR_EQUITY_COMPENSATIONS,
    COLOR_FUTURE_INFLOWS,
    COLOR_LIABILITIES,
    COLOR_LIFE_INSURANCES,
    COLOR_PERSONAL_ASSETS
} from "../../../constants/colors";
import {StackedBarChartData} from "../../../components/BarChartSidebar/BarChartSidebar";
import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {
    BarChartSummaryTableItem,
    BarChartSummaryTableProps
} from "../../../components/BarChartSidebar/BarChartSummaryTable";
import {formatCurrency} from "../../../utils/format";
import {AssetsSummary} from "../../models/Assets";
import {hasSomeInEstateOwnership} from "../../Ownership/helpers";
import {
    calculateEquityCompensationValue,
    calculateInEstateFormValueForLifeInsurance
} from "../common/AssetSummaryCalculator";

type AssetOverview = Omit<ActiveFormAsset, 'assetType'>;

export function createAssetBarChartData(assetTotals: InEstateAssetTotals,
                                        activeFormAsset?: ActiveFormAsset | null): StackedBarChartData[] {
    const barChartData = [];
    if (assetTotals.accountTotal) {
        barChartData.push({
            className: 'accounts-chart-item',
            label: "Accounts",
            color: COLOR_ASSETS_ACCOUNTS,
            total: assetTotals.accountTotal,
            highlight: (activeFormAsset?.assetType === 'standaloneAccount' || activeFormAsset?.assetType === 'investmentProgram') ? activeFormAsset?.inEstateValue : undefined,
        });
    }

    if (assetTotals.equityCompensationTotal) {
        barChartData.push({
            className: 'equity-compensation-chart-item',
            label: "Equity Compensation",
            color: COLOR_EQUITY_COMPENSATIONS,
            total: assetTotals.equityCompensationTotal,
            highlight: activeFormAsset?.assetType === 'equityCompensation' ? activeFormAsset?.inEstateValue : undefined,
        });
    }

    if (assetTotals.futureInflowsTotal) {
        barChartData.push({
            className: 'future-inflows-chart-item',
            label: "Future Inflows",
            color: COLOR_FUTURE_INFLOWS,
            total: assetTotals.futureInflowsTotal,
            highlight: activeFormAsset?.assetType === 'futureInflow' ? activeFormAsset?.inEstateValue : undefined,
        });
    }

    if (assetTotals.personalAssetTotal) {
        barChartData.push({
            className: 'personal-asset-chart-item',
            label: "Personal Assets",
            color: COLOR_PERSONAL_ASSETS,
            total: assetTotals.personalAssetTotal,
            highlight: activeFormAsset?.assetType === 'personalAsset' ? activeFormAsset?.inEstateValue : undefined,
        });
    }

    if (assetTotals.lifeInsuranceTotal) {
        barChartData.push({
            className: 'life-insurance-chart-item',
            label: "Life Insurance",
            color: COLOR_LIFE_INSURANCES,
            total: assetTotals.lifeInsuranceTotal,
            highlight: activeFormAsset?.assetType === 'lifeInsurance' ? activeFormAsset?.inEstateValue : undefined,
        });
    }

    if (assetTotals.personalLiabilitiesTotal) {
        barChartData.push({
            className: 'personal-liabilities-chart-item',
            label: "Personal Liabilities",
            color: COLOR_LIABILITIES,
            total: -(assetTotals.personalLiabilitiesTotal),
            highlight: activeFormAsset?.assetType === 'liability' ? activeFormAsset?.inEstateValue : undefined,
        });
    }

    return barChartData;
}

const getOwnerName = (ownerMemberId: string, investorGroup?: InvestorGroupType) => {
    if (investorGroup?.partnerMember?.id === ownerMemberId) {
        return investorGroup.partnerMember.name;
    } else if (investorGroup?.primaryMember.id === ownerMemberId) {
        return investorGroup.primaryMember.name;
    } else {
        return ""
    }
}

export const buildSummaryTableData = (activeFormAsset: ActiveFormAsset,
                                      clientAssets: AssetsSummary,
                                      inEstateAssetsTotals: InEstateAssetTotals,
                                      investorGroup: InvestorGroupType | undefined): BarChartSummaryTableProps => {

    switch (activeFormAsset.assetType) {
        case "personalAsset": {
            const personalAssetOverviews = clientAssets.personalAssets.data.map(asset => ({
                id: asset.id,
                description: asset.description,
                inEstateValue: asset.inEstateValue,
                hasInEstateOwnership: hasSomeInEstateOwnership(asset.memberOwnerships)
            }));
            return {
                leftHeader: 'Personal Assets',
                rightHeader: formatCurrency(inEstateAssetsTotals.personalAssetTotal),
                items: buildItemsForAssets(activeFormAsset, personalAssetOverviews, COLOR_PERSONAL_ASSETS)
            };
        }
        case "lifeInsurance": {
            const lifeInsuranceOverviews = clientAssets.lifeInsurances.data.map(asset => ({
                id: asset.id,
                description: asset.description,
                inEstateValue: asset.isCashValueWillFundGoals ? calculateInEstateFormValueForLifeInsurance(asset.cashValue, asset.memberOwnerships) : 0,
                hasInEstateOwnership: hasSomeInEstateOwnership(asset.memberOwnerships)
            }));
            return {
                leftHeader: 'Life Insurance',
                rightHeader: formatCurrency(inEstateAssetsTotals.lifeInsuranceTotal),
                items: buildItemsForAssets(activeFormAsset, lifeInsuranceOverviews, COLOR_LIFE_INSURANCES)
            };
        }
        case "futureInflow": {
            const futureInflowOverviews: AssetOverview[] = [];
            clientAssets.generalInflows.data.forEach(asset => futureInflowOverviews.push({
                id: asset.id,
                description: asset.description,
                inEstateValue: asset.presentValue,
                hasInEstateOwnership: true,
            }));
            clientAssets.socialSecurities.data.forEach(asset => futureInflowOverviews.push({
                id: asset.id,
                description: `${getOwnerName(asset.memberOwnership.member.id, investorGroup)} (Social Security)`,
                inEstateValue: asset.presentValue,
                hasInEstateOwnership: true,
            }));
            return {
                leftHeader: 'Future Inflows',
                rightHeader: formatCurrency(inEstateAssetsTotals.futureInflowsTotal),
                items: buildItemsForAssets(activeFormAsset, futureInflowOverviews, COLOR_FUTURE_INFLOWS)
            };
        }
        case "liability": {
            const liabilityOverviews = clientAssets.personalLiabilities.map(liability => ({
                id: liability.id,
                description: liability.description,
                inEstateValue: liability.loanBalance,
                hasInEstateOwnership: true,
            }));
            return {
                leftHeader: 'Liabilities',
                rightHeader: formatCurrency(inEstateAssetsTotals.personalLiabilitiesTotal),
                items: buildItemsForAssets(activeFormAsset, liabilityOverviews, COLOR_LIABILITIES)
            };
        }
        case "investmentProgram":
        case "standaloneAccount": {
            const accountOverviews: AssetOverview[] = [];
            clientAssets.investmentProgram && clientAssets.investmentProgram.legalAgreements.forEach(investmentProgramAccount => accountOverviews.push({
                id: investmentProgramAccount.id!,
                description: investmentProgramAccount.name,
                inEstateValue: investmentProgramAccount.marketValue,
                hasInEstateOwnership: true
            }));

            clientAssets.accounts.data.forEach(standaloneAccount => accountOverviews.push({
                id: standaloneAccount.id,
                description: standaloneAccount.name,
                inEstateValue: standaloneAccount.inEstateValue,
                hasInEstateOwnership: hasSomeInEstateOwnership(standaloneAccount.memberOwnerships)
            }));

            return {
                leftHeader: 'Accounts',
                rightHeader: formatCurrency(inEstateAssetsTotals.accountTotal),
                items: buildItemsForAssets(activeFormAsset, accountOverviews, COLOR_ASSETS_ACCOUNTS),
            };
        }
        case "equityCompensation" : {
            const equityCompensationOverviews = clientAssets.equityCompensations.data.map(asset => ({
                id: asset.id,
                description: asset.entityOrAccountName,
                inEstateValue: calculateEquityCompensationValue(asset),
                hasInEstateOwnership: true
            }));
            return {
                leftHeader: 'Equity Compensation',
                rightHeader: formatCurrency(inEstateAssetsTotals.equityCompensationTotal),
                items: buildItemsForAssets(activeFormAsset, equityCompensationOverviews, COLOR_EQUITY_COMPENSATIONS)
            }
        }
        default:
            throw new Error(`Unimplemented asset type: ${activeFormAsset.assetType}`);
    }
}

function buildItemsForAssets(activeAssetOverview: AssetOverview,
                             allAssets: AssetOverview[],
                             color: string): BarChartSummaryTableItem[] {
    const nonActiveAssetOverviews = allAssets.filter(asset => asset.id !== activeAssetOverview.id);
    const sortedOverviews = [
        activeAssetOverview,
        ...nonActiveAssetOverviews,
    ];
    const toBarChartItem = (asset: AssetOverview) => ({
        leftText: asset.description,
        rightText: formatCurrency(asset.inEstateValue),
        isActive: asset.id === activeAssetOverview.id,
        keyColor: color,
    });

    return sortedOverviews
        .filter(hasInEstateOwnership)
        .map(toBarChartItem);
}

const hasInEstateOwnership = (overview: AssetOverview) => overview.hasInEstateOwnership;
