import {AssetListHeader} from "../../../Assets/AssetSummary/common/AssetListHeader";
import {NO_OP} from "../../../constants/common";
import {Accordion, AccordionItem} from "../../../components";
import {COLOR_LIABILITIES} from "../../../constants/colors";
import {AssetAccordionHeader} from "../../../Assets/AssetSummary/common/AssetAccordionHeader";
import {PersonalLiabilityRow} from "../../../Assets/AssetSummary/InEstate/PersonalLiabilityRow";
import {PersonalLiabilitySummary} from "../../../Assets/models/PersonalLiability";
import AssetSummaryPersonalLiabilityDetails
    from "../../../Assets/AssetSummary/InEstate/AssetSummaryPersonalLiabilityDetails";
import React from "react";
import {AssetsSummary} from "../../../Assets/models/Assets";
import {formatCurrency} from "../../../utils/format";
import {assetListData} from "../../../Assets/AssetSummary/common/utils";

type LiabilityReportListProps = {
    clientAssets: AssetsSummary,
    allAssets: AssetsSummary,
    isDetailed: boolean
};

export function LiabilityReportList({
                                        allAssets,
                                        clientAssets,
                                        isDetailed
                                    }: LiabilityReportListProps) {
    function getFormattedTotalPresentValue(totalPresentValue: number) {
        return totalPresentValue !== undefined ? `-${formatCurrency(totalPresentValue)}` : '';
    }

    return <section>
        <div className="liabilities-list">
            <div className="accordion-tables">
                <AssetListHeader
                    columnTitles={["Liability Type", "", "Present Value"]}
                    onExpandedChanged={NO_OP}
                />
                <Accordion
                    allowMultipleExpanded
                    allowZeroExpanded
                    accordionId={isDetailed ? "AssetSummaryLiabilityAccordionReport" : "AssetSummaryLiabilityCollapsedReport"}
                >
                    <div className="liabilities">
                        {clientAssets.personalLiabilities.length > 0 && (
                            <AccordionItem
                                uuid="PersonalLiabilities"
                                accentColor={COLOR_LIABILITIES}
                                HeaderComponent={({expanded}) =>
                                    <AssetAccordionHeader
                                        expanded={expanded}
                                        formattedTotalPresentValue={getFormattedTotalPresentValue(allAssets.liabilitiesValue.inEstateValue)}
                                        title="Personal Liabilities"
                                        gridClassName={"assets-grid"}
                                        caption={JSON.stringify(allAssets.personalLiabilities[0]) === JSON.stringify(clientAssets.personalLiabilities[0]) ? "" : "continued"}
                                    />
                                }
                            >
                                <div role="table"
                                     className="assets-grid-with-actionmenu assets-grid-table"
                                     aria-label="personal-liabilities-table">
                                    <div role="row"
                                         className="assets-grid-with-actionmenu assets-grid-table-header"
                                         tabIndex={-1}>
                                                                                <span role="columnheader"
                                                                                      className='paddingleft-xxl textalign-left'>Name</span>
                                        <span role="columnheader"
                                              className='textalign-left'>Institution</span>
                                        <span role="columnheader"
                                              className='textalign-right'>Interest Rate</span>
                                        <span role="columnheader"
                                              className='textalign-right'>Maturity Date</span>
                                        <span role="columnheader"
                                              className='textalign-right'>Loan Balance</span>
                                    </div>
                                    {assetListData(clientAssets).inEstatePersonalLiabilities.map(personalLiability =>
                                        <PersonalLiabilityRow
                                            key={personalLiability.id}
                                            onClickEdit={NO_OP}
                                            onClickDelete={NO_OP}
                                            personalLiability={personalLiability}
                                            actionsDisabled={false}
                                            gridClassName={"assets-grid-with-actionmenu"}
                                            renderPersonalLiabilityDetails={(personalLiabilitySummary: PersonalLiabilitySummary) =>
                                                <AssetSummaryPersonalLiabilityDetails
                                                    personalLiabilitySummary={personalLiabilitySummary}/>}
                                        />)}
                                </div>
                            </AccordionItem>
                        )}
                    </div>
                </Accordion>
            </div>
        </div>
    </section>
}