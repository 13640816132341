import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import React, {useEffect, useRef, useState} from "react";
import {ClientMemberOwnerDropdown} from "../Ownership/Sole/ClientMemberOwnerDropdown";
import {mapSoleMemberOwnershipFormData, mapToMemberOwnershipWriteModel,} from "../Ownership/mappers";
import {
    InvestorGroupMember,
    InvestorGroupType,
    MemberGroup,
    PlanningPeriodType
} from "../../ClientManagement/models/InvestorGroupType";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {CurrencyInputWithLabel, NumberInput, RequiredFieldsSubheader, UnderlinedHeader} from "../../components";
import {formatCurrency} from "../../utils/format";
import DataEntrySummary from "../../components/DataEntry/DataEntrySummary";
import {isNaN} from "mathjs";
import {assetsApiClient} from "../AssetsApiClient";
import {setActiveFormAsset} from "../clientAssetsSlice";
import {
    EvaluateSocialSecurityRequest,
    SocialSecurity,
    SocialSecurityBenefits,
    SocialSecurityDetails,
    UnsavedSocialSecurity
} from "../models/SocialSecurity";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {calculateAgeFromBirthdate} from "../../utils/dateUtils";
import {ISO8601_DATE_FORMAT, NO_OP} from "../../constants/common";
import PercentInput from "../../components/Input/PercentInput";
import deepEquals from "fast-deep-equal";
import {InflowCharacteristics} from "src/Assets/FutureInflows/InflowCharacteristics";
import {InflowReserve} from "src/Assets/FutureInflows/InflowReserve";
import {LifeStatus} from "../../ClientManagement/models/MemberType";
import {ReleaseTogglesType} from "../../ReleaseToggles/ReleaseToggles";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import {HistoryBlockModal} from "../../components/HistoryBlockModal/HistoryBlockModal";

export type SocialSecurityProps = {
    formatTitle: (selectedOwner: string) => string,
    handleSave: (unsavedSocialSecurity: UnsavedSocialSecurity) => Promise<void>,
    handleCancel: (isFormChanged: boolean) => void,
    initialSocialSecurity: SocialSecurityDetails,
    updateOwnerName: (name: string) => void,
    investorGroup: InvestorGroupType | undefined
    existingSocialSecurities: SocialSecurity[],
    memberGroup: MemberGroup,
    releaseToggles: ReleaseTogglesType
}

function getPlanningHorizon(memberId: string, investorGroup: InvestorGroupType) {
    let planningPeriod: PlanningPeriodType | undefined = investorGroup.planningPeriod;

    if (investorGroup.partnerMember) {
        if (memberId === investorGroup.primaryMember.id) {
            planningPeriod = investorGroup.primaryMember.planningPeriod;
        } else if (memberId === investorGroup.partnerMember.id) {
            planningPeriod = investorGroup.partnerMember.planningPeriod;
        }
    }

    return planningPeriod ? planningPeriod.ageFrom + planningPeriod.numberOfYears : 0;
}

function getCurrentOwnerByOwnerMemberId(investorGroup: InvestorGroupType, ownerMemberId: string) {
    let selectedOwner = investorGroup.primaryMember;
    if (investorGroup.partnerMember?.id === ownerMemberId) {
        selectedOwner = investorGroup.partnerMember;
    }
    return selectedOwner;
}

function formatTaxRate(taxRate: number): string {
    return taxRate.toFixed(2);
}

export function SocialSecurityForm(props: SocialSecurityProps) {
    const profile = useAppSelector(selectProfile);
    const dispatch = useAppDispatch();
    const mounted = useRef(false);
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    const [socialSecurity, updateSocialSecurity] = useState<SocialSecurityDetails>(props.initialSocialSecurity);
    const [planningHorizon, setPlanningHorizon] = useState<number>();
    const [isSaveButtonDisabled, updateSaveButtonDisabled] = useState(isProfileWithProposalsOrArchived);

    const [startAgeInputValue, setStartAgeInputValue] = useState<number | string>(String(props.initialSocialSecurity.startAge));
    const [grossAnnualFlowInputValue, setGrossAnnualFlowInputValue] = useState<string>(props.initialSocialSecurity.grossAnnualFlow.toFixed(2));
    const [taxRateInputValue, setTaxRateInputValue] = useState<string>(formatTaxRate(props.initialSocialSecurity.taxRate));
    const [inflowReserveLengthInYears, setInflowReserveLengthInYears] = useState(props.initialSocialSecurity.inflowReserveLength);
    const [isInflowWillFundLifestyleGoal, setIsInflowWillFundLifestyleGoal] = useState<boolean>(props.initialSocialSecurity.isInflowWillFundLifestyleGoal);
    const [showNavigationModal, setShowNavigationModal] = useState(true);

    useEffect(() => {
        if (props.investorGroup) {
            setPlanningHorizon(getPlanningHorizon(socialSecurity.memberOwnership.memberId, props.investorGroup));
            props.updateOwnerName(getCurrentOwnerByOwnerMemberId(props.investorGroup, socialSecurity.memberOwnership.memberId).name);
        }
    }, [props.investorGroup]);

    useEffect(() => {
        if (props.investorGroup && planningHorizon) {
            dispatch(setActiveFormAsset({
                assetType: 'futureInflow',
                id: socialSecurity.id,
                inEstateValue: socialSecurity.presentValue || 0,
                description: `${selectedOwner.name} (Social Security)`,
                hasInEstateOwnership: true,
            }));
        }
    }, [socialSecurity.presentValue, props.investorGroup, planningHorizon]);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
            dispatch(setActiveFormAsset(null));
        }
    }, []);

    useEffect(() => {
        const overrides = {
            ...socialSecurity,
            isInflowWillFundLifestyleGoal
        };
        if (planningHorizon) {
            evaluateSocialSecurity(overrides).then((evaluatedSocialSecurity) => {
                if (mounted.current) {
                    updateSocialSecurity(evaluatedSocialSecurity);
                }
            }).catch(reason => console.log(reason));
        }
    }, [
        isInflowWillFundLifestyleGoal,
        socialSecurity.inflowReserveLength,
        socialSecurity.memberOwnership.memberId,
        socialSecurity.startAge,
        socialSecurity.grossAnnualFlow,
        socialSecurity.taxRate
    ]);

    if (!props.investorGroup || !planningHorizon) {
        return <LoadingIndicator/>;
    }

    const primaryMember = props.investorGroup.primaryMember;
    const partnerMember = props.investorGroup.partnerMember;

    const getOwnerById = (ownerId: string): InvestorGroupMember => {
        let selectedClientOwner = primaryMember;
        if (partnerMember?.id === ownerId) {
            selectedClientOwner = partnerMember;
        }
        return selectedClientOwner
    }

    const selectedOwner = getOwnerById(socialSecurity.memberOwnership.memberId);

    const assetSummaryItems = [
        {
            label: 'Net Annual Flow',
            value: formatCurrency(!isNaN(socialSecurity.netAnnualFlow) ? socialSecurity.netAnnualFlow : 0)
        },
        {
            label: 'Years until Flow',
            value: socialSecurity.yearsUntilFlow
        },
        {
            label: 'Years of Flow',
            value: socialSecurity.yearsOfFlow
        },
        {
            label: 'Present Value',
            value: formatCurrency(socialSecurity.presentValue)
        },
    ];

    const handleSave = async () => {
        updateSaveButtonDisabled(true);
        setShowNavigationModal(false);

        const socialSecurityDetails = await evaluateSocialSecurity();
        const memberOwnershipWriteModel = mapToMemberOwnershipWriteModel(socialSecurityDetails.memberOwnership)

        await props.handleSave({
            memberOwnership: memberOwnershipWriteModel,
            startAge: socialSecurityDetails.startAge,
            grossAnnualFlow: socialSecurityDetails.grossAnnualFlow,
            taxRate: socialSecurityDetails.taxRate,
            isInflowWillFundLifestyleGoal: socialSecurityDetails.isInflowWillFundLifestyleGoal,
            inflowReserveLength: inflowReserveLengthInYears,
            ordinal: -1
        });
    }

    const isFormChanged = !deepEquals(socialSecurity, props.initialSocialSecurity);

    const handleCancel = () => {
        setShowNavigationModal(false)
        props.handleCancel(isFormChanged);
    }

    const hasSocialSecurity = (socialSecurities: SocialSecurity[], ownerId?: string) => {
        return Boolean(socialSecurities.find(socialSecurityAsset => socialSecurityAsset.memberOwnership.member.id === ownerId));
    }

    const hasCurrentSocialSecurity = (socialSecurities: SocialSecurity[], ownerId?: string) => {
        const hadCurrentSocialSecurityBeforeEditing = Boolean(socialSecurities
            .filter(socialSecurityAsset => socialSecurityAsset.memberOwnership.member.id === ownerId)
            .filter(socialSecurityAsset => socialSecurityAsset.id === socialSecurity.id).length);
        return socialSecurity.id !== undefined && (socialSecurity.memberOwnership.memberId === ownerId || hadCurrentSocialSecurityBeforeEditing);
    }

    const handleInflowReserveLengthChange = (value: number) => {
        updateSocialSecurity({
            ...socialSecurity,
            inflowReserveLength: value
        });
    }

    const getFirstOwnerOption = () => {
        const primaryMemberHasCurrentSocialSecurity = hasCurrentSocialSecurity(props.existingSocialSecurities, primaryMember.id);
        const partnerMemberHasCurrentSocialSecurity = hasCurrentSocialSecurity(props.existingSocialSecurities, partnerMember?.id);
        const primaryMemberHasSocialSecurity = hasSocialSecurity(props.existingSocialSecurities, primaryMember.id);
        const partnerMemberHasSocialSecurity = hasSocialSecurity(props.existingSocialSecurities, partnerMember?.id);

        if (primaryMemberHasCurrentSocialSecurity || !primaryMemberHasSocialSecurity) {
            return primaryMember;
        }
        if (props.memberGroup.partnerMember?.lifeStatus !== LifeStatus.Deceased &&
            (partnerMemberHasCurrentSocialSecurity || !partnerMemberHasSocialSecurity)) {
            return partnerMember!;
        }
        return primaryMember;
    }

    const getSecondOwnerOption = () => {
        if (partnerMember && props.memberGroup.partnerMember?.lifeStatus !== LifeStatus.Deceased) {
            const partnerMemberHasCurrentSocialSecurity = hasCurrentSocialSecurity(props.existingSocialSecurities, partnerMember.id);
            const partnerMemberHasSocialSecurity = hasSocialSecurity(props.existingSocialSecurities, partnerMember.id);
            if (getFirstOwnerOption() === primaryMember
                && (partnerMemberHasCurrentSocialSecurity || !partnerMemberHasSocialSecurity)) {
                return partnerMember;
            }
        }
        return undefined;
    }

    const changeOwner = (newOwnerMemberId: string) => {
        setPlanningHorizon(getPlanningHorizon(newOwnerMemberId, props.investorGroup!));
        const newOwner = mapSoleMemberOwnershipFormData(newOwnerMemberId)
        updateSocialSecurity({
            ...socialSecurity,
            memberOwnership: newOwner,
        });
    }

    const evaluateSocialSecurity = async (overrides: Partial<EvaluateSocialSecurityRequest> = {})
        : Promise<SocialSecurityDetails> => {
        const socialSecurityBenefitsResponse: SocialSecurityBenefits = await assetsApiClient.evaluateSocialSecurity(
            profile.id,
            socialSecurity.memberOwnership.memberId,
            {
                startAge: socialSecurity.startAge,
                grossAnnualFlow: socialSecurity.grossAnnualFlow,
                taxRate: socialSecurity.taxRate,
                isInflowWillFundLifestyleGoal: socialSecurity.isInflowWillFundLifestyleGoal,
                id: socialSecurity.id,
                inflowReserveLength: socialSecurity.inflowReserveLength,
                ...overrides
            },
        );

        const socialSecurityDetails: SocialSecurityDetails = {
            ...socialSecurity,
            memberOwnership: mapSoleMemberOwnershipFormData(socialSecurity.memberOwnership.memberId),
            ...socialSecurityBenefitsResponse,
        };

        if (mounted.current) {
            setStartAgeInputValue(socialSecurityDetails.startAge);
            setGrossAnnualFlowInputValue(socialSecurityDetails.grossAnnualFlow.toFixed(2));
            setTaxRateInputValue(formatTaxRate(socialSecurityDetails.taxRate));
            setInflowReserveLengthInYears(socialSecurityDetails.inflowReserveLength);
        }

        return socialSecurityDetails;
    }

    const assetAlignmentItems = [
        {
            label: 'Lifestyle Goal Aligned',
            value: formatCurrency(socialSecurity.lifestyleGoalAligned)
        },
        {
            label: 'Excess Future Inflow',
            value: formatCurrency(socialSecurity.excessFutureInflow)
        }
    ];

    return (
        <aside aria-label="Social Security" className="social-security-section">
            <HistoryBlockModal
                when={isFormChanged && showNavigationModal}
                itemType={'page'}
                onSave={handleSave}
            />
            <DataEntryHeader
                className='dataEntryHeader'
                title={props.formatTitle(selectedOwner.name)}
                onPrimaryButtonClick={handleSave}
                onSecondaryButtonClick={handleCancel}
                disablePrimaryButton={isSaveButtonDisabled}
                primaryButtonText="Save"
                secondaryButtonText="Cancel"
            />
            <div className="social-security__form layout-data-entry-form">
                <article>
                    <section>
                        <UnderlinedHeader
                            className="asset-details-section-header"
                            primaryText="Asset Details"
                            secondaryContent={<RequiredFieldsSubheader/>}
                        />
                        <ClientMemberOwnerDropdown
                            label="Owner"
                            value={selectedOwner}
                            firstOwnerOption={getFirstOwnerOption()}
                            secondOwnerOption={getSecondOwnerOption()}
                            hideOwnerAge
                            onChange={changeOwner}
                            disabled={isProfileWithProposalsOrArchived}
                        />
                        <div className="layout-data-entry-form__field">
                            <label id="currentAge-label">
                                <b>Current Age</b>
                            </label>
                            <span role="note" aria-label="currentAge" className="paddingleft-md lineheight-md">
                            {calculateAgeFromBirthdate(selectedOwner.birthdate, ISO8601_DATE_FORMAT)}
                            </span>
                        </div>
                        <div className="layout-data-entry-form__field">
                            <label id="startAge-label">
                                <b>Start Age</b>
                            </label>
                            <NumberInput
                                aria-labelledby="startAge-label"
                                value={startAgeInputValue}
                                onChangeValue={(_event, value) => setStartAgeInputValue(value)}
                                onBlur={() => {
                                        if (startAgeInputValue) {
                                            updateSocialSecurity({
                                                ...socialSecurity,
                                                startAge: parseInt(startAgeInputValue.toString(), 10)
                                            });
                                        } else {
                                            setStartAgeInputValue(socialSecurity.startAge);
                                        }
                                    }
                                }
                                readOnly={isProfileWithProposalsOrArchived}
                            />
                        </div>
                        <CurrencyInputWithLabel
                            label="Gross Annual Flow"
                            name="grossAnnualFlow"
                            value={grossAnnualFlowInputValue}
                            maxLength={15}
                            minValue={0}
                            allowDecimals
                            formatOptions={{maximumFractionDigits: 2, minimumFractionDigits: 2}}
                            onChangeValue={(_event, value) => setGrossAnnualFlowInputValue(String(value))}
                            onBlur={() => {
                                updateSocialSecurity({
                                    ...socialSecurity,
                                    grossAnnualFlow: grossAnnualFlowInputValue ? parseFloat(String(grossAnnualFlowInputValue)) : 0
                                });
                                setGrossAnnualFlowInputValue(grossAnnualFlowInputValue ?? '0.00');
                            }}
                            size="small"
                            readOnly={isProfileWithProposalsOrArchived}
                        />
                        <div className="layout-data-entry-form__field tax-rate">
                            <label id="taxRate-label">
                                <b>Tax Rate</b>
                            </label>
                            <PercentInput defaultValue={"0"}
                                          value={taxRateInputValue}
                                          aria-labelledby="taxRate-label"
                                          label="taxRate"
                                          hideLabel={true}
                                          minValue={0}
                                          maxValue={99}
                                          onChange={(newValue) => setTaxRateInputValue(newValue)}
                                          onBlur={() => {
                                              if (taxRateInputValue) {
                                                  updateSocialSecurity({
                                                      ...socialSecurity,
                                                      taxRate: parseFloat(String(taxRateInputValue))
                                                  });
                                              } else {
                                                  setTaxRateInputValue(String(socialSecurity.taxRate));
                                              }
                                          }}
                                          disabled={isProfileWithProposalsOrArchived}
                            />
                        </div>
                        <div className="layout-data-entry-form__field">
                            <label id="netAnnualFlow-label">
                                <b>Net Annual Flow</b>
                            </label>
                            <span role="note" aria-label="netAnnualFlow" className="paddingleft-md lineheight-md">
                                {formatCurrency(socialSecurity.netAnnualFlow)}
                            </span>
                        </div>
                        <div className="layout-data-entry-form__field">
                            <label id="irs-calculator-label" className="align-self-start">
                                <b>IRS Calculators</b>
                            </label>
                            <span aria-label="irs-calculator-label">
                                <a className="underline" href="https://www.ssa.gov/OACT/quickcalc/index.html"
                                   target="_blank">Quick Calculator</a><br/>
                                <a className="underline"
                                   href="https://www.ssa.gov/benefits/retirement/planner/AnypiaApplet.html"
                                   target="_blank">Online Calculator</a>
                            </span>
                        </div>
                    </section>
                    <InflowCharacteristics
                        willAdjustWithInflation={true}
                        isInflowWillFundLifestyleGoal={isInflowWillFundLifestyleGoal}
                        onFundLifestyleGoalChange={(e) => setIsInflowWillFundLifestyleGoal(e.target.checked)}
                        onChange={NO_OP}
                        interestRate={socialSecurity.interestRate}
                        isAdjustWithInflationCheckboxDisabled={true}
                        isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                    />
                    <InflowReserve
                        isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                        isInflowWillFundLifestyleGoal={isInflowWillFundLifestyleGoal}
                        setInflowReserveLengthInYears={setInflowReserveLengthInYears}
                        inflowReserveLengthInYears={inflowReserveLengthInYears}
                        yearsOfFlow={socialSecurity.yearsOfFlow}
                        inflowReservePresentValue={socialSecurity.inflowReservePresentValue}
                        onBlur={handleInflowReserveLengthChange}
                        releaseToggles={props.releaseToggles}
                    />
                </article>
                <aside aria-label="Asset Summary">
                    <DataEntrySummary
                        items={assetSummaryItems}
                        title="Asset Summary"
                    />
                    {
                        isInflowWillFundLifestyleGoal &&
                        <DataEntrySummary
                            items={assetAlignmentItems}
                            title="Asset Alignment"
                        />
                    }
                </aside>
            </div>
        </aside>
    );
}
