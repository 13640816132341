import React, {useCallback} from 'react';
import FamilyTree from "../../../ClientManagement/FamilyTree/FamilyTree";
import moment from "moment";
import {useHistory, useLocation, useParams} from "react-router-dom";
import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import {DropdownItem, PageActionMenu} from "../../../components";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {RouteWithId} from "../../../routes/types";

import {
    resetFamilyTreeToDefaults,
    selectFamilyTreeViewportBounds,
    selectIsExtendedFamilySelected,
    selectIsOtherMembersSelected,
    setIsExtendedFamilySelected,
    setIsOtherMembersSelected
} from "../../../ClientManagement/FamilyTree/FamilyTreeSlice";

const EditFamilyTreeReport = () => {

    const history = useHistory();
    const location = useLocation();
    const showOtherMembers = useAppSelector(selectIsOtherMembersSelected);
    const showExtendedFamily = useAppSelector(selectIsExtendedFamilySelected);
    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const familyTreeViewportBounds = useAppSelector(selectFamilyTreeViewportBounds);

    const cancelEditFamilyReport = useCallback(() => {
        history.push(`/Profile/${id}/ClientProfile/CreateReport`);
    }, [id, location]);

    const showOtherMembersOfFamilyTree = () => {
        dispatch(setIsOtherMembersSelected(true));
    }
    const hideOtherMembers = () => {
        dispatch(setIsOtherMembersSelected(false));
    }

    const showExtendedFamilyOfFamilyTree = () => {
        dispatch(setIsExtendedFamilySelected(true));
    }
    const hideExtendedFamily = () => {
        dispatch(setIsExtendedFamilySelected(false));
    }

    const fitFamilyMembersToDefaultOnClick = () => {
        if (null !== familyTreeViewportBounds) {
            dispatch(resetFamilyTreeToDefaults());
        }
    }
    const takeScreenShot = useCallback(() => {
        history.push(`/Profile/${id}/ClientProfile/CreateReport`);
    }, [id, location]);

    const component = (
        <PageActionMenu
            className="edit-family-tree-menu marginright-12"
            buttonKind="secondary"
            rounded={false}
            panelWidth={240}
        >
            <DropdownItem
                aria-label="Fit Immediate Family Members"
                itemText="Fit Immediate Family Members"
                value="Fit Immediate Family Members"
                onClick={fitFamilyMembersToDefaultOnClick}
            />
            {!showExtendedFamily ? <DropdownItem
                aria-label="Show Extended Family"
                itemText="Show Extended Family"
                value="Show Extended Family"
                onClick={showExtendedFamilyOfFamilyTree}
            /> : <DropdownItem
                aria-label="Hide Extended Family"
                itemText="Hide Extended Family"
                value="Hide Extended Family"
                onClick={hideExtendedFamily}
            />}
            {!showOtherMembers ? <DropdownItem
                aria-label="Show Other Members"
                itemText="Show Other Members"
                value="Show Other Members"
                onClick={showOtherMembersOfFamilyTree}
            /> : <DropdownItem
                aria-label="Hide Other Members"
                itemText="Hide Other Members"
                value="Hide Other Members"
                onClick={hideOtherMembers}
            />}
        </PageActionMenu>
    );

    return (
        <div>
            <DataEntryHeader
                title={"Family Tree Report Preview"}
                primaryButtonText="Take Screenshot"
                secondaryButtonText="Cancel"
                onPrimaryButtonClick={takeScreenShot}
                onSecondaryButtonClick={cancelEditFamilyReport}
                isExtraHTMLElementRequired={true}
                extraHTMLElementComponent={component}
                SubtitleComponent={() => <span
                    className='holdings-subtitle'>{`As of ${moment().format('MM/DD/YYYY hh:mm A')}`}</span>}
            />

            {
                <div className="edit-family-tree-view">
                    <FamilyTree />
                </div>
            }

        </div>
    )
}

export default EditFamilyTreeReport;
