import React, {MouseEventHandler, ReactNode} from 'react';
import {NO_OP} from '../../constants/common';
import {AlertBanner as XpsAlertBanner} from 'xps-react';

type ActionType = {
    name: string,
    onClick: MouseEventHandler
}

type AlertBannerProps = {
    id?: string,
    showAlert: boolean,
    children?: ReactNode,
    className?: string,
    fullWidth?: boolean,
    icon?: 'warning' | 'error' | 'info' | 'security',
    showCloseBtn?: boolean,
    type?: 'success' | 'warning' | 'error' | 'info' | 'neutral',
    onClose?: MouseEventHandler,
    actions?: ActionType[],
    message?: string,
    detail?: string | ReactNode
}
const AlertBanner: React.FC<AlertBannerProps> = (
    {
        id,
        showAlert,
        children,
        className, 
        fullWidth = false,
        icon = "error",
        showCloseBtn = false,
        type = "error",
        onClose = NO_OP,
        actions=[],
        message,
        detail
    }) => (
    <>
        {showAlert && (
                <XpsAlertBanner
                    id={id}
                    className={className}
                    fullWidth={fullWidth}
                    icon={icon}
                    showAlert={showAlert}
                    showCloseBtn={showCloseBtn}
                    type={type}
                    onClose={onClose}
                    actions={actions}
                    message={message}
                    detail={detail}
                >
                    {children}
                </XpsAlertBanner>
        )}
    </>
);

export default AlertBanner;