import {Button, PresentationPaneHeader, Tab, TabBar, TabPanel, TabsProvider,} from "../../components";
import React, {useEffect, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {RouteWithIdAndActiveTab} from "../../routes/types";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import ReportSetup from "./ReportSetup";
import {TabsConsumer} from "xps-react";
import moment from "moment";
import PreviewReport from "./PreviewReport";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    selectAdviceLibraryDocumentsCount, selectClientDocumentsCount, selectMyDocumentsCount,
    selectPracticeDocumentsCount,
    selectSelectedPagesForReport,
    selectSelectedReportType,
    setSelectedReportType
} from "./ReportingSlice";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {reportingApiClient} from "../ReportingApiClient";
import ModalWrapper from "../../components/Modal/ModalWrapper/ModalWrapper";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import {clientManagementApiClient} from "src/ClientManagement/ClientManagementApiClient";
import {ISO8601_DATE_FORMAT} from "src/constants/common";
import {resetFamilyTreeToDefaults} from "../../ClientManagement/FamilyTree/FamilyTreeSlice";
import {ReleaseTogglesType} from "../../ReleaseToggles/ReleaseToggles";

type ReportHeaderControlsProps = {
    activeIndex: string,
    setActive: any,
    isPreviewDisabled: boolean,
    isSendDisabled: boolean,
    isDownloadDisabled: boolean,
    sendAnnualReportDate: () => void,
    sendReport: () => Promise<void>,
    releaseToggles: ReleaseTogglesType,
    formatFileName: () => string,
    generatedReportUrl: string,
}

function ReportHeaderControls({
                                  activeIndex,
                                  setActive,
                                  isPreviewDisabled,
                                  isSendDisabled,
                                  sendAnnualReportDate,
                                  sendReport,
                                  releaseToggles,
                                  formatFileName,
                                  isDownloadDisabled,
                                  generatedReportUrl,
                              }: ReportHeaderControlsProps) {
    const profile = useAppSelector(selectProfile);
    useEffect(() => {
        if (profile?.id) {
            setActive(0);
        }

    }, [profile?.id]);

    return <>
        {
            parseInt(activeIndex) !== 1 ? (
                <Button
                    className="next-btn"
                    icon="right"
                    iconName="arrow_right"
                    kind="primary"
                    onClick={() => setActive(parseInt(activeIndex) + 1)}
                    size="medium"
                    type="button"
                    rounded={true}
                    disabled={isPreviewDisabled}
                >
                    Preview
                </Button>
            ) : (
                <div>
                    <a href={generatedReportUrl}
                       role="button"
                       aria-label="Download PDF link"
                       download={formatFileName()}
                    >
                        <Button
                            className="next-btn"
                            kind="primary"
                            icon="right"
                            iconName="download"
                            onClick={sendAnnualReportDate}
                            size="medium"
                            type="button"
                            rounded={true}
                            disabled={isDownloadDisabled}
                        >
                            Download PDF
                        </Button>
                    </a>
                    {releaseToggles?.enableSendReportToDCH && <Button
                        className="next-btn marginleft-md"
                        icon="right"
                        iconName="arrow_right"
                        kind="primary"
                        onClick={sendReport}
                        size="medium"
                        type="button"
                        rounded={true}
                        disabled={isSendDisabled}
                    >
                        Send
                    </Button>}
                </div>
            )
        }
    </>
}


const CreateReport = () => {
    const {id} = useParams<RouteWithIdAndActiveTab>();
    const history = useHistory();
    const location = useLocation();
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [showMessageModal, setShowMessageModal] = useState<boolean>(false);
    const [messageModalText, setMessageModalText] = useState<string>('');
    const [generatedReportUrl, setGeneratedReportUrl] = useState<string>('');
    const {approvedProfile} = useProfileAndProposals(id);
    const displayName = approvedProfile.displayName;
    const profile = useAppSelector(selectProfile);
    const selectedPagesForAnnualReportFromRedux = useAppSelector(selectSelectedPagesForReport);
    const adviceLibraryDocumentsCount = useAppSelector(selectAdviceLibraryDocumentsCount);
    const practiceDocumentsCount = useAppSelector(selectPracticeDocumentsCount);
    const clientDocumentsCount = useAppSelector(selectClientDocumentsCount);
    const myDocumentsCount = useAppSelector(selectMyDocumentsCount);
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const dispatch = useAppDispatch();

    const isAnyPageSelected = Object.values(selectedPagesForAnnualReportFromRedux).some(page => page.isEnabled);

    useEffect(() => {
        return () => {
            dispatch(resetFamilyTreeToDefaults());
        };
    }, []);

    const navigateToEditReportPage = (param:string) => {

        const currentPageLocation = location.pathname + `/EditFamilyTree`;
        const nwotPageLocation = location.pathname + `/EditNetWorthOverTime`;
        if(param == "netWorthOverTime") {
            history.push(nwotPageLocation);
        } else {
            history.push(currentPageLocation);
        }
    };

    const convertReportToBase64String = async (): Promise<string> => {
        const reportBlob = await reportingApiClient.getBlobFromReportObjectUrl(generatedReportUrl);
        return await blobToBase64(reportBlob);
    }

    const blobToBase64 = (blob: Blob): Promise<string> => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result as string);
            };
        });
    };

    const sendReport = async () => {
        const fileName: string = formatFileName()
        await reportingApiClient.sendReport(id, {
            ulek: profile!.resourceCode,
            fileName,
            file: ""
        }).catch(() => {
            setMessageModalText("Failed to Upload Report")
            setShowMessageModal(true)
        })
        await convertReportToBase64String();
    };

    function formatFileName() {
        return `${displayName}_Annual Report_${moment().format('MM-DD-YYYY')}`;
    }

    const sendAnnualReportDate = () => {
        if (profile?.id) {
            clientManagementApiClient.putAnnualReportSentDate(profile.id, moment().format(ISO8601_DATE_FORMAT))
                .catch((e) => console.error("Failed to send report generated date: ", e.message));
        }
    }

    const titleRowChildren = () => (
        <TabsConsumer>
            {({activeIndex, setActive}: any) =>
                <ReportHeaderControls
                    activeIndex={activeIndex}
                    setActive={setActive}
                    sendAnnualReportDate={sendAnnualReportDate}
                    sendReport={sendReport}
                    isPreviewDisabled={!isAnyPageSelected && adviceLibraryDocumentsCount === 0 && practiceDocumentsCount === 0 && clientDocumentsCount === 0 && myDocumentsCount === 0}
                    isSendDisabled={(!isAnyPageSelected && adviceLibraryDocumentsCount === 0 && practiceDocumentsCount === 0 && clientDocumentsCount === 0 && myDocumentsCount === 0) || isGenerating}
                    isDownloadDisabled={(!isAnyPageSelected && adviceLibraryDocumentsCount === 0 && practiceDocumentsCount === 0 && clientDocumentsCount === 0 && myDocumentsCount === 0) || isGenerating}
                    releaseToggles={releaseToggles!}
                    formatFileName={formatFileName}
                    generatedReportUrl={generatedReportUrl}
                />
            }
        </TabsConsumer>
    );

    const bottomRowChildren = () => (
        <div className="tab-bar">
            <TabBar theme="light">
                <Tab name="1. Report Setup"/>
                <Tab name="2. Preview"/>
            </TabBar>
        </div>
    )

    return (
        <div className="create-report">
            <TabsProvider tabIdArray={["report-setup", "preview"]}>
                <section data-theme="aqua" className="header">
                    <PresentationPaneHeader className="header"
                                            displayName={displayName}
                                            title="Create a Report"
                                            bottomRow={bottomRowChildren()}
                                            titleRow={titleRowChildren()}
                    />
                </section>
                <TabPanel className="marginbottom-xl">
                    <ReportSetup
                        navigateToEditReportPage={navigateToEditReportPage}
                    />
                    <PreviewReport
                        displayName={displayName}
                        isGenerating={isGenerating}
                        setIsGenerating={setIsGenerating}
                        generatedReportUrl={generatedReportUrl}
                        setGeneratedReportUrl={setGeneratedReportUrl}
                    />
                </TabPanel>
            </TabsProvider>
            <ModalWrapper
                id="message-modal"
                isOpen={showMessageModal}
                headerText={messageModalText}
                size="small"
                buttons={[
                    {
                        text: "Ok",
                        onClick: () => {
                            setMessageModalText('');
                            setShowMessageModal(false);
                        },
                        destructive: false,
                        primary: true,
                    }
                ]}
            />
        </div>
    );
};

export default CreateReport;