import React, {useContext} from "react";
import {useHistory} from "react-router-dom";
import {useAppSelector} from "../../../store/hooks";
import {selectProfile} from "../../../ClientManagement/ClientProfile/activeProfileSlice";
import {InvestorGroupType, MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import {Dropdown, DropdownItem} from "../../../components";
import {AssetsSummary} from "../../models/Assets";
import {selectReleaseToggles} from "src/ReleaseToggles/releaseTogglesSlice";
import AssetsViewContext from "../../common/AssetsViewContext";
import {LifeStatus} from "../../../ClientManagement/models/MemberType";
import useProfileEditableState from "../../../hooks/useProfileEditableState";

export interface AddAssetButtonDropdownProps {
    assets: AssetsSummary,
    className?: string,
    investorGroup: InvestorGroupType,
    memberGroup: MemberGroup,
    showFutureInflowButtons?: boolean,
}

const AddAssetButtonDropdown = ({
                                    assets,
                                    className,
                                    investorGroup,
                                    memberGroup,
                                    showFutureInflowButtons = true
                                }: AddAssetButtonDropdownProps) => {
    const history = useHistory();
    const profileId = useAppSelector(selectProfile).id;
    const profileIsProspect = useAppSelector(selectProfile).prospect;
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const viewType = useContext(AssetsViewContext);
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    const showAddSocialSecurityButton = () => {
        const profileHasOnlyPrimaryMember = investorGroup?.partnerMember === undefined;
        const onlyPrimaryHasSocialSecurity = assets.socialSecurities?.data.length === 1;
        if (profileHasOnlyPrimaryMember && onlyPrimaryHasSocialSecurity) {
            return false;
        }

        const partnerDeceased = memberGroup.partnerMember?.lifeStatus === LifeStatus.Deceased;
        if (partnerDeceased && onlyPrimaryHasSocialSecurity) {
            return false;
        }

        const profileHasBothPrimaryAndPartnerMembers = investorGroup?.primaryMember && investorGroup?.partnerMember;
        const bothMembersHaveSocialSecurity = assets.socialSecurities?.data.length === 2;
        if (profileHasBothPrimaryAndPartnerMembers && bothMembersHaveSocialSecurity) {
            return false;
        }

        return true;
    };

    const dropdownItemArray: { itemText: string, value: string, onClick: () => void } [] = [];

    if (!profileIsProspect && assets?.investmentProgram === null) {
        dropdownItemArray.push({
            itemText: "Investment Program",
            value: "Investment Program",
            onClick: () => {
                history.push(`/Profile/${profileId}/ClientProfile/${viewType}/AddInvestmentProgram`);
            }
        })
    }

    if(releaseToggles?.enablePartiallyOwnedInvestmentAccounts && !profileIsProspect) {
        dropdownItemArray.push(...[
            {
                itemText: "Partially Owned Investment Account",
                value: "Partially Owned Investment Account",
                onClick: () => {
                    history.push(`/Profile/${profileId}/ClientProfile/${viewType}/SearchPartiallyOwnedInvestment`);
                }
            }
        ]);
    }

    dropdownItemArray.push(...[
        {
            itemText: "Standalone Account",
            value: "Standalone Account",
            onClick: () => {
                history.push(`/Profile/${profileId}/ClientProfile/${viewType}/AddStandaloneAccount`);
            }
        }
    ]);

    if (showFutureInflowButtons) {
        dropdownItemArray.push({
            itemText: "General Inflow",
            value: "General Inflow",
            onClick: () => {
                history.push(`/Profile/${profileId}/ClientProfile/${viewType}/AddGeneralInflow`);
            }
        });

        if (showAddSocialSecurityButton()) {
            dropdownItemArray.push({
                itemText: "Social Security",
                value: "Social Security",
                onClick: () => {
                    history.push(`/Profile/${profileId}/ClientProfile/${viewType}/AddSocialSecurity`);
                }
            });
        }
    }

    if (releaseToggles!.enableLifeInsuranceAsset) {
        dropdownItemArray.push({
            itemText: "Life Insurance",
            value: "Life Insurance",
            onClick: () => {
                history.push(`/Profile/${profileId}/ClientProfile/${viewType}/AddLifeInsurance`);
            }
        });
    }

    if (releaseToggles!.enableEquityCompensationAsset) {
        dropdownItemArray.push({
            itemText: "Equity Compensation",
            value: "Equity Compensation",
            onClick: () => {
                history.push(`/Profile/${profileId}/ClientProfile/${viewType}/AddEquityCompensation`);
            }
        });
    }

    dropdownItemArray.push(...[
        {
            itemText: "Personal Asset",
            value: "Personal Asset",
            onClick: () => {
                history.push(`/Profile/${profileId}/ClientProfile/${viewType}/AddPersonalAsset`);
            }
        }, {
            itemText: "Personal Liability",
            value: "Personal Liability",
            onClick: () => {
                history.push(`/Profile/${profileId}/ClientProfile/${viewType}/AddPersonalLiability`);
            }
        }]);

    return <Dropdown
        className={className}
        alignRight
        buttonIcon="left"
        buttonKind="primary"
        dropdownKind="menu"
        defaultText="Add New Asset"
        iconNameOpen="add"
        iconNameClose="add"
        panelWidth={245}
        disabled={isProfileWithProposalsOrArchived}
        rounded
    >
        {dropdownItemArray.map((item: any, i: number) =>
            <DropdownItem key={i}
                          itemText={item.itemText}
                          value={item.value} onClick={item.onClick}/>)}
    </Dropdown>
}

export default AddAssetButtonDropdown;
