import React from 'react';
import {InvestmentProgram} from '../../models/InvestmentProgram';
import {Button} from "../../../components";

type InvestmentProgramSelectionProps = {
    investmentProgram: InvestmentProgram,
    handleSave: (investmentProgram: InvestmentProgram) => void
}
const InvestmentProgramSelection = (props: InvestmentProgramSelectionProps) => {

    return (
        <div className="table-footer">
            <span className="total-accounts-label" data-testid="investment-program-count">
                <span>Total Accounts</span>
                <span className="total-accounts-value">{props.investmentProgram.legalAgreements?.length}</span>
            </span>
            <Button
                icon="left"
                iconName="add"
                size="medium"
                onClick={() => { props.handleSave(props.investmentProgram); }}
            >
                Add Investment Program To Profile
            </Button>
        </div>
    );
};

export default InvestmentProgramSelection;
