import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import AssetRelianceHeader from "./AssetRelianceHeader";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {wealthManagementApiClient} from "../WealthManagementApiClient";
import AssetRelianceContent from "./AssetRelianceContent";
import {
    selectAssetReliancePageActionMenu,
    selectAssetRelianceResponse,
    setAssetReliancePageActionMenu,
    setAssetRelianceResponse
} from "./AssetRelianceSlice";
import LoadingIndicator from 'src/pages/LoadingIndicator';
import usePageViewTimer from "../../hooks/usePageViewTimer";
import {RouteWithId} from "../../routes/types";
import {resetAccordionTableState} from "../../components/AccordionTable/AccordionTableSlice";
import AssetRelianceFooter from "./AssetRelianceFooter";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../../components/Modal/Error/GenericErrorModal";
import {EMPTY_ASSET_RELIANCE_BUTTON_STATE} from "./AssetRelianceButtonState";
import {emptyInvestorGroup, InvestorGroupType} from "../models/InvestorGroupType";
import {clientManagementApiClient} from "../ClientManagementApiClient";
import {assetsApiClient} from "../../Assets/AssetsApiClient";
import {selectClientAssets, setClientAssets} from "../../Assets/clientAssetsSlice";
import {AssetsSummary} from "../../Assets/models/Assets";

const AssetReliance: React.FC = () => {
    const assetResponse = useAppSelector(selectAssetRelianceResponse);
    const assetRelianceButtonState = useAppSelector(selectAssetReliancePageActionMenu);
    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const history = useHistory();
    const unblockRef = useRef();

    const accountHasAssets = !!assetResponse.allAssetsStack.netAssets || !!assetResponse.goals.totalPresentValue;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [genericError, setGenericError] = useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>(emptyInvestorGroup);
    const clientAssets: AssetsSummary | undefined = useAppSelector(selectClientAssets);

    const handleError = ((traceId: string) => {
        console.log(genericError)
        setGenericError({
            isOpen: true,
            header: 'Communication Failure',
            message: 'There has been a communication failure. The data you see may not be accurate, please refresh your browser. If this error continues, please contact the support team.',
            operationId: traceId
        });
    })

    usePageViewTimer('Asset Reliance Page Load Timer (milliseconds)', isLoading);

    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            wealthManagementApiClient.getAssetReliance(id),
            clientManagementApiClient.getInvestorGroup(id),
            assetsApiClient.getAssetsSummary(id),
        ]).then(([
                     assetRelianceResponse,
                     investorGroupResponse,
                     assetsSummaryResponse,
                 ]) => {
            dispatch(setAssetRelianceResponse(assetRelianceResponse));
            dispatch(setClientAssets(assetsSummaryResponse));
            setInvestorGroup(investorGroupResponse);
            setIsLoading(false);
        }).catch((error) => {
            handleError(id)
            console.error('Could not fetch asset reliance data', error.message);
        });
        return () => {
            dispatch(resetAccordionTableState());
        };
    }, [id])

    useEffect(() => {
        unblockRef.current = history.block((newLocation: Location) => {
            dispatch(setAssetReliancePageActionMenu({
                ...EMPTY_ASSET_RELIANCE_BUTTON_STATE
            }))
            return true;
        })
    }, [history])

    if (isLoading) {
        return <LoadingIndicator/>
    }

    return (
        <div className="asset-reliance">
            <AssetRelianceHeader
                id={id}
                title="Review Asset Reliance"
                headerOptions={assetRelianceButtonState}
                investorGroup={investorGroup}
                showPageActionMenu={assetResponse.allAssetsStack.excessAssets > 0}
                lifeInsurances={clientAssets?.lifeInsurances}
            />
            <>
                <AssetRelianceContent
                    goalsSummaryDTO={assetResponse.goals}
                    allAssetsStack={assetResponse.allAssetsStack}
                    assetStacks={assetResponse.assetStacks}
                    excludedAssetStacks={assetResponse.excludedAssetStacks}
                    id={id}
                    accountHasAssets={accountHasAssets}
                    headerOptions={assetRelianceButtonState}
                    investorGroup={investorGroup}
                />
            </>
            <AssetRelianceFooter/>
        </div>
    )
}

export default AssetReliance;