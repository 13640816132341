import React from "react";
import {ReportConfig} from "../../models/Reporting";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../../routes/types";
import {useAssetTitling} from "../../../AssetTitling/useAssetTitling";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {AssetTitlingContent} from "../../../AssetTitling/AssetTitlingContent";
import PrintViewWrapper from "../PrintViewWrapper";

type AssetTitlingWrapperProps = {
    reportConfig: ReportConfig
}
const AssetTitlingReport = ({
                                reportConfig
                            }: AssetTitlingWrapperProps) => {
    const {id} = useParams<RouteWithId>();
    const {
        isLoading,
        assets,
        assetsTotal,
        approvedProfile
    } = useAssetTitling(id);

    if (isLoading || !assets || !assetsTotal) {
        return <LoadingIndicator/>
    }

    return <>
        {reportConfig.assetTitlingAndDistribution.isEnabled &&
            <PrintViewWrapper displayName={approvedProfile.displayName}>
                <AssetTitlingContent
                    assets={assets}
                    currentNetWorthAtDeath={assetsTotal.netWorthValueAtDeath.inEstateValue}
                    approvedProfile={approvedProfile}
                    isIncludedInDetailedReport={false}
                /></PrintViewWrapper>}

        {reportConfig.assetTitlingAndDistributionDetailed.isEnabled && <AssetTitlingContent
            assets={assets}
            currentNetWorthAtDeath={assetsTotal.netWorthValueAtDeath.inEstateValue}
            approvedProfile={approvedProfile}
            isIncludedInDetailedReport={true}
        />}
    </>
}

export default AssetTitlingReport;