import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import React, {useContext, useEffect, useState} from "react";
import {NO_OP} from "../../../constants/common";
import {useHistory, useParams} from "react-router-dom";
import AssetsViewContext from "../../common/AssetsViewContext";
import {useAppSelector} from "../../../store/hooks";
import {selectProfile} from "../../../ClientManagement/ClientProfile/activeProfileSlice";
import {
    initialPartiallyOwnedAccount,
    PartiallyOwnedInvestmentAccountReadModel,
    PartiallyOwnedInvestmentAccountWriteModel
} from "../../models/PartiallyOwnedInvestmentAccount";
import {assetsApiClient} from "../../AssetsApiClient";
import {RouteWithProfileIdAndAssetId} from "../../../routes/types";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {AssetClassifications} from "../../models/AssetClassifications";
import {HoldingsScrollableContainer} from "../../StandaloneAccount/Holdings/HoldingsScrollableContainer";
import {HoldingSummaryAccordion} from "../../StandaloneAccount/Holdings/HoldingSummaryAccordion";
import {HoldingSummaryAssetSubclass} from "../../StandaloneAccount/Holdings/HoldingSummaryAssetSubclass";
import {HoldingAssetSubclassDetails} from "../../models/Holding";
import {
    LegalAgreementHoldingSummarySubclassDetailsRow
} from "../../InvestmentProgram/LegalAgreementHoldingSummarySubclassDetailsRow";
import Icon from "../../../components/Icon/Icon";
import HoldingsInfo from "../../StandaloneAccount/Holdings/HoldingsInfo";
import {TaxStatusOptions} from "../../models/TaxDetails";
import {DomesticTrustHoldingWriteModel} from "../../InvestmentProgram/DomesticTrustAccountWriteModel";

export default function PartiallyOwnedInvestmentAccountHoldings() {
    const history = useHistory();
    const viewType = useContext(AssetsViewContext);
    const profile = useAppSelector(selectProfile);
    const {profileId, assetId} = useParams<RouteWithProfileIdAndAssetId>();
    const [classifications, setClassifications] = useState<AssetClassifications>();
    const [account, setAccount] = useState<PartiallyOwnedInvestmentAccountWriteModel>(initialPartiallyOwnedAccount);
    const [updatedDomesticTrustHoldings, setUpdatedDomesticTrustHoldings] = useState<HoldingAssetSubclassDetails[]>([]);
    const [domesticTrustHoldings, setDomesticTrustHoldings] = useState<HoldingAssetSubclassDetails[]>([]);
    const [isDoneButtonDisabled, updateDoneButtonDisabled] = useState(false);

    useEffect(() => {
        Promise.all([
            assetsApiClient.getPartiallyOwnedInvestment(profile.id, assetId),
            assetsApiClient.getAssetClassifications()
        ])
            .then(([partiallyOwnedInvestmentAccountResponse, assetClassificationResponse]) => {
                mapPartiallyOwnedInvestmentAccountToWriteModel(partiallyOwnedInvestmentAccountResponse);
                setClassifications(assetClassificationResponse)
            });
    }, [assetId]);


    useEffect(() => {
        if (account.financialAccountPersistentId.length === 0) {
            return;
        }
        assetsApiClient.getDomesticTrustHoldingsForPartiallyOwnedAccount(
            profileId,
            account.financialAccountPersistentId,
            account.isEntityCapitalGains ?? account.isEntityCapitalGains ?? false,
            account.isLiabilityPaidByPortfolio ?? account.isLiabilityPaidByPortfolio ?? false,
            account.taxStatus)
            .then(holdingsResponse => {
                setDomesticTrustHoldings(holdingsResponse.allHoldings);
                setUpdatedDomesticTrustHoldings(holdingsResponse.allHoldings.map(holding => (
                    {
                        ...holding
                    }))
                );
            })
            .catch(e => console.error(e));
    }, [account, profileId])

    const mapPartiallyOwnedInvestmentAccountToWriteModel = (partiallyOwnedInvestmentAccount: PartiallyOwnedInvestmentAccountReadModel) => {
        const {
            number,
            typeCode,
            domesticTrustAccounts,
            marketEstateValue,
            ...rest
        } = partiallyOwnedInvestmentAccount;
        const domesticTrustAccount = domesticTrustAccounts[0];

        setAccount({
            ...account,
            ...rest,
            financialAccountPersistentId: domesticTrustAccount.financialAccountPersistentId,
            legalAgreementNumber: number,
            legalAgreementTypeCode: typeCode,
            accountNumber: domesticTrustAccount.accountNumber,
            accountStatus: domesticTrustAccount.accountStatus
        });
    };

    const handleLockHolding = async (index: number, checked: boolean) => {
        handleAssetSubclassDetailUpdate(index, (assetSubclassDetail: HoldingAssetSubclassDetails) => {
            assetSubclassDetail.locked = checked
        })
    }

    const handleConcentrateHolding = async (index: number, checked: boolean) => {
        handleAssetSubclassDetailUpdate(index, (assetSubclassDetail: HoldingAssetSubclassDetails) => {
            assetSubclassDetail.concentrated = checked
        })
    }

    const handleAssetSubclassDetailUpdate = async (index: number, changeFunction: (assetSubclassDetail: HoldingAssetSubclassDetails) => void) => {
        if (index >= 0) {
            const holdingToUpdate = updatedDomesticTrustHoldings[index];
            changeFunction(holdingToUpdate);

            setUpdatedDomesticTrustHoldings([
                ...updatedDomesticTrustHoldings.slice(0, index),
                holdingToUpdate,
                ...updatedDomesticTrustHoldings.slice(index + 1, updatedDomesticTrustHoldings.length),
            ])
        }
    }

    const handleDone = async () => {
        const updatedHoldings: DomesticTrustHoldingWriteModel[] = updatedDomesticTrustHoldings
            .filter((trustHoldings, index) => {
                return trustHoldings.locked !== domesticTrustHoldings[index].locked
                    || trustHoldings.concentrated !== domesticTrustHoldings[index].concentrated
            })
            .map((trustHolding) => {
                const domesticTrustHoldingWriteModel: DomesticTrustHoldingWriteModel = {
                    holdingPersistentId: trustHolding.id!,
                    locked: trustHolding.locked,
                    concentrated: trustHolding.concentrated
                }
                return domesticTrustHoldingWriteModel;
            });
        if (updatedHoldings.length > 0) {
            updateDoneButtonDisabled(true);
            const response =
                await assetsApiClient.putLegalAgreementHoldings(
                    profile.id,
                    {domesticTrustHoldings: updatedHoldings}
                );
            if (response.status === 200) {
                navigateToEditPOIA()
            }
        } else {
            navigateToEditPOIA();
        }
    }

    function navigateToEditPOIA() {
        history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditPartiallyOwnedInvestment/${assetId}`);
    }

    const assetSubclassDetailsRowRenderer = (assetSubclassDetail: HoldingAssetSubclassDetails, index: number) =>
        <LegalAgreementHoldingSummarySubclassDetailsRow
            key={`asset-subclass-details-${assetSubclassDetail.id}-${assetSubclassDetail.productName}`}
            index={index}
            assetSubclassDetail={assetSubclassDetail}
            handleConcentrateHolding={handleConcentrateHolding}
            handleLockHolding={handleLockHolding}
        />;

    if (!(classifications) || !(domesticTrustHoldings) || !(account)) {
        return (<LoadingIndicator/>)
    }

    return (
        <div className="legal-agreement-holdings asset-form holdings-container">
            <div className="layout-data-entry-form">
                <DataEntryHeader
                    className='dataEntryHeader'
                    title={account.name}
                    onPrimaryButtonClick={handleDone}
                    disablePrimaryButton={isDoneButtonDisabled}
                    primaryButtonText="Done"
                    hideSecondaryButton={true}
                />
            </div>
            <HoldingsInfo
                account={{
                    institution: account.institution,
                    accountNumber: account.legalAgreementNumber,
                    taxStatus: account.taxStatus as TaxStatusOptions,
                    memberOwnerships: [],
                    legalEntityOwnerships: [],
                    ownershipCategory: "Sole"
                }}
                allHoldings={updatedDomesticTrustHoldings}
                classifications={classifications}
            />
            <div className="holding-grid holding-grid-container">
                <div className="grid-display-contents" role="row">
                        <span className="display-flex align-items-center paddingbottom-md"
                              role="cell">
                            <Icon name="chevron_double_right"/>
                            <span className="condensed-subtitle paddingleft-md">Asset Class / Subclass</span>
                        </span>
                    <span className="condensed-subtitle" role="cell">Product</span>
                    <span className="textalign-right condensed-subtitle" role="cell">Account</span>
                    <span className="condensed-subtitle textalign-right" role="cell">Tax Cost</span>
                    <span className="condensed-subtitle textalign-right" role="cell">Market Value</span>
                    <span className="condensed-subtitle textalign-right" role="cell">Investable Value</span>
                    <span className="condensed-subtitle textalign-center" role="cell">Locked</span>
                    <span className="condensed-subtitle textalign-left" role="cell">Concen</span>
                </div>
                <div className="empty-grid-row"/>
                <HoldingsScrollableContainer>
                    <HoldingSummaryAccordion
                        riskAssetClasses={classifications?.riskAssetClasses}
                        riskControlAssetClasses={classifications?.riskControlAssetClasses}
                        assetSubclassRowRenderer={(assetSubclass, holdingsWithIndex, subclassIndex) => {
                            return <HoldingSummaryAssetSubclass
                                key={subclassIndex}
                                assetSubclass={assetSubclass}
                                holdingsWithIndex={holdingsWithIndex}
                                subclassIndex={subclassIndex}
                                assetSubclassDetailsRowRenderer={assetSubclassDetailsRowRenderer}
                            />
                        }}
                        selectedHoldings={domesticTrustHoldings}
                    />
                </HoldingsScrollableContainer>
            </div>
        </div>
    );
}
