import React, {useEffect, useMemo, useState} from "react";
import {Button, EmptyStateContainer} from "../components";
import ScrollableContainer from "../components/ScrollableContainer/ScrollableContainer";
import {AssetsSummary} from "../Assets/models/Assets";
import {AssetTitlingBarChartSidebar} from "./AssetTitlingBarChartSidebar";
import {AssetTitlingAccordion} from "./AssetTitlingAccordion";
import {AssetTitlingHeader} from "./AssetTitlingHeader";
import {useHistory} from "react-router-dom";
import {ProfileResponse} from "../ClientManagement/models/ProfileResponse";
import {AssetTitlingAccordionItem, mapAssetsToAssetTitlingAccordionItems} from "./mappers";
import {splitAssetTitlingData} from "../Reporting/DetailedEmailReports/AssetTitlingReport/assetTitlingUtil";
import PrintViewWrapper from "../Reporting/DetailedEmailReports/PrintViewWrapper";

export type AssetTitlingContentProps = {
    assets: AssetsSummary,
    currentNetWorthAtDeath: number,
    approvedProfile: ProfileResponse,
    isIncludedInDetailedReport: boolean
};

export function AssetTitlingContent(
    {
        assets,
        currentNetWorthAtDeath,
        approvedProfile,
        isIncludedInDetailedReport
    }: AssetTitlingContentProps) {
    const memoizedAccordionItems = useMemo(() => {
        return mapAssetsToAssetTitlingAccordionItems(assets);
    }, [assets]);

    const barChartGroup = {
        'Joint Tenancy': memoizedAccordionItems[0].totalValue,
        'Beneficiary Designation': memoizedAccordionItems[1].totalValue,
        'Will': memoizedAccordionItems[2].totalValue,
        'Revocable Trust': memoizedAccordionItems[3].totalValue
    };

    const [splitAssetTitling, setSplitAssetTitling] = useState<Array<AssetTitlingAccordionItem[]>>([]);

    useEffect(() => {
        if (isIncludedInDetailedReport && currentNetWorthAtDeath > 0 && !splitAssetTitling.length) {
            splitAssetTitlingData(memoizedAccordionItems).then(splitData => {
                setSplitAssetTitling(splitData);
            });
        } else {
            setSplitAssetTitling([memoizedAccordionItems]);
        }
    }, [currentNetWorthAtDeath]);

    const WrapperComponent = useMemo(() => {
        return getConditionalWrapperComponent(isIncludedInDetailedReport, PrintViewWrapper);
    }, [isIncludedInDetailedReport]);

    return (<>
        {splitAssetTitling.map((assetTitlingItem, index) =>
            <WrapperComponent displayName={approvedProfile.displayName} key={index}>
                <article className="summary-page layout-split-left asset-titling-summary-page">
                    <AssetTitlingBarChartSidebar
                        profileDisplayName={approvedProfile.displayName}
                        barChartGroup={barChartGroup}
                        currentNetWorthAtDeath={currentNetWorthAtDeath}
                        title={`Asset Titling & Distribution${index > 0 ? ' (continued)' : ''}`}
                    />
                    <section>
                        {isIncludedInDetailedReport ?
                            <div className="summary-page-content">
                                <DisplayAssetTitlingItems
                                    assetTitlingAccordionItems={assetTitlingItem}
                                    currentNetWorthAtDeath={currentNetWorthAtDeath}
                                    approvedProfile={approvedProfile}
                                    isFromReport={isIncludedInDetailedReport}
                                    pageIndex={index}/>
                            </div>
                            : <ScrollableContainer>
                                <div className="summary-page-content">
                                    <DisplayAssetTitlingItems
                                        assetTitlingAccordionItems={assetTitlingItem}
                                        currentNetWorthAtDeath={currentNetWorthAtDeath}
                                        approvedProfile={approvedProfile}
                                        pageIndex={index}/>
                                </div>
                            </ScrollableContainer>}
                    </section>
                </article>
            </WrapperComponent>)}
    </>);
}

type DisplayAssetTitlingItemsProps = {
    currentNetWorthAtDeath: number,
    approvedProfile: ProfileResponse,
    assetTitlingAccordionItems: AssetTitlingAccordionItem[],
    isFromReport?: boolean,
    pageIndex: number
}

function DisplayAssetTitlingItems({
                                      currentNetWorthAtDeath,
                                      approvedProfile,
                                      assetTitlingAccordionItems,
                                      isFromReport,
                                      pageIndex
                                  }: DisplayAssetTitlingItemsProps): JSX.Element {
    const history = useHistory();

    return <>
        {
            currentNetWorthAtDeath > 0
                ? <>
                    {pageIndex === 0 && <div className="section-header">
                        <AssetTitlingHeader currentNetWorthAtDeath={currentNetWorthAtDeath}/>
                    </div>}
                    <section>
                        <AssetTitlingAccordion accordionItems={assetTitlingAccordionItems} isFromReport={isFromReport}/>
                    </section>
                </>
                : <EmptyStateContainer
                    hideBorder
                    title="No transferable have been captured yet."
                    size="large"
                    description={<span>
                                        Add assets to review Asset Titling & Distribution
                                        <p>
                                        {!isFromReport && <Button
                                            icon='right'
                                            iconName="arrow_right"
                                            kind="secondary"
                                            size='medium'
                                            onClick={() => {
                                                history.push(`/Profile/${approvedProfile.id}/ClientProfile/AssetSummary`);
                                            }}
                                            className={"asset-titling-summary-btn"}
                                            rounded>
                                            ASSET SUMMARY
                                        </Button>}
                                        </p>
                                </span>}
                />
        }
    </>;
}

const getConditionalWrapperComponent = (isWrapperRequired: boolean, Wrapper: any) => {
    return isWrapperRequired ? (props: any) => <Wrapper {...props} /> : (props: any) => <>{props.children}</>
};
