import React, {useEffect, useState} from "react";
import EditFormContainer from "../EditFormContainer";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {SocialSecurityForm} from "./SocialSecurityForm";
import {SocialSecurity, SocialSecurityDetails, UnsavedSocialSecurity} from "../models/SocialSecurity";
import {assetsApiClient} from "../AssetsApiClient";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithAssetId} from "../../routes/types";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {InvestorGroupType, MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {mapToMemberOwnershipFormData} from "../Ownership/mappers";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";

const EditSocialSecurity = () => {
    const history = useHistory();
    const profile = useAppSelector(selectProfile);
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const [socialSecurity, setSocialSecurity] = useState<SocialSecurity>();
    const {assetId: socialSecurityId} = useParams<RouteWithAssetId>();
    const [ownerName, setOwnerName] = useState<string>("");
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>();
    const [existingSocialSecurities, setExistingSocialSecurities] = useState<SocialSecurity[]>([]);
    const [memberGroup, setMemberGroup] = useState<MemberGroup>();

    useEffect(() => {
        let componentUnmounted = false;
        assetsApiClient.getSocialSecurity(profile.id, socialSecurityId)
            .then(socialSecurityResponse => {
                if (componentUnmounted) return;
                setSocialSecurity(socialSecurityResponse);
            });
        return () => {
            componentUnmounted = true;
        }
    }, [socialSecurityId, history.location.pathname]);

    useEffect(() => {
        let isSubscribed = true;

        Promise.all([
            clientManagementApiClient.getInvestorGroup(profile.id),
            assetsApiClient.getSocialSecuritiesByProfileId(profile.id),
            clientManagementApiClient.getMemberGroup(profile.id),
        ]).then(([ investorGroupResponse, socialSecuritiesResponse, memberGroupResponse]) => {
            if (!isSubscribed) return;
            setInvestorGroup(investorGroupResponse);
            setExistingSocialSecurities(socialSecuritiesResponse);
            setMemberGroup(memberGroupResponse)
        }).catch(error => console.error('Could not fetch asset details', error.message));

        return () => {
            isSubscribed = false;
        }
    }, [profile.id]);

    if (!socialSecurity || !existingSocialSecurities || !memberGroup) {
        return <LoadingIndicator/>;
    }

    const handleSave = async (unsavedData: UnsavedSocialSecurity) => {
        const response = await assetsApiClient.putSocialSecurity({
            profileId: profile.id,
            ...unsavedData
        }, socialSecurityId);
        if (response.status === 200) {
            history.push(`/Profile/${profile.id}/ClientProfile/AssetSummary`);
        }
    };

    const updateOwnerName = (name: string) => {
        setOwnerName(name);
    }
    const handleDelete = async () => {
        const response = await assetsApiClient.deleteSocialSecurity(profile.id, socialSecurityId);
        if (response.status === 200) {
            history.push(`/Profile/${profile.id}/ClientProfile/AssetSummary`);
        }
    };

    const initialSocialSecurity: SocialSecurityDetails = {
        id: socialSecurity.id,
        memberOwnership: mapToMemberOwnershipFormData(socialSecurity.memberOwnership),
        startAge: socialSecurity.startAge,
        grossAnnualFlow: socialSecurity.grossAnnualFlow,
        taxRate: socialSecurity.taxRate,
        netAnnualFlow: socialSecurity.netAnnualFlow,
        yearsUntilFlow: socialSecurity.yearsUntilFlow,
        yearsOfFlow: socialSecurity.yearsOfFlow,
        presentValue: socialSecurity.presentValue,
        interestRate: socialSecurity.interestRate,
        isInflowWillFundLifestyleGoal: socialSecurity.isInflowWillFundLifestyleGoal,
        lifestyleGoalAligned: socialSecurity.lifestyleGoalAligned,
        excessFutureInflow: socialSecurity.excessFutureInflow,
        inflowReservePresentValue: socialSecurity.inflowReservePresentValue,
        inflowReserveLength: socialSecurity.inflowReserveLength,
    };

    return (
        <div className='social-security'>
            <EditFormContainer
                modalTitle="Asset"
                assetType="Social Security"
                assetDescription={ownerName}
                handleDelete={handleDelete}
                form={(handleCancel) => {
                    return (
                        <SocialSecurityForm
                            formatTitle={selectedOwner => `Edit ${selectedOwner}\'s Social Security`}
                            handleSave={handleSave}
                            handleCancel={handleCancel}
                            initialSocialSecurity={initialSocialSecurity}
                            updateOwnerName={updateOwnerName}
                            investorGroup={investorGroup}
                            existingSocialSecurities={existingSocialSecurities}
                            memberGroup={memberGroup}
                            releaseToggles={releaseToggles!}
                        />
                    );
                }}
            />
        </div>
    );
}

export default EditSocialSecurity;

