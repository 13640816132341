import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../store/store";
import {emptyPortfolioReserveModel, PortfolioReserveModelType} from "./models/PortfolioReserveModelType";
import {
    emptyPortfolioReserveOptions,
    emptyPortfolioReserveUserPreferences,
    PortfolioReserveOptions,
    PortfolioReserveUserPreferences
} from "./models/PortfolioReserveOptions";
import {emptyPortfolioReserveButtonState, PortfolioReserveButtonState} from "./models/PortfolioReserveButtonState";
import {emptyPortfolioReserveYears, PortfolioReserveYearsType} from "./models/PortfolioReserveYearsType";
import {PortfolioReserveResponse} from "../models/PortfolioReserve";
import {LifestylePresentValueResponse} from "../models/PresentValue";
import {emptyMonteCarloResponse, MonteCarloResponse} from "../models/MonteCarlo";

export type PortfolioReserveState = {
    portfolioReserveModel: PortfolioReserveModelType | undefined;
    portfolioReserveUserPreferences: PortfolioReserveUserPreferences;
    buttonState: PortfolioReserveButtonState;
    portfolioReserveYears: PortfolioReserveYearsType;
    monteCarlo: MonteCarloResponse,
    portfolioReserveOptions: PortfolioReserveOptions
}

export const initialState: PortfolioReserveState = {
    portfolioReserveModel: {...emptyPortfolioReserveModel},
    portfolioReserveUserPreferences: {
        ...emptyPortfolioReserveUserPreferences,
    },
    buttonState: {
        excessAssets: false,
        portfolioRisk: false,
        futureInflow: false,
        assetAllocation: false
    },
    portfolioReserveYears: {...emptyPortfolioReserveYears},
    monteCarlo: emptyMonteCarloResponse,
    portfolioReserveOptions: emptyPortfolioReserveOptions
}

export const portfolioReserveSlice = createSlice({
    name: 'portfolioReserve',
    initialState,
    reducers: {
        setPortfolioReserveModel: (state, action: PayloadAction<PortfolioReserveModelType>) => {
            return {
                ...state,
                portfolioReserveModel: action.payload,
            }
        },
        setPortfolioReserveUserPreferences: (state, action: PayloadAction<PortfolioReserveUserPreferences>) => {
            return {
                ...state,
                portfolioReserveUserPreferences: action.payload
            }
        },
        setPortfolioReserve: (state, action: PayloadAction<PortfolioReserveResponse>) => {
            return {
                ...state,
                portfolioReserveModel: {
                    ...state.portfolioReserveModel!,
                    portfolioReserve: action.payload
                }
            }
        },
        setReserveTargetPresentValue: (state, action: PayloadAction<LifestylePresentValueResponse>) => {
            return {
                ...state,
                portfolioReserveModel: {
                    ...state.portfolioReserveModel!,
                    reserveTargetPresentValue: action.payload
                }
            }
        },
        setMinimumRiskPresentValue: (state, action: PayloadAction<LifestylePresentValueResponse>) => {
            return {
                ...state,
                portfolioReserveModel: {
                    ...state.portfolioReserveModel!,
                    minimumRiskPresentValue: action.payload
                }
            }
        },
        setPortfolioReserveButtonStates: (state, action: PayloadAction<PortfolioReserveButtonState>) => {
            state.buttonState = action.payload;
        },
        resetPortfolioReserveButtonStates: (state) => {
            state.buttonState = {...emptyPortfolioReserveButtonState};
        },
        setPortfolioReserveYears: (state, action: PayloadAction<PortfolioReserveYearsType>) => {
            state.portfolioReserveYears = action.payload;
        },
        resetPortfolioReserveYears: (state) => {
            state.portfolioReserveYears = {...emptyPortfolioReserveYears};
        },
        setMonteCarlo: (state, action: PayloadAction<MonteCarloResponse>) => {
            state.monteCarlo = action.payload
        },
        setPortfolioReserveOptions: (state, action: PayloadAction<PortfolioReserveOptions>) => {
            state.portfolioReserveOptions = action.payload
        },
    }
});

export const {
    setPortfolioReserveModel,
    setPortfolioReserveUserPreferences,
    setPortfolioReserveButtonStates,
    resetPortfolioReserveButtonStates,
    setPortfolioReserveYears,
    setMonteCarlo,
    setPortfolioReserveOptions
} = portfolioReserveSlice.actions;

export const selectPortfolioReserveModel = (state: RootState): PortfolioReserveModelType => {
    return state.portfolioReserve?.portfolioReserveModel
        ? state.portfolioReserve.portfolioReserveModel
        : emptyPortfolioReserveModel;
};

export const selectPortfolioReserveUserPreferences = (state: RootState): PortfolioReserveUserPreferences => {
    return state.portfolioReserve?.portfolioReserveUserPreferences
        ? state.portfolioReserve.portfolioReserveUserPreferences
        : emptyPortfolioReserveUserPreferences;
}

export const selectPortfolioReserveButtonStates = (state: RootState): PortfolioReserveButtonState => {
    return state.portfolioReserve?.buttonState
        || {...emptyPortfolioReserveButtonState};
}

export const selectPortfolioReserveYears = (state: RootState): PortfolioReserveYearsType => {
    return state.portfolioReserve?.portfolioReserveYears
        || {...emptyPortfolioReserveYears};
}

export const selectMonteCarlo = (state: RootState): MonteCarloResponse => {
    return state.portfolioReserve?.monteCarlo || {...emptyMonteCarloResponse}
}

export const selectPortfolioReserveOptions = (state: RootState): PortfolioReserveOptions => {
    return state.portfolioReserve?.portfolioReserveOptions || emptyPortfolioReserveOptions
}

export default portfolioReserveSlice.reducer;

