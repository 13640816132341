import React from "react";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../routes/types";
import LoadingIndicator from "../pages/LoadingIndicator";
import {AssetTitlingContent} from "./AssetTitlingContent";
import {useAssetTitling} from "./useAssetTitling";

export default function AssetTitling() {
    const {id} = useParams<RouteWithId>();
    const {
        isLoading,
        assets,
        assetsTotal,
        approvedProfile
    } = useAssetTitling(id);

    if (isLoading || !assets || !assetsTotal) {
        return <LoadingIndicator/>
    }

    return <AssetTitlingContent
        assets={assets}
        currentNetWorthAtDeath={assetsTotal.netWorthValueAtDeath.inEstateValue}
        approvedProfile={approvedProfile}
        isIncludedInDetailedReport={false}
    />
}