import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {ActiveFormAsset, InEstateAssetTotals} from "../../../Assets/clientAssetsSlice";
import {AssetsSummary} from "../../../Assets/models/Assets";
import React, {useEffect, useState} from "react";
import {formatCurrency} from "../../../utils/format";
import {
    calculateCNWInEstateTotalValue,
    calculateOutOfEstateTotalValue
} from "../../../Assets/AssetSummary/common/AssetSummaryCalculator";
import {assetListData, hasAssets, hasInEstateAssets} from "../../../Assets/AssetSummary/common/utils";
import {BarChartSidebar} from "../../../components";
import {createAssetBarChartData} from "../../../Assets/AssetSummary/BarChart/AssetBarChartUtils";
import {AssetBarChartFooter} from "../../../Assets/AssetSummary/BarChart/AssetBarChartFooter";
import CurrentNetWorthAssetListReport from "./CurrentNetWorthAssetListReport";
import {
    hasOutOfEstateLegalEntities,
    hasOutOfEstateNonClientSummaries,
    hasSufficientSpace,
    InAndOutEstateAssets,
    mergeAssetSummariesAndLegalEntitiesPages,
    mergeLegalEntitiesAndNonClientPages,
    splitNonClientMemberSummaries,
    splitOutOfEstateLegalEntities
} from "../AssetSummaryDetailReport/AssetsReportUtils";
import PrintViewWrapper from "../PrintViewWrapper";
import {useAppDispatch} from "../../../store/hooks";
import {LegalEntityReadModel} from "../../../Assets/models/Ownership";
import {
    createLegalEntityTypeSummaries,
    createNonClientMemberSummaries,
    LegalEntityTypeSummary,
    NonClientMemberSummary
} from "../../../Assets/mappers";
import {setAccordionPreferencesState} from "../../../Assets/common/accordionPreferencesSlice";
import OutOfEstateLegalEntityReportView from "../AssetSummaryDetailReport/OutOfEstateLegalEntityReportView";
import OutOfEstateNonClientSummariesView from "../AssetSummaryDetailReport/OutOfEstateNonClientSummariesView";
import {splitCNWAssets} from "./CurrentNWUtil";


export type CurrentNetWorthReportViewProps = {
    investorGroup?: InvestorGroupType,
    clientAssets: AssetsSummary,
    showDeathBenefit: boolean,
    displayName: string,
    inEstateTotals: InEstateAssetTotals,
    activeFormAsset: ActiveFormAsset,
    legalEntities: LegalEntityReadModel[],
    isDetailed: boolean,
    profileId: string
}

export function CurrentNetWorthReportView({
                                              investorGroup,
                                              clientAssets,
                                              showDeathBenefit,
                                              displayName,
                                              inEstateTotals,
                                              legalEntities,
                                              isDetailed,
                                              profileId
                                          }: CurrentNetWorthReportViewProps) {

    const dispatch = useAppDispatch();
    const [CurrentNetWorthReportPages, setCurrentNetWorthReportPages] = useState<InAndOutEstateAssets[]>([]);

    const [legalEntityTypeSummaries, setLegalEntityTypeSummaries] = useState<LegalEntityTypeSummary[]>([]);
    const [nonClientMemberSummaries, setNonClientMemberSummaries] = useState<NonClientMemberSummary[]>([]);
    const [allItems, setAllItems] = useState<any[]>([]);
    const [firstLegalEntityPageIndex, setFirstLegalEntityPageIndex] = useState<number>();

    function expandNonClientAccordions(nonClientNamesToExpand: string[]) {
        const nonClientAccordionNames = nonClientNamesToExpand.map((accordionName) => accordionName + "-report");
        dispatch(setAccordionPreferencesState({
            accordionId: "CurrentNetWorthOutOfEstateNonClientAccordionReport",
            state: {
                expandedItems: [...nonClientAccordionNames]
            }
        }));
    }

    useEffect(() => {
        if(!isDetailed) {
            setCurrentNetWorthReportPages([{
                assetsSummary: clientAssets,
                legalEntitySummary: undefined,
                nonClientMemberSummary: undefined
            }]);
        } else {
            //assetSumaries pages
            const {assetSummariesData, remainingSpaceAfterSplittingAssets} = splitCNWAssets(clientAssets);
            const assetSummariesPages: InAndOutEstateAssets[] = [];
            assetSummariesData.forEach(summary => {
                assetSummariesPages.push({
                    assetsSummary: summary,
                    legalEntitySummary: undefined,
                    nonClientMemberSummary: undefined
                });
            });
            if (hasSufficientSpace(remainingSpaceAfterSplittingAssets)) {
                setFirstLegalEntityPageIndex(assetSummariesPages.length - 1);
            } else {
                setFirstLegalEntityPageIndex(assetSummariesPages.length);
            }
            //legal pages
            let tempLegalEntityTypeSummaries = createLegalEntityTypeSummaries('CurrentNetWorth', clientAssets, legalEntities);
            const {legalEntityTypeSummariesArray, remainingSpaceAfterLegalEntities} =  splitOutOfEstateLegalEntities(tempLegalEntityTypeSummaries, remainingSpaceAfterSplittingAssets);
            const legalEntitySummariesPages: InAndOutEstateAssets[] = [];
            legalEntityTypeSummariesArray.forEach((summary) => {
                legalEntitySummariesPages.push({
                    assetsSummary: undefined,
                    legalEntitySummary: summary,
                    nonClientMemberSummary: undefined
                })
            })
            const legalEntityNames = [...tempLegalEntityTypeSummaries.map((summary: { entityType: any; }) => summary.entityType)];
            setLegalEntityTypeSummaries(tempLegalEntityTypeSummaries);
            //non client pages
            let tempNonClientMemberSummaries = createNonClientMemberSummaries('CurrentNetWorth', clientAssets);
            const  nonClientMemberSummariesArray: Array<NonClientMemberSummary[]> = splitNonClientMemberSummaries(tempNonClientMemberSummaries, remainingSpaceAfterLegalEntities);
            const nonClientMemberSummariesPages: InAndOutEstateAssets[] = [];
            nonClientMemberSummariesArray.forEach((summary) => {
                nonClientMemberSummariesPages.push({
                    assetsSummary: undefined,
                    legalEntitySummary: undefined,
                    nonClientMemberSummary: summary
                })
            })
            const nonClientNames = [
                ...tempNonClientMemberSummaries.map((summary: { memberName: any; }) => `${summary.memberName}-assets-summary`)
            ];
            setNonClientMemberSummaries(tempNonClientMemberSummaries);
            setAllItems([...legalEntityNames, ...nonClientNames]);

            const allAssetsPages: InAndOutEstateAssets[] = [...assetSummariesPages, ...legalEntitySummariesPages, ...nonClientMemberSummariesPages];

            if (assetSummariesPages.length > 0 && legalEntitySummariesPages.length > 0 && hasSufficientSpace(remainingSpaceAfterSplittingAssets)) {
                mergeAssetSummariesAndLegalEntitiesPages(assetSummariesPages, legalEntitySummariesPages,allAssetsPages);
            }
            if (legalEntitySummariesPages.length > 0 && nonClientMemberSummariesPages.length > 0 && hasSufficientSpace(remainingSpaceAfterLegalEntities)) {
                mergeLegalEntitiesAndNonClientPages(legalEntitySummariesPages, nonClientMemberSummariesPages, allAssetsPages , assetSummariesPages.length);
            }

            if (CurrentNetWorthReportPages.length === 0) {
                setCurrentNetWorthReportPages(allAssetsPages);
                expandNonClientAccordions(nonClientNames);
            }
        }
    },[])

    return <>
        {CurrentNetWorthReportPages.map((clientAssetsPage, pageIndex) =>
            <PrintViewWrapper pageNumber={pageIndex} displayName={displayName} key={pageIndex}>
                <article className="asset-summary-page layout-split-left">
                    <BarChartSidebar
                        data={createAssetBarChartData(inEstateTotals, null)}
                        noDataText='No assets have been captured yet.'
                        displayName={displayName}
                        title={`Current Net Worth${pageIndex > 0 ? ' (continued)' : ''}`}>
                        <AssetBarChartFooter
                            clientAssets={clientAssets}
                            inEstateAssetsTotals={inEstateTotals}
                            activeFormAsset={null}
                            investorGroup={investorGroup!}
                            title={'Current Net Worth'}
                        />
                    </BarChartSidebar>
                    <section>
                        <div data-testid="current-networth-content">

                            {clientAssetsPage && <div className="asset-summary-content">
                                {clientAssetsPage.assetsSummary && (hasAssets(clientAssetsPage.assetsSummary) || assetListData(clientAssetsPage.assetsSummary!).hasInEstatePersonalLiability) &&
                                    <>
                                        <div className="section-header">
                                            <h3>
                                                {pageIndex === 0 && <div>Your current net worth
                                                    is <b>{formatCurrency(calculateCNWInEstateTotalValue(clientAssets, showDeathBenefit))}</b>:
                                                </div>
                                                }
                                                {!hasInEstateAssets(clientAssetsPage.assetsSummary) &&
                                                    <div className="condensed-subtitle">Begin by entering assets or
                                                        entities</div>}
                                            </h3>
                                        </div>
                                        <CurrentNetWorthAssetListReport
                                            profileId={profileId}
                                            isDetailed={isDetailed}
                                            assetsDataForPage={clientAssetsPage.assetsSummary}
                                            investorGroup={investorGroup!}
                                            showDeathBenefit={showDeathBenefit}
                                            allAssets={clientAssets}
                                        />
                                    </>
                                }
                                {
                                    hasOutOfEstateLegalEntities(clientAssetsPage) &&
                                    <>
                                        {
                                            pageIndex === firstLegalEntityPageIndex &&
                                            <div className="section-header">
                                                <h3>
                                                    Out of estate assets
                                                    total <b>{formatCurrency(calculateOutOfEstateTotalValue(clientAssets)).trim()}</b>:
                                                </h3>
                                            </div>
                                        }

                                        <OutOfEstateLegalEntityReportView
                                            allItems={allItems}
                                            legalEntitiesSummariesForPage={clientAssetsPage.legalEntitySummary!}
                                            allLegalEntityTypeSummaries={legalEntityTypeSummaries}
                                            accordionIdForOutOfEstate={isDetailed ? "CurrentNetWorthOutOfEstateAccordionReport" : "CurrentNetWorthOutOfEstateCollapsedReport"}

                                        />
                                    </>
                                }
                                {
                                    hasOutOfEstateNonClientSummaries(clientAssetsPage) &&
                                    <OutOfEstateNonClientSummariesView
                                        allItems={allItems}
                                        nonClientMemberSummariesForPage={clientAssetsPage.nonClientMemberSummary!}
                                        allNonClientMemberSummaries={nonClientMemberSummaries}
                                        accordionIdForView={isDetailed ? "CurrentNetWorthOutOfEstateNonClientAccordionReport" : "CurrentNetWorthOutOfEstateNonClientCollapsedReport"}
                                    />
                                }
                            </div>}
                        </div>
                    </section>
                </article>
            </PrintViewWrapper>
        )}
    </>

}