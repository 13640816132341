import {
    LifestyleSpendingGoal,
    LifestyleSpendingGoalResponse,
    LifestyleSpendingRequest
} from "./models/LifestyleSpendingGoal";
import {
    LifestyleAndNonLifestylePresentValueRequest,
    LifestyleAndNonLifestylePresentValueResponse,
    LifestylePresentValueResponse
} from "./models/PresentValue";
import {PortfolioReserveResponse} from "./models/PortfolioReserve";
import {get, post, put, remove} from "../core/api";
import {FamilyGoalRequest, FamilyGoalType} from "./models/FamilyGoalType";
import {AllGoalsType} from "./models/GoalModelType";
import {NonLifeStyleGoalRequest, NonLifestyleGoalType, UpdateNonLifestyleGoalType} from "./models/GoalType";
import {MonteCarloResponse} from "./models/MonteCarlo";

const extractBody = async (res: Response) => {
    return await res.json();
};

const postLifeStyleGoal = (profileId: string, lifestyleSpendingRequest: LifestyleSpendingRequest): Promise<LifestyleSpendingGoalResponse> => {
    return post(`/goal/profiles/${profileId}/lifestyleGoal`, lifestyleSpendingRequest)
        .then(extractBody);
}

const postLifestylePresentValueWithMultipleLSPeriods = async (profileId: string, userInputs: LifestyleSpendingGoal["userInputs"], abortSignal?: AbortSignal): Promise<LifestylePresentValueResponse> => {
    return post(`/wealth/lifestylePresentValue/${profileId}`, userInputs, { signal: abortSignal})
        .then(extractBody)
}

const getPortfolioReserve = async (profileId: string, selectedSupportLengths?: {selectedReserveTarget: string, selectedMinimumRisk?: string, selectedFBNI?: string}, abortSignal?: AbortSignal): Promise<PortfolioReserveResponse> => {
    return get(`/wealth/portfolioReserve/${profileId}`, {queryParams: selectedSupportLengths, signal: abortSignal})
        .then(extractBody);
}

const getMonteCarlo = async (profileId: string, selectedSupportLengths?: {selectedReserveTarget: string, selectedMinimumRisk?: string, selectedFBNI?: string}, abortSignal?: AbortSignal): Promise<MonteCarloResponse> => {
    return get(`/wealth/monteCarlo/${profileId}`, {queryParams: selectedSupportLengths, signal: abortSignal})
        .then(extractBody);
}

const getProposedPortfolioSupports = async (profileId: string, proposedAllocation: { proposedAllocation: string }): Promise<number> => {
    return get(`/wealth/proposedPortfolioSupports/${profileId}`, {queryParams: proposedAllocation})
        .then(extractBody);
}

const postFamilyGoal = (profileId: string, familyGoal: FamilyGoalRequest): Promise<FamilyGoalType> => {
    return post(`/goal/profiles/${profileId}/familyGoal`, familyGoal)
        .then(extractBody);
}

const getAllGoals = (profileId: string): Promise<AllGoalsType> => {
    return get(`/goal/profiles/${profileId}/goals`)
        .then(extractBody);
}

const deleteFamilyGoal = async (goalId : string): Promise<Response> => {
    return remove(`/goal/familyGoals/${goalId}`);
}

const postNonLifestyleGoal = (profileId: string, nonLifestyleGoal: NonLifeStyleGoalRequest): Promise<NonLifestyleGoalType> => {
    return post(`/goal/profiles/${profileId}/nonLifestyleGoal`, nonLifestyleGoal)
        .then(extractBody);
}

const deleteNonLifestyleGoal = async (goalId : string): Promise<Response> => {
    return remove(`/goal/nonLifestyleGoal/${goalId}`);
}

const postLifestyleAndNonLSPresentValue = async(profileId: string, request: LifestyleAndNonLifestylePresentValueRequest,abortSignal?: AbortSignal): Promise<LifestyleAndNonLifestylePresentValueResponse> => {
    return post(`/wealth/nonLifestylePresentValue/${profileId}`, request, { signal: abortSignal})
        .then(extractBody)
}


const updateNonLifestyleGoalForPrioritizationType = async (profileId : string, request: UpdateNonLifestyleGoalType): Promise<Response> => {
    return put(`/goal/profiles/${profileId}//nonLifestyleGoal/prioritizationType`, request);
}
export const goalsApiClient = {
    postLifeStyleGoal,
    postFamilyGoal,
    deleteFamilyGoal,
    getPortfolioReserve,
    getMonteCarlo,
    getProposedPortfolioSupports,
    postNonLifestyleGoal,
    deleteNonLifestyleGoal,
    getAllGoals,
    postLifestylePresentValueWithMultipleLSPeriods,
    postLifestyleAndNonLSPresentValue,
    updateNonLifestyleGoalForPrioritizationType
}
