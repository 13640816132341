import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/main.scss';
import {MsalProvider} from "@azure/msal-react";
import {initializeMsalInstance} from "./MsalUtils";
import {createStore} from "./store/store";
import {Provider} from "react-redux";
import {fetchSpaConfigAndUpdateSessionStorage, SessionStorageKey, sessionStorageService} from './core/storageHelper';
import LoadingIndicator from './pages/LoadingIndicator';
import {PublicClientApplication} from '@azure/msal-browser';
import {initializeAppInsights} from './AppInsights';


if (process.env.NODE_ENV !== 'production') {
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 1000);
}

ReactDOM.render(
    <React.StrictMode>
        <AppWrapper />
    </React.StrictMode>, document.getElementById('root'));

function AppWrapper() {
    const [spaConfig, setSpaConfig] = useState(sessionStorageService.get(SessionStorageKey.SpaConfig));
    const [msalInstance, setMsalInstance] = useState<PublicClientApplication>();

    useEffect(() => {
        if(!spaConfig) {
            fetchSpaConfigAndUpdateSessionStorage().then((spaConfigResponse) => setSpaConfig(spaConfigResponse));
        }
    }, []);

    useEffect(() => {
        if(spaConfig) {
            setMsalInstance(initializeMsalInstance());
            initializeAppInsights();
        }
    }, [spaConfig]);

    if(!spaConfig || !msalInstance) {
        return <LoadingIndicator />
    }

    return <Provider store={createStore()}>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </Provider>;
}
