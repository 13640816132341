import classNames from 'classnames';
import {ReactComponentLike} from 'prop-types';
import React from 'react';
import {Logo} from 'xps-react';
import {isEmpty} from '../../utils/stringUtils';
import {Icon} from "../../components";
import {NO_OP} from '../../constants/common';
import {MeetingControls} from '../../ClientManagement/Meeting/MeetingControls';
import {Link, NavDrawer} from '../NavDrawer';
import {HistoryProps, LinkProps, LocationProps} from '../../models/routeData/RouteParamTypes';

type AppHeaderProps = {
    appTitle?: string,
    className?: string,
    HeaderToolbar?: React.FC,
    headerToolbarAlignment?: "left" | "right" | "center",
    history?: HistoryProps,
    onLogoClick: () => void,
    LinkRenderer?: ReactComponentLike,
    links?: LinkProps[],
    NavDrawerToolbar?: ReactComponentLike,
    navigationStyle?: "header" | "drawer",
    secondaryLinks?: LinkProps[],
    theme?: "light" | "none",
    showMeetingControl?: boolean,
    children?: React.ReactNode
}

const AppHeader = ({
                       appTitle,
                       className,
                       HeaderToolbar,
                       headerToolbarAlignment = 'right',
                       history,
                       links = [],
                       LinkRenderer = 'a',
                       NavDrawerToolbar,
                       navigationStyle = 'header',
                       onLogoClick = NO_OP,
                       secondaryLinks = [],
                       theme = 'light',
                       showMeetingControl = false,
                       children,
                   }: AppHeaderProps) => (
    <>
        <header
            className={classNames(
                'app-header',
                {[`app-header--theme-${theme}`]: Boolean(theme)},
                {
                    'app-header--left-aligned': headerToolbarAlignment === 'left',
                    'app-header--right-aligned': headerToolbarAlignment === 'right',
                },
                className
            )}
        >
            <div className="app-header__title">
                <span className="app-header__title__logo">
                  <Logo
                      alt="Single Line Anchor Logo"
                      ariaLabel="Northern Trust Logo"
                      color="black"
                      logoType="single"
                      onClick={onLogoClick}
                      width="200px"
                  />
                </span>
                {!isEmpty(appTitle) && <span className="app-header__title__separator"/>}
                <span className="app-header__title__text">{appTitle}</span>
            </div>
            {HeaderToolbar && (
                    <div className="app-header__toolbar-wrapper">
                        <div className="app-header__toolbar">
                            <HeaderToolbar/>
                        </div>
                    </div>
            )}
            {navigationStyle === 'header' && (
                <div className="app-header__navbar" role="menu">
                    {links.map(link => {
                        const customHandler = link.customHandler
                        const hasChildren = Boolean(link.children);
                        const Renderer = hasChildren ? 'span' : LinkRenderer;
                        return (customHandler ?
                                <a
                                    className="app-header__navitem nav__item nav__item-skin"
                                    key={link.id}
                                    title={'log out'}
                                    onClick={customHandler}
                                >
                                    {link.name}
                                </a>
                                : <Link
                                    className="app-header__navitem nav__item nav__item-skin"
                                    key={link.id}
                                    link={link}
                                    location={history?.location}
                                    Renderer={Renderer}

                                >
                                    {link.children && (
                                        <>
                                            <Icon className="app-header__subnav-icon marginleft-xs"
                                                  name="menu_down"/>
                                            <div className="app-header__subnav subnav subnav-skin">
                                                <ul className="list paddingleft-none" role="menu">
                                                    {link.children.map(child => {
                                                        let key = child.id || ((child.path as LocationProps).pathname
                                                            ? (child.path as LocationProps).pathname
                                                            : child.path as string);
                                                        return (
                                                            <li key={key}
                                                                role="menuitem">
                                                                <Link
                                                                    className="subnav__item subnav__item-skin"
                                                                    link={child}
                                                                    location={history?.location}
                                                                    Renderer={LinkRenderer}
                                                                />
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </>
                                    )}
                                </Link>
                        );
                    })}
                </div>
            )}
        </header>
        {navigationStyle === 'drawer' && (children ?
                <div className="app-header__custom-content">
                    {children}
                </div>
                :
                <NavDrawer
                    history={history}
                    isOpen={true}
                    LinkRenderer={LinkRenderer}
                    primaryLinks={links}
                    secondaryLinks={secondaryLinks}
                    Toolbar={NavDrawerToolbar}
                    sideMenuRequired={true}
                />
        )}
        {showMeetingControl && <MeetingControls/>}
    </>
);

export default AppHeader;
