import CustomModal from "../../components/Modal/Custom/CustomModal";
import {formatCurrency} from "../../utils/format";
import React from "react";
import {InvestmentProgram} from "../models/InvestmentProgram";

interface DeleteModalForInvestmentProgramProps {
    isOpen: boolean,
    investmentProgram: InvestmentProgram | null,
    onConfirm: () => void,
    onCancel: () => void,
}

const DeleteInvestmentProgramConfirmationModal = ({isOpen,investmentProgram,onConfirm,onCancel}: DeleteModalForInvestmentProgramProps) => {

    return <CustomModal
        isOpen={isOpen}
        title={
            <h1 className="h3 display-inlineblock">
                {`Remove ${investmentProgram?.name} Investment Program from this profile?`}
            </h1>
        }
        size='large'
        content={
        <div className='investment-programs font-default'>
            {investmentProgram && investmentProgram.legalAgreements.length > 0 &&
                <div>
                    <div className="add-investment-program-dialog-grid">
                        <span className="fontweight-500">Investment Program Legal Agreements</span>
                        <span className="textalign-right fontweight-500">Legal Agreement #</span>
                        <span className="textalign-right fontweight-500">Market Value</span>
                    </div>
                    {investmentProgram.legalAgreements.map(legalAgreement => (
                        <div className="add-investment-program-dialog-grid"
                             key={legalAgreement.legalAgreementNumber}>
                            <span>{legalAgreement.name}</span>
                            <span
                                className="textalign-right">{legalAgreement.legalAgreementNumber}</span>
                            <span
                                className="textalign-right">{formatCurrency(legalAgreement.marketValue)}</span>
                        </div>
                    ))}
                </div>
            }
        </div>}
        onClickConfirm={onConfirm}
        onClickCancel={onCancel}
        confirmText='Remove Investment Program'
        cancelText='Cancel'
        confirmButtonDestructive
    />;
}

export default DeleteInvestmentProgramConfirmationModal;