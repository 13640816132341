import React, {useContext, useEffect, useState} from 'react';
import {Table} from "xps-react";
import {Condition, ConditionResultResponse} from "../../../Monitoring/ConditionResultResponse";
import {monitoringApiClient} from "../../../Monitoring/MonitoringApiClient";
import {wealthManagementApiClient} from "../../WealthManagementApiClient";
import {
    PROFILE_UPDATES_DEFAULT_ROWS,
    PROFILE_UPDATES_MIN_ROWS,
    PROFILE_UPDATES_PAGE_SIZES
} from "./profileDetailsConstants";
import {Badge} from "../../../components";
import {AssetRelianceResponse, EMPTY_ASSET_RELIANCE_RESPONSE} from "../../models/AssetRelianceResponse";
import {
    CurrentAllocationResponse,
    EMPTY_CURRENT_ALLOCATION_RESPONSE
} from "../../AssetAllocation/CurrentAllocationResponse";
import {
    EMPTY_PROPOSED_ALLOCATION_RESPONSE,
    ProposedAllocationResponse
} from "../../AssetAllocation/ProposedAllocationResponse";
import moment from "moment";
import {Link} from "react-router-dom";
import AssetsViewContext from "../../../Assets/common/AssetsViewContext";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../../../components/Modal/Error/GenericErrorModal";
import {formatCurrency} from "../../../utils/format";

type ProfileUpdatesProps = {
    profileId: string
}

export const ProfileUpdates: React.FC<ProfileUpdatesProps> = ({profileId}) => {

    const [conditionResultResponse, updateConditionResultResponse] = useState([] as ConditionResultResponse[]);
    const [assetReliance, setAssetReliance] = useState<AssetRelianceResponse>(EMPTY_ASSET_RELIANCE_RESPONSE);
    const [currentAllocation, setCurrentAllocation] = useState<CurrentAllocationResponse>(EMPTY_CURRENT_ALLOCATION_RESPONSE);
    const [proposedAllocation, setProposedAllocation] = useState<ProposedAllocationResponse>(EMPTY_PROPOSED_ALLOCATION_RESPONSE);
    const [genericError, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const handleErrorCloseButton = () => {
        setGenericError({...genericError, isOpen: false});
    }

    useEffect(() => {
        if (profileId != undefined && profileId != "") {
            setIsLoading(true);
            monitoringApiClient.getConditionsForProfile(profileId)
                .then((conditionResult) => {
                    return updateConditionResultResponse(conditionResult);
                }).catch((error) => {
                // Turning off until REV endpoint is live, please do not remove
                // setGenericError({
                //     isOpen: true,
                //     header: "Communication Failure",
                //     message: "There has been a communication failure.  The data you see may not be accurate, please refresh your browser.  If this error continues, please contact the support team.",
                //     operationId: error.headers.get('trace-id')
                // });
                console.error('Failed to retrieve conditions', error.message);
            })
            Promise.all([
                wealthManagementApiClient.getAssetReliance(profileId),
                wealthManagementApiClient.getProposedAllocation(profileId),
                wealthManagementApiClient.getCurrentAllocation(profileId)
            ]).then(([assetRelianceResponse, proposedAllocationResponse, currentAllocationResponse]) => {
                setAssetReliance(assetRelianceResponse);
                setProposedAllocation(proposedAllocationResponse);
                setCurrentAllocation(currentAllocationResponse);
                setIsLoading(false)
            }).catch((error) => {
                console.error('Failed to retrieve excess assets and allocation data', error.message);
            })
        }
    }, [profileId]);

    return (
        <div className="rt-resizable-header-content profile-updates">

                <span className="h4">
                    Profile Updates
                    <hr/>
                </span>
            {
                genericError.isOpen ?
                    <GenericErrorModal
                        errorModalData={genericError}
                        onClickButton={handleErrorCloseButton}
                        buttonText={'Close'}
                    /> : <React.Fragment/>
            }

            {conditionResultResponse && conditionResultResponse.map((conditionalResult,
                                                                     index) => (
                <div className="conditions">
                    <ConditionList conditions={conditionalResult.conditions}
                                   profileId={profileId}
                                   key={index}
                                   assetRelianceResponse={assetReliance}
                                   currentAllocation={currentAllocation}
                                   proposedAllocation={proposedAllocation}
                                   isLoading={isLoading}
                    />
                </div>
            ))}
        </div>
    );
}

type ConditionListProps = {
    profileId: string,
    conditions: Condition[],
    assetRelianceResponse: AssetRelianceResponse,
    currentAllocation: CurrentAllocationResponse,
    proposedAllocation: ProposedAllocationResponse,
    isLoading: boolean,
}

const ConditionList: React.FC<ConditionListProps> = ({
                                                         profileId,
                                                         conditions,
                                                         assetRelianceResponse,
                                                         currentAllocation,
                                                         proposedAllocation,
                                                         isLoading
                                                     }: ConditionListProps) => {
    const viewType = useContext(AssetsViewContext);

    function switchCondition(conditonName: string, assetName?: string, asOfDate?: string, activeDays?: number, referenceId?: string) {
        switch (conditonName) {
            case 'Risk Control Assets Underfunded' :
                const proposedMinusCurrentRiskControlAllocation = proposedAllocation.totalRiskControlAssetsPercent - currentAllocation.totalRiskControlAssetsPercent;
                return (<>
                    <Link to={`/Profile/${profileId}/ClientProfile/AssetAllocation/CurrentVsProposed`}>Risk Control
                        Assets Underfunded </Link>
                    <span>
                        by {parseFloat(proposedMinusCurrentRiskControlAllocation.toFixed(2))}% ({'>'}5%). The current allocation is {parseFloat((currentAllocation.totalRiskControlAssetsPercent).toFixed(2))}% and the proposed is {parseFloat((proposedAllocation.totalRiskControlAssetsPercent).toFixed(2))}%. ({activeDays} days outstanding)
                    </span>
                </>);
            case 'Standalone Update Overdue' :
                return (<>
                    <span>Standalone Update Overdue. </span>
                    <Link
                        to={`/Profile/${profileId}/ClientProfile/${viewType}/EditStandaloneAccount/${referenceId}`}>{assetName} </Link>
                    <span>
                        was last updated {moment().diff(moment(asOfDate), 'days')} days ago ({'>'}365 days). ({activeDays} days overdue)
                    </span>
                </>);
            case 'Standalone Update Non-Investable Overdue' :
                return (<>
                    <span>Standalone Update Overdue. </span>
                    <Link
                        to={`/Profile/${profileId}/ClientProfile/${viewType}/EditStandaloneAccount/${referenceId}`}>{assetName} </Link>
                    <span>
                         was last updated {moment().diff(moment(asOfDate), 'days')} days ago ({'>'}730 days). ({activeDays} days overdue)
                    </span>
                </>);
            case 'Risk Assets Underfunded' :
                const proposedMinusCurrentRiskAssetAllocation = proposedAllocation.totalRiskAssetsPercent - currentAllocation.totalRiskAssetsPercent;
                return (<>
                    <Link to={`/Profile/${profileId}/ClientProfile/AssetAllocation/CurrentVsProposed`}>Risk Assets
                        Underfunded </Link>
                    <span>
                        by {parseFloat(proposedMinusCurrentRiskAssetAllocation.toFixed(2))}% ({'>'}10%). The current allocation is {parseFloat((currentAllocation.totalRiskAssetsPercent).toFixed(2))}% and the proposed is {parseFloat((proposedAllocation.totalRiskAssetsPercent).toFixed(2))}%. ({activeDays} days outstanding)
                    </span>
                </>);
            case 'Investable Column Insufficient' :
                return (<>
                    <span>There is </span>
                    <Link to={`/Profile/${profileId}/ClientProfile/AssetReliance`}>Investable Column
                        Insufficient </Link>
                    <span>
                        of {assetRelianceResponse.investablePortfolioAssetsStack?.excessAssets ? formatCurrency(assetRelianceResponse.investablePortfolioAssetsStack?.excessAssets) : '$0'} ({'<'}0) ({activeDays} days outstanding)
                    </span>
                </>);
            case 'All Column Insufficient' :
                return (<>
                    <Link to={`/Profile/${profileId}/ClientProfile/AssetReliance`}>The All Column is
                        Insufficient </Link>
                    <span>
                        by {formatCurrency(assetRelianceResponse.allAssetsStack.excessAssets)} ({'<'}0) ({activeDays} days outstanding)
                    </span>
                </>);
            case 'Annual Report Sent' :
                return (<>
                    <span>GDWM Annual Report {asOfDate ? 'last sent' : 'not sent'} {asOfDate ? asOfDate : ''} ({'>'}365 days)</span>
                </>);
            case 'Equity Comp. Update Overdue' :
                return (<>
                    <span>Equity Comp. Update Overdue. </span>
                    <Link
                        to={`/Profile/${profileId}/ClientProfile/AssetSummary/EditEquityCompensation/${referenceId}`}>{assetName} </Link>
                    <span>was last updated {moment().diff(moment(asOfDate), 'days')} days ago ({'>'}365 days). ({activeDays} days overdue)</span>
                </>);
            default:
                return conditonName;
        }
    }

    return (
        <div className="table-spacing">
            {!isLoading && <Table
                className="conditons-table"
                id="ConditionsTable"
                caption="Profile Conditions"
                data={conditions}
                minRows={PROFILE_UPDATES_MIN_ROWS}
                filterable={false}
                pageSizeDropUp={true}
                pageSizeOptions={PROFILE_UPDATES_PAGE_SIZES}
                rowHeight="medium"
                showPagination={false}
                sortable={false}
                defaultPageSize={PROFILE_UPDATES_DEFAULT_ROWS}
                columns={[
                    {
                        Header: () => (
                            <span>
                                <Badge value={conditions.length}
                                       type={"error"}
                                       className="badge-condition-length"/>
                                &nbsp; Profile Conditions
                            </span>
                        ),
                        accessor: "conditionName",
                        Cell: ({original}: { original: Condition }) => (
                            <span className="condition-detail">
                                {switchCondition(original.conditionName, original.profileData, original.asOfDate, original.activeDays, original.referenceId)}
                           </span>
                        ),
                        minWidth: 448
                    }]}
            />}
        </div>
    );
};