import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {wealthManagementApiClient} from "../../ClientManagement/WealthManagementApiClient";
import {RouteWithId} from "../../routes/types";
import {
    CurrentAllocationResponse,
    EMPTY_CURRENT_ALLOCATION_RESPONSE
} from "../../ClientManagement/AssetAllocation/CurrentAllocationResponse";
import {
    EMPTY_PROPOSED_ALLOCATION_RESPONSE,
    ProposedAllocationResponse
} from "../../ClientManagement/AssetAllocation/ProposedAllocationResponse";
import {ReportConfig} from "../models/Reporting";
import {ProfileResponse} from "../../ClientManagement/models/ProfileResponse";
import LoadingIndicator from "../../pages/LoadingIndicator";
import MonitorPortfolioReserveWrapper from "../MonitorPortfolioReserveWrapper";
import PrintViewWrapper from "../DetailedEmailReports/PrintViewWrapper";
import CurrentVsProposedDetailsReport from "../DetailedEmailReports/CurrentVsProposed/CurrentVsProposedDetailsReport";
import {
    EMPTY_REVISE_ASSET_ALLOCATION_RESPONSE,
    ReviseAssetAllocationResponse
} from "../../ClientManagement/AssetAllocation/ReviseAssetAllocation";
import {AssetClassifications} from "../../Assets/models/AssetClassifications";
import {assetsApiClient} from "../../Assets/AssetsApiClient";

type ReserveAndInvestmentPlanProps = {
    reportConfig: ReportConfig,
    approvedProfile: ProfileResponse
}

const ReserveAndInvestmentPlan = ({
                                      reportConfig,
                                      approvedProfile
                                  }: ReserveAndInvestmentPlanProps) => {
    const {id} = useParams<RouteWithId>();

    const [isLoading, setIsLoading] = useState(true);
    const [currentAllocation, setCurrentAllocation] = useState<ReviseAssetAllocationResponse>(EMPTY_REVISE_ASSET_ALLOCATION_RESPONSE);
    const [proposedAllocation, setProposedAllocation] = useState<ReviseAssetAllocationResponse>(EMPTY_REVISE_ASSET_ALLOCATION_RESPONSE);
    const [classifications, setClassifications] = useState<AssetClassifications>({
        riskAssetClasses: [],
        riskControlAssetClasses: [],
    });

    useEffect(() => {
        if (id) {
            setIsLoading(true)
            Promise.all([
                wealthManagementApiClient.getReviseAssetAllocation(id),
                    assetsApiClient.getAssetClassifications()]
            ).then(([allocationResponse, assetClassifications]) => {
                setCurrentAllocation(allocationResponse);
                setProposedAllocation(allocationResponse);
                setClassifications(assetClassifications)
                setIsLoading(false)
            }).catch(e => {
                setIsLoading(false);
            });

        }
    }, [id]);

    if (isLoading) {
        return <LoadingIndicator/>;
    }

    return <>
        {reportConfig.monitorPortfolioReserve.isEnabled &&
            <PrintViewWrapper displayName={approvedProfile.displayName}><MonitorPortfolioReserveWrapper
                approvedProfile={approvedProfile}
                currentAllocation={currentAllocation}
                proposedAllocation={proposedAllocation}/></PrintViewWrapper>}
        {reportConfig.currentVsProposed.isEnabled &&
            <CurrentVsProposedDetailsReport displayName={approvedProfile.displayName}
                                            currentAllocation={currentAllocation}
                                            proposedAllocation={proposedAllocation}
                                            isDetailedReport={false}
                                            classifications={classifications}/>
        }
        {reportConfig.currentVsProposedDetailed.isEnabled &&
            <CurrentVsProposedDetailsReport displayName={approvedProfile.displayName}
                                            currentAllocation={currentAllocation}
                                            proposedAllocation={proposedAllocation}
                                            isDetailedReport={true}
                                            classifications={classifications}/>}
    </>
}

export default ReserveAndInvestmentPlan;