import {
    AssetRelianceDescribedValueDTO,
    AssetRelianceGoalSummaryDTO,
    AssetRelianceGoalTypeDTO,
    AssetRelianceStack,
    AssetRelianceStackAsset
} from "../../../ClientManagement/models/AssetRelianceResponse";
import {FixedColumnCounter} from "../../../components";
import {ReviewAssetTableDisplayReport} from "./ReviewAssetTableDisplayReport";
import {ExcessAssetsTableDisplayReport} from "./ExcessAssetsTableDisplayReport";
import {GoalTableDisplayReport} from "./GoalTableDisplayReport";
import {FveDiscountRateType} from "../../../ClientManagement/AssetReliance/AssetRelianceButtonState";
import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {ExcessAssetsTableDisplay} from "../../../ClientManagement/AssetReliance/TableDisplay/ExcessAssetsTableDisplay";

const TOTAL_PAGE_HEIGHT = 1055;
const PAGE_PADDING_HEIGHT = 96;
const SECTION_HEADER_HEIGHT = 405;
const ACCORDION_HEADER_HEIGHT = 72;
const TABLE_HEADER_HEIGHT = 0;
const TABLE_ROW_HEIGHT = 50;
const PAGE_SPACING_BETWEEN_TWO_PAGES = 71;

const UNDERLINE_HEADER = 0;
const PAGE_DISCLAIMER_SECTION_TITLE = 114;

export type AssetRelianceDetails = {
    assets: ReviewAssetTableDisplayReport,
    excessAssets: ExcessAssetsTableDisplayReport,
    goals: GoalTableDisplayReport,
    columnCounter: FixedColumnCounter,
}

export const splitAssetRelianceData = async (mainData: any, defaultData: any): Promise<Array<AssetRelianceDetails>> => {

    const calculatedData: Array<any> = [];
    let pageScore = PAGE_PADDING_HEIGHT + SECTION_HEADER_HEIGHT + PAGE_DISCLAIMER_SECTION_TITLE;
    let pageData: any = {...defaultData};

    if(mainData['assets'] && mainData['assets'].rowStacks_.length) {
        pageScore = pageScore + ACCORDION_HEADER_HEIGHT + TABLE_HEADER_HEIGHT + UNDERLINE_HEADER;
        const result = await setAssetsPageData('assets', pageData, pageScore, mainData['assets'], calculatedData, defaultData,true);
        pageScore = result.pageScore;
        pageData = result.pageData;

    }

    if (mainData['goals']) {
        pageScore = pageScore + ACCORDION_HEADER_HEIGHT + TABLE_HEADER_HEIGHT;
        const result = await setGoalsPageData('goals', pageData, pageScore, mainData['goals'], calculatedData, defaultData, false);
        pageScore = result.pageScore;
        pageData = result.pageData;
    }

    if (mainData['excessAssets']) {
        if (pageScore < TOTAL_PAGE_HEIGHT) {
            pageData = {
                ...pageData,
                ['excessAssets']: mainData['excessAssets']
            }
        } else {
            calculatedData.push(pageData);
            pageData = {...defaultData};
            pageData = {
                ...pageData,
                ['excessAssets']: mainData['excessAssets']
            }
        }
    }

    if (pageData !== defaultData) {
        calculatedData.push(pageData);
    }
    return calculatedData;

}

const setAssetsPageData = async (dataType: string,
                                 pageData: any,
                                 pageScore: number,
                                 data: any,
                                 calculatedData: any,
                                 defaultData: any,
                                 isFirstAssetPage: boolean) => {
    const nestedDataHeaderLength = 0;

    let updatedPageScore = 0;
    let totalLengthOfAllAssets = 0;
    totalLengthOfAllAssets = data.rowStacks_.length;
    let originalRowAssetStacksArray: AssetRelianceStack[] = data.rowStacks_;
    originalRowAssetStacksArray.forEach(asset => {
        totalLengthOfAllAssets = totalLengthOfAllAssets + asset.assets.length;
    })
    updatedPageScore = pageScore + (totalLengthOfAllAssets + nestedDataHeaderLength) * TABLE_ROW_HEIGHT;


    if (updatedPageScore < TOTAL_PAGE_HEIGHT) {
        pageData = {
            ...pageData,
            [dataType]: data
        }
    } else {
        const remainingPageSpace = TOTAL_PAGE_HEIGHT - pageScore;
        let noOfDataPageCanFit = 0;
        if (remainingPageSpace > 0) {
            noOfDataPageCanFit = Math.floor(remainingPageSpace / TABLE_ROW_HEIGHT);
            if (noOfDataPageCanFit > 0) {

                let dataThatCanFitInCurrentPage: AssetRelianceStack[] = [];
                const rowAssetStacksArray: AssetRelianceStack[] = JSON.parse(JSON.stringify(originalRowAssetStacksArray));
                let count = 0;
                rowAssetStacksArray.forEach((asset) => {

                    if (count < noOfDataPageCanFit) {
                        if (asset.assets.length > 0) {
                            count = count + 1

                            const numberOfChildAssetsThatCanAdded = noOfDataPageCanFit - count
                            let originalChildAssets: AssetRelianceStackAsset[] = JSON.parse(JSON.stringify(asset.assets));
                            let childDataThatCanFitInCurrentPageAsset: AssetRelianceStackAsset[] = [];

                            childDataThatCanFitInCurrentPageAsset = asset.assets.slice(0, numberOfChildAssetsThatCanAdded);
                            asset.assets = childDataThatCanFitInCurrentPageAsset;
                            count = count + childDataThatCanFitInCurrentPageAsset.length;
                            pushAssetOnlyWhenAssetContainsChildData(childDataThatCanFitInCurrentPageAsset,dataThatCanFitInCurrentPage,asset);
                            if (originalChildAssets.slice(childDataThatCanFitInCurrentPageAsset.length).length > 0) {
                                const copiedRowAssetStacksArray: AssetRelianceStack[] = JSON.parse(JSON.stringify(originalRowAssetStacksArray));
                                let rowAssetStack = copiedRowAssetStacksArray.find(ele => ele.stackSequenceNumber === asset.stackSequenceNumber)!
                                rowAssetStack.assets = originalChildAssets.slice(childDataThatCanFitInCurrentPageAsset.length);
                                isChildAssetContinued(rowAssetStack,numberOfChildAssetsThatCanAdded);
                                originalRowAssetStacksArray = copiedRowAssetStacksArray;
                            } else {
                                originalRowAssetStacksArray = originalRowAssetStacksArray.filter(ele => (ele.stackSequenceNumber !== asset.stackSequenceNumber))
                            }
                        } else {
                            count = count + 1;
                            dataThatCanFitInCurrentPage.push(asset)
                            originalRowAssetStacksArray = originalRowAssetStacksArray.filter(ele => (ele.stackSequenceNumber !== asset.stackSequenceNumber))
                        }
                    }
                })

                let isAssetContinued = getIsAssetContinued(isFirstAssetPage);

                const splitedAssetsData = new ReviewAssetTableDisplayReport(data.columnCounter, dataThatCanFitInCurrentPage, data.columnStacks_, data.originalRowStacks_, isAssetContinued);

                pageData = {
                    ...pageData,
                    [dataType]: splitedAssetsData
                };
            }

        }

        // Push Current Page
        calculatedData.push(pageData);

        // Creating New Page
        updatedPageScore = PAGE_PADDING_HEIGHT + SECTION_HEADER_HEIGHT + PAGE_SPACING_BETWEEN_TWO_PAGES + PAGE_DISCLAIMER_SECTION_TITLE;
        pageData = {...defaultData};

        const reMainingSplitedAssetsData = new ReviewAssetTableDisplayReport(data.columnCounter, originalRowAssetStacksArray, data.columnStacks_, data.originalRowStacks_,true);

        if (originalRowAssetStacksArray.length > 0) {
            const result = await setAssetsPageData(dataType, pageData, updatedPageScore, reMainingSplitedAssetsData, calculatedData, defaultData, false);
            updatedPageScore = result.pageScore;
            pageData = result.pageData;
        }
    }
    return {pageScore: updatedPageScore, pageData};
}

const getIsAssetContinued = (isFirstAssetPage: boolean) => {
    if (isFirstAssetPage === true) {
        return false
    } else {
        return true
    }
}

const isChildAssetContinued = (rowAssetStack: AssetRelianceStack, numberOfChildAssetsThatCanAdded: number) => {
    if ((!rowAssetStack.description.includes(" (continued)")) && (numberOfChildAssetsThatCanAdded>0)) {
        rowAssetStack.description = rowAssetStack.description + " (continued)"
    }
}


const pushAssetOnlyWhenAssetContainsChildData = (childDataThatCanFitInCurrentPageAsset: AssetRelianceStackAsset[],dataThatCanFitInCurrentPage: AssetRelianceStack[], asset : AssetRelianceStack ) => {
    if (childDataThatCanFitInCurrentPageAsset.length > 0) {
        dataThatCanFitInCurrentPage.push(asset);
    }
}


const setGoalsPageData = async (dataType: string,
                                pageData: any,
                                pageScore: number,
                                data: any,
                                calculatedData: any,
                                defaultData: any,
                                isGoalsContinued: boolean) => {
    const nestedDataHeaderLength = 0;

    let updatedPageScore = 0;

    let totalLengthOfAllGoals = 0;
    totalLengthOfAllGoals = data.goalsSummary_.goalTypes.length;
    let originalGoalTypes: AssetRelianceGoalTypeDTO[] = data.goalsSummary_.goalTypes;
    originalGoalTypes.forEach(goal => {
        totalLengthOfAllGoals = totalLengthOfAllGoals + goal.data.length;
    })
    updatedPageScore = pageScore + (totalLengthOfAllGoals + nestedDataHeaderLength) * TABLE_ROW_HEIGHT;

    if (updatedPageScore < TOTAL_PAGE_HEIGHT) {
        pageData = {
            ...pageData,
            [dataType]: data
        }
    } else {
        const remainingPageSpace = TOTAL_PAGE_HEIGHT - pageScore;
        let noOfDataPageCanFit = 0;
        if (remainingPageSpace > 0) {
            noOfDataPageCanFit = Math.floor(remainingPageSpace / TABLE_ROW_HEIGHT);
            if (noOfDataPageCanFit > 0) {
                const copiedGoalTypesArray: AssetRelianceGoalTypeDTO[] = JSON.parse(JSON.stringify(originalGoalTypes));
                const goalTypes: AssetRelianceGoalTypeDTO[] = [];
                let count = 0;

                copiedGoalTypesArray.forEach(goal => {

                    if(count < noOfDataPageCanFit){
                        if (goal.data.length > 0) {
                            const numberOfChildGoalsThatCanAdded = noOfDataPageCanFit - count
                            let originalChildGoals: AssetRelianceDescribedValueDTO[] = JSON.parse(JSON.stringify(goal.data));

                            const childDataThatCanFitInCurrentPageGoals : AssetRelianceDescribedValueDTO[] = goal.data.slice(0, numberOfChildGoalsThatCanAdded);
                            goal.data = childDataThatCanFitInCurrentPageGoals;
                            count = count + childDataThatCanFitInCurrentPageGoals.length;
                            pushGoalOnlyWhenGoalsContainsChildData(childDataThatCanFitInCurrentPageGoals,goalTypes,goal);

                            if (originalChildGoals.slice(childDataThatCanFitInCurrentPageGoals.length).length > 0) {
                                const copiedGoalTypes: AssetRelianceGoalTypeDTO[] = JSON.parse(JSON.stringify(originalGoalTypes));
                                let goalType = copiedGoalTypes.find(ele => ele.type === goal.type)!
                                goalType.data = originalChildGoals.slice(childDataThatCanFitInCurrentPageGoals.length);
                                isChildGoalContinued(goalType,numberOfChildGoalsThatCanAdded);
                                originalGoalTypes = copiedGoalTypes;
                            } else {
                                originalGoalTypes = originalGoalTypes.filter(ele => (ele.type !== goal.type))
                            }

                        } else {
                            count = count + 1;
                            goalTypes.push(goal);
                            originalGoalTypes = originalGoalTypes.filter(ele => (ele.type !== goal.type))
                        }
                    }

                });

                const copiedGoalsData = new GoalTableDisplayReport(data.columnCounter, {
                    totalPresentValue: data.goalsSummary_.presentValue,
                    goalTypes: goalTypes
                }, data.stacks_,isGoalsContinued,data.goalsSummary_)

                pageData = {
                    ...pageData,
                    [dataType]: copiedGoalsData
                };
            }
        }


        // Push Current Page
        calculatedData.push(pageData);

        // Creating New Page
        updatedPageScore = PAGE_PADDING_HEIGHT + SECTION_HEADER_HEIGHT + PAGE_SPACING_BETWEEN_TWO_PAGES + PAGE_DISCLAIMER_SECTION_TITLE;
        pageData = {...defaultData};


        if (originalGoalTypes.length > 0) {

            isGoalsContinued = getIsGoalsContinued(originalGoalTypes,data);

            const reMainingSplitedGoalsData = new GoalTableDisplayReport(data.columnCounter, {
                totalPresentValue: data.goalsSummary_.presentValue,
                goalTypes: originalGoalTypes
            }, data.stacks_,isGoalsContinued,data.goalsSummary_)

            const result = await setGoalsPageData(dataType, pageData, updatedPageScore, reMainingSplitedGoalsData, calculatedData, defaultData, isGoalsContinued);
            updatedPageScore = result.pageScore;
            pageData = result.pageData;
        }
    }

    return {pageScore: updatedPageScore, pageData};
}


const getIsGoalsContinued = (originalGoalTypes: AssetRelianceGoalTypeDTO[], data: any ) => {
    let isGoalsContinued = true
    if(originalGoalTypes.length === data.goalsSummary_.goalTypes.length){ // condition for first page if remainingPageSpace is < 0
        isGoalsContinued = false;
    }
    return isGoalsContinued;
}

const pushGoalOnlyWhenGoalsContainsChildData = (childDataThatCanFitInCurrentPageGoals: AssetRelianceDescribedValueDTO[],goalTypes: AssetRelianceGoalTypeDTO[], goalType : AssetRelianceGoalTypeDTO ) => {
    if (childDataThatCanFitInCurrentPageGoals.length > 0) {
        goalTypes.push(goalType);
    }
}

const isChildGoalContinued = (goalType : AssetRelianceGoalTypeDTO, numberOfChildGoalsThatCanAdded: number) => {
    if ((!goalType.description.includes(" (continued)")) && (numberOfChildGoalsThatCanAdded >0)) {
        goalType.description = goalType.description + " (continued)"
    }
}


export const createTableDisplayObjects = (
    {
        goalsDTO,
        allAssetsStack,
        assetStacks,
        excludedAssetStacks,
        showExpectedExcessAssets,
        includeLifeInsuranceAtDeath,
        futureValueOfExcessAssetsDiscountType,
        investorGroup,
        enableOtherExcludeAssetsOnAssetReliance
    }: {
        goalsDTO: AssetRelianceGoalSummaryDTO,
        allAssetsStack: AssetRelianceStack,
        assetStacks: AssetRelianceStack[],
        excludedAssetStacks: AssetRelianceStack[],
        showExpectedExcessAssets: boolean,
        includeLifeInsuranceAtDeath: boolean,
        futureValueOfExcessAssetsDiscountType: FveDiscountRateType,
        investorGroup: InvestorGroupType,
        enableOtherExcludeAssetsOnAssetReliance: boolean
    }
) => {
    const stacksToGenerateAssetRows = [allAssetsStack, ...assetStacks];
    const stacksToGenerateAssetColumns = [allAssetsStack, ...excludedAssetStacks];
    const assetColumnCounter = new FixedColumnCounter(stacksToGenerateAssetColumns.length);
    const assets = new ReviewAssetTableDisplayReport(assetColumnCounter, stacksToGenerateAssetRows, stacksToGenerateAssetColumns,stacksToGenerateAssetRows,false);
    const goals = new GoalTableDisplayReport(assetColumnCounter, goalsDTO, stacksToGenerateAssetColumns, false,goalsDTO);
    const excludedExcessAssetStacks:AssetRelianceStack[] = enableOtherExcludeAssetsOnAssetReliance ? excludedAssetStacks : [];

    const excessAssets = new ExcessAssetsTableDisplayReport({
        columnCounter: assetColumnCounter,
        assetTableDisplay: assets,
        goalTableDisplay: goals,
        allAssetsStack,
        showExpectedExcessAssets,
        includeLifeInsuranceAtDeath,
        fveDiscountRateType: futureValueOfExcessAssetsDiscountType,
        investorGroup,
        excludedExcessAssetStacks,
    });

    return {
        assets,
        excessAssets,
        goals,
        columnCounter: assetColumnCounter,
    };
}
