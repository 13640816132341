import {EstateValue, HoldingAssetSubclassDetails} from "../../models/Holding";
import {NumberInput} from "../../../components";
import React, {useEffect, useState} from "react";
import {EQUITY_DERIVATIVE_ASSET_CLASS4_ID} from '../../models/AssetClassifications';

export type EditableHoldingFields =
    'taxCost'
    | 'investableValue'
    | 'marketValue'
    | 'ticker'
    | 'cusip'
    | 'productName'
    | 'marketEstateValue';

type Props = {
    holdingWithIndex: {
        holding: HoldingAssetSubclassDetails;
        index: number
    },
    label: string,
    onUpdate: (value: number | null | EstateValue,
               index: number,
               valueType: 'taxCost' | 'investableValue' | 'marketValue' | 'marketEstateValue') => void,
    inEstateOwnershipPercentage: number,
    readOnly?: boolean,
    focusOnField?: EditableHoldingFields
};

export const EditHoldingValues = ({
                                      holdingWithIndex,
                                      label,
                                      onUpdate,
                                      inEstateOwnershipPercentage,
                                      readOnly = false,
                                      focusOnField
                                  }: Props) => {

    const [taxCost, setTaxCost] = useState<string | null>(holdingWithIndex.holding.taxCost ?
        holdingWithIndex.holding.taxCost.toString() : null);
    const [marketValue, setMarketValue] = useState<string | null>(holdingWithIndex.holding.marketValue ?
        holdingWithIndex.holding.marketValue.toString() : null);
    const [oldMarketValue, setOldMarketValue] = useState<string | null>(holdingWithIndex.holding.marketValue ?
        holdingWithIndex.holding.marketValue.toString() : null);
    const [investableValue, setInvestableValue] = useState<string | null>(holdingWithIndex
        .holding.investableValue !== null && marketValue ? holdingWithIndex.holding.investableValue.toString() : null);
    const [isInvestableValueRemoved, setIsInvestableValueRemoved] = useState<boolean>(false);
    const [shouldFocus, setShouldFocus] = useState<boolean>(
        focusOnField === 'taxCost' || focusOnField === 'marketValue' || focusOnField === 'investableValue'
    );

    const hasOutOfEstate: boolean = inEstateOwnershipPercentage < 100;

    const outOfEstateOwnershipPercentage: number = 100 - inEstateOwnershipPercentage;

    const isEquityDerivative = holdingWithIndex.holding.assetSubclassDetailsId === EQUITY_DERIVATIVE_ASSET_CLASS4_ID;
    const isEmpty = (value: string | null) => value === null || value === "";


    useEffect(() => {
        setTaxCost(holdingWithIndex.holding.taxCost !== null ? holdingWithIndex.holding.taxCost.toString() : null)
    }, [holdingWithIndex.holding.taxCost]);

    useEffect(() => {
        setMarketValue(holdingWithIndex.holding.marketValue !== null ? holdingWithIndex.holding.marketValue.toString() : null)
    }, [holdingWithIndex.holding.marketValue]);

    useEffect(() => {
        setInvestableValue(holdingWithIndex.holding.investableValue !== null && marketValue ? holdingWithIndex.holding.investableValue.toString() : null)
    }, [holdingWithIndex.holding.investableValue]);

    useEffect(() => {
        if(!(isEquityDerivative && taxCost === '-')){
            onUpdate(taxCost ? parseFloat(taxCost) : null, holdingWithIndex.index, "taxCost")
        }
    }, [taxCost]);

    useEffect(() => {
        if(!(isEquityDerivative && marketValue === '-')) {
            onUpdate(marketValue ? parseFloat(marketValue) : null, holdingWithIndex.index, "marketValue")
        }
    }, [marketValue]);

    useEffect(() => {
        onUpdate(investableValue ? parseFloat(investableValue) : null, holdingWithIndex.index,
            "investableValue")
    }, [investableValue]);

    useEffect(() => {
        if(shouldFocus) {
            const newlyAddedElement = document.getElementById(label);

            if(newlyAddedElement) {
                newlyAddedElement.focus();
            }
        }
    })

    const inEstateMarketValue = (value: number | null) => value ? value * inEstateOwnershipPercentage / 100 : 0;

    const outOfEstateMarketValue = (value: number | null) => value ? value * outOfEstateOwnershipPercentage / 100 : 0;

    function updateMarketAndInvestableValues() {
        const marketValueIsLessThanInvestableValue = marketValue && investableValue &&
            parseFloat(marketValue) < parseFloat(investableValue);
        const marketValueMatchesInvestableValue = investableValue !== marketValue && investableValue === oldMarketValue;

        if(isEquityDerivative || isInvestableValueRemoved || investableValue?.trim().length === 0) {
            setInvestableValue('0.00');
            setIsInvestableValueRemoved(false);
        }
        else if (isEmpty(marketValue) ||
            investableValue === null ||
            marketValueIsLessThanInvestableValue ||
            marketValueMatchesInvestableValue
        ) {
            setInvestableValue(marketValue);
        }

        setOldMarketValue(marketValue);
    }

    function updateMarketAndInvestableValuesWhenOutOfEstate() {
        if (isEmpty(marketValue) && !isEquityDerivative) {
            setInvestableValue(marketValue)
        } else {
            setInvestableValue("0.00")
        }
        setOldMarketValue(marketValue);
    }

    const handleChangeTaxCost = (value: string | number) => setTaxCost(value as string);

    const handleBlurTaxCost = () => {
        setShouldFocus(false);
        updateMarketAndInvestableValues();
    }

    const handleBlurMarketValue = () => {
        setShouldFocus(false);

        hasOutOfEstate
            ? updateMarketAndInvestableValuesWhenOutOfEstate()
            : updateMarketAndInvestableValues();
    };

    const handleChangeMarketValue = (value: string | number) => {
        const inEstateValue = inEstateMarketValue(value as number);
        const outOfEstateValue = outOfEstateMarketValue(value as number);
        const marketEstateValue: EstateValue = {
            inEstateValue: inEstateValue,
            outOfEstateValue: outOfEstateValue,
            totalValue: inEstateValue + outOfEstateValue
        };
        onUpdate(marketEstateValue, holdingWithIndex.index, "marketEstateValue");
        setMarketValue(value as string);
    }

    const handleBlurInvestableValue = () => {
        setShouldFocus(false);

        if (!hasOutOfEstate) {
            updateMarketAndInvestableValues();
        }
    }

    const handleChangeInvestableValue = (value: string | number) => {
        setIsInvestableValueRemoved(investableValue !== null && value === '')
        setInvestableValue(value as string);
    }

    return (<>
        <span role="cell"
              aria-label={`Tax Cost - ${label}`}
              className="textalign-right">
                    <NumberInput
                        id={focusOnField === 'taxCost' ? label : undefined}
                        aria-label={`${label} - Tax Cost`}
                        removeMarginTop
                        onBlur={handleBlurTaxCost}
                        onChangeValue={(_, value) => handleChangeTaxCost(value)}
                        size="small"
                        maxLength={15}
                        allowDecimals
                        formatOptions={{maximumFractionDigits: 2, minimumFractionDigits: 2}}
                        value={taxCost}
                        tabIndex={0}
                        readOnly={readOnly}
                        allowNegativeValues={isEquityDerivative}
                    />
        </span>
        <span role="cell"
              aria-label={`Market Value - ${label}`}
              className="textalign-right">
                    <NumberInput
                        id={focusOnField === 'marketValue' ? label : undefined}
                        aria-label={`${label} - Market Value`}
                        removeMarginTop
                        onBlur={handleBlurMarketValue}
                        onChangeValue={(_, value) => handleChangeMarketValue(value)}
                        size="small"
                        maxLength={15}
                        allowDecimals
                        formatOptions={{maximumFractionDigits: 2, minimumFractionDigits: 2}}
                        value={marketValue}
                        tabIndex={0}
                        readOnly={readOnly}
                        allowNegativeValues={isEquityDerivative}
                    />
        </span>
        <span role="cell"
              aria-label={`Investable Value - ${label}`}
              className="textalign-right">
                    <NumberInput
                        id={focusOnField === 'investableValue' ? label : undefined}
                        aria-label={`${label} - Investable Value`}
                        removeMarginTop
                        onBlur={handleBlurInvestableValue}
                        onChangeValue={(_, value) => handleChangeInvestableValue(value)}
                        size="small"
                        maxLength={15}
                        allowDecimals
                        formatOptions={{maximumFractionDigits: 2, minimumFractionDigits: 2}}
                        value={investableValue}
                        tabIndex={0}
                        readOnly={readOnly || hasOutOfEstate || isEquityDerivative}
                    />
        </span>
    </>)
};
