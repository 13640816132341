import {formatCurrency} from "../../../utils/format";
import {TableActionDropdownMenu} from "../../../components/Table/TableActionDropdownMenu";
import {DropdownItem, Icon} from "../../../components";
import React from "react";
import {TableCell} from "../../../components/Table/TableCell";
import {LifeInsurance} from "../../models/LifeInsurance";
import classNames from "classnames";
import {calculateInEstateFormValueForLifeInsurance} from "../common/AssetSummaryCalculator";
import useProfileEditableState from "../../../hooks/useProfileEditableState";

type AssetSummaryLifeInsuranceProps = {
    lifeInsurance: LifeInsurance;
    onClickEdit: (id: string) => void;
    onClickDelete: (lifeInsurance: LifeInsurance) => void;
    renderLifeInsuranceDetails: (lifeInsurance: LifeInsurance) => React.ReactNode;
    actionsDisabled: boolean;
    gridClassName: string;
    showDeathBenefit?: boolean;
    hideActionMenu?: boolean
};
export const LifeInsuranceRow = ({
                                     lifeInsurance,
                                     onClickEdit,
                                     onClickDelete,
                                     renderLifeInsuranceDetails,
                                     actionsDisabled,
                                     gridClassName,
                                     showDeathBenefit = false,
                                     hideActionMenu = false
                                 }: AssetSummaryLifeInsuranceProps) => {
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    const lifeInsuranceValue = showDeathBenefit ? lifeInsurance.deathBenefitValue : lifeInsurance.cashValue;
    return (<div key={`life-insurance-row-${lifeInsurance.id}`}
                 aria-label={`life-insurance-row-${lifeInsurance.id}`}
                 role="row"
                 className={classNames("assets-grid-table-row", gridClassName)}
                 tabIndex={0}>
        {renderLifeInsuranceDetails(lifeInsurance)}
        <TableCell
            text={formatCurrency(calculateInEstateFormValueForLifeInsurance(lifeInsuranceValue, lifeInsurance.memberOwnerships))}
            className="textalign-right"/>
        {!hideActionMenu && <TableActionDropdownMenu
            disabled={actionsDisabled}
            ariaLabel={`${lifeInsurance.description} Menu`}>
            <DropdownItem
                itemText="Edit Asset"
                value="Edit Asset"
                onClick={() => onClickEdit(lifeInsurance.id)}
            />
            <DropdownItem
                itemText="Delete Asset"
                value="Delete Asset"
                disabled={isProfileWithProposalsOrArchived}
                onClick={() => onClickDelete(lifeInsurance)}
            >
                <Icon name="delete"
                      className="asset-table-delete-icon"/>
            </DropdownItem>
        </TableActionDropdownMenu>}
    </div>);
};
