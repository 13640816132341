import React from "react";
import {formatCurrency} from "../../../utils/format";
import {ActiveFormAsset, InEstateAssetTotals} from "../../clientAssetsSlice";
import {BarChartSummaryTable} from "../../../components/BarChartSidebar/BarChartSummaryTable";
import {AssetsSummary} from "../../models/Assets";
import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {buildSummaryTableData} from "./AssetBarChartUtils";

type AssetBarChartFooterProps = {
    activeFormAsset?: ActiveFormAsset | null,
    inEstateAssetsTotals: InEstateAssetTotals,
    clientAssets: AssetsSummary,
    investorGroup: InvestorGroupType,
    title: string
}

export const AssetBarChartFooter = ({
                                        activeFormAsset,
                                        inEstateAssetsTotals,
                                        clientAssets,
                                        investorGroup,
                                        title
                                    }: AssetBarChartFooterProps) => {
    return <>
        <div role="figcaption">
            <span className="condensed-subtitle margintop-xxxl">{title}</span>
            <div className="h1 margintop-sm marginbottom-xxxl"
                 aria-label={'Total Net Assets'}>{formatCurrency(inEstateAssetsTotals.totalNetValue)}</div>
        </div>
        {
            activeFormAsset &&
            <BarChartSummaryTable {...buildSummaryTableData(activeFormAsset, clientAssets, inEstateAssetsTotals, investorGroup)} />
        }
    </>;
}

