import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '../store/store';
import {AssetsSummary, AssetType} from "./models/Assets";
import {
    calculateAllAccountsInEstateTotalMarketValue,
    calculateEquityCompensationValue,
    calculateInEstateCNWEquityCompensationTotalValue,
    calculateInEstateEquityCompensationTotalValue,
    calculateInEstateFormValueForLifeInsurance,
    calculateInEstateLifeInsuranceTotalValue,
    calculatePersonalAssetInEstateTotalValue
} from "./AssetSummary/common/AssetSummaryCalculator";

export type ActiveFormAsset = {
    id?: string,
    assetType: AssetType,
    inEstateValue: number,
    description: string,
    hasInEstateOwnership: boolean,
};

export type ClientAssetsState = AssetsSummary & {
    activeFormAsset: ActiveFormAsset | null,
}

export const defaultAssetsState: ClientAssetsState = {
    accounts: {
        totalPresentValue: 0,
        totalInvestableValue: 0,
        totalRiskControlPresentValue: 0,
        totalInvestableTaxValue: 0,
        totalInvestableTaxDeferredValue: 0,
        taxableRatio: 0,
        totalPresentTaxLiabilityForDeferredAccounts: 0,
        totalPresentTaxLiabilityForTaxableAccounts: 0,
        totalPresentTaxLiabilityForAllAccounts: 0,
        totalPresentTaxLiabilityForDeferredAccountsFundedByPortfolio: 0,
        totalPresentTaxLiabilityForTaxableAccountsFundedByPortfolio: 0,
        totalPresentTaxLiabilityForAllAccountsFundedByPortfolio: 0,
        data: []
    },
    personalAssets: {
        totalPresentValue: 0,
        data: []
    },
    generalInflows: {
        totalPresentValue: 0,
        data: []
    },
    socialSecurities: {
        totalPresentValue: 0,
        data: []
    },
    lifeInsurances: {
        data: []
    },
    equityCompensations: {
        totalPresentValue: 0,
        totalAfterTaxUnVestedValue: 0,
        totalAfterTaxVestedValue: 0,
        data: []
    },
    investmentProgram: null,
    partiallyOwnedInvestmentAccounts: [],
    personalLiabilities: [],
    totalGrossValue: 0,
    totalNetValue: 0,
    totalPresentValue: 0,
    totalLiabilitiesValue: 0,
    liabilitiesValue: {
        inEstateValue: 0,
        outOfEstateValue: 0,
        totalValue: 0
    },
    activeFormAsset: null,
    totalTaxLiabilities: {
        totalPresentTaxLiabilityForDeferredAccounts: 0,
        totalPresentTaxLiabilityForAllAccounts: 0,
        totalPresentTaxLiabilityForAllAccountsFundedByPortfolio: 0,
        totalPresentTaxLiabilityForDeferredAccountsFundedByPortfolio: 0,
        totalPresentTaxLiabilityForTaxableAccounts: 0,
        totalPresentTaxLiabilityForTaxableAccountsFundedByPortfolio: 0
    }
}

function initialState(): ClientAssetsState {
    return defaultAssetsState;
}

const clientAssetsSlice = createSlice({
    name: 'assets',
    initialState,
    reducers: {
        setClientAssets: (state, action: PayloadAction<AssetsSummary>) => ({
            ...state,
            ...action.payload,
        }),
        setActiveFormAsset: (state, action: PayloadAction<ActiveFormAsset | null>) => {
            state.activeFormAsset = action.payload;
        },
        resetClientAssets: () => defaultAssetsState
    }
});

const calculateAssetTotal = (activeFormAsset: ActiveFormAsset | null,
                             assetType: ActiveFormAsset["assetType"],
                             assets: AssetWithTotal[],
                             totalValue: number): number => {
    const activeAsset = activeFormAsset?.assetType === assetType ? activeFormAsset : null;
    let total = totalValue;
    if (activeAsset) {
        total += isNaN(activeAsset.inEstateValue) ? 0 : activeAsset.inEstateValue;
        const isEditingExistingAsset = !!activeAsset.id;
        if (isEditingExistingAsset) {
            const originalAsset = assets.find((asset) => asset.id === activeAsset.id);
            total -= originalAsset ? originalAsset.value : 0;
        }
    }
    return total;
};

export type InEstateAssetTotals = {
    accountTotal: number,
    personalAssetTotal: number,
    futureInflowsTotal: number,
    lifeInsuranceTotal: number,
    equityCompensationTotal: number,
    personalLiabilitiesTotal: number,
    totalNetValue: number,
    totalGrossValue: number,
}

type AssetWithTotal = {
    id: string,
    value: number
}

const getInEstateAssetTotals = (assets: ClientAssetsState): InEstateAssetTotals => {
    const accountTotal = getAccountTotal(assets);
    const personalAssetTotal = getPersonalAssetTotal(assets);
    const liabilitiesTotal = getLiabilitiesTotal(assets);
    const lifeInsuranceTotal = getLifeInsuranceTotal(assets);
    const equityCompensationTotal = getEquityCompensationTotal(assets);
    const futureInflowsTotal = getFutureInflowsTotal(assets);

    return {
        accountTotal,
        personalAssetTotal,
        futureInflowsTotal,
        lifeInsuranceTotal,
        equityCompensationTotal,
        personalLiabilitiesTotal: liabilitiesTotal,
        totalNetValue: (accountTotal + personalAssetTotal + futureInflowsTotal + lifeInsuranceTotal + equityCompensationTotal) - liabilitiesTotal,
        totalGrossValue: (accountTotal + personalAssetTotal + futureInflowsTotal + lifeInsuranceTotal + equityCompensationTotal)
    }
}

const getInEstateCnwAssetTotals = (assets: ClientAssetsState, useDeathBenefitForLifeInsurance = false): InEstateAssetTotals => {
    const accountTotal = getAccountTotal(assets);
    const equityCompensationTotal = getEquityCompensationTotalCNW(assets);
    const personalAssetTotal = getPersonalAssetTotal(assets);
    const liabilitiesTotal = getLiabilitiesTotal(assets);
    const lifeInsuranceTotal = getLifeInsuranceTotal(assets, useDeathBenefitForLifeInsurance);

    return {
        accountTotal,
        personalAssetTotal,
        futureInflowsTotal: 0,
        lifeInsuranceTotal,
        equityCompensationTotal,
        personalLiabilitiesTotal: liabilitiesTotal,
        totalNetValue: (accountTotal + personalAssetTotal + lifeInsuranceTotal + equityCompensationTotal) - liabilitiesTotal,
        totalGrossValue: (accountTotal + personalAssetTotal + lifeInsuranceTotal + equityCompensationTotal)
    }
}

function getAccountTotal(assets: AssetsSummary & { activeFormAsset: ActiveFormAsset | null }) {
    const allAccountsInEstateBaseTotal = calculateAllAccountsInEstateTotalMarketValue(
        assets.accounts.data,
        assets.investmentProgram,
        assets.partiallyOwnedInvestmentAccounts)
    const accountsForCalculation = assets.accounts.data.map(asset => ({id: asset.id, value: asset.inEstateValue}));
    return calculateAssetTotal(assets.activeFormAsset, 'standaloneAccount', accountsForCalculation, allAccountsInEstateBaseTotal);
}

function getPersonalAssetTotal(assets: AssetsSummary & { activeFormAsset: ActiveFormAsset | null }) {
    const personalAssetsForCalculation = assets.personalAssets.data.map(asset => ({
        id: asset.id,
        value: asset.inEstateValue
    }));
    const inEstatePersonalAssetTotal = calculatePersonalAssetInEstateTotalValue(assets.personalAssets.data);
    return calculateAssetTotal(assets.activeFormAsset, 'personalAsset', personalAssetsForCalculation, inEstatePersonalAssetTotal);
}

function getLiabilitiesTotal(assets: AssetsSummary & { activeFormAsset: ActiveFormAsset | null }) {
    const liabilitiesForCalculation = assets.personalLiabilities.map(asset => ({
        id: asset.id,
        value: asset.loanBalanceEstateValue.inEstateValue
    }));
    return calculateAssetTotal(assets.activeFormAsset, 'liability', liabilitiesForCalculation, assets.liabilitiesValue.inEstateValue);
}

function getLifeInsuranceTotal(assets: AssetsSummary & { activeFormAsset: ActiveFormAsset | null }, useDeathBenefitForLifeInsurance = false) {
    const lifeInsurancesForCalculation = assets.lifeInsurances.data
        .filter(lifeInsurance => useDeathBenefitForLifeInsurance || lifeInsurance.isCashValueWillFundGoals)
        .map(asset => ({
            id: asset.id,
            value: calculateInEstateFormValueForLifeInsurance(useDeathBenefitForLifeInsurance ? asset.deathBenefitValue : asset.cashValue, asset.memberOwnerships)
        }));
    const lifeInsuranceTotal = calculateInEstateLifeInsuranceTotalValue(assets.lifeInsurances.data, useDeathBenefitForLifeInsurance);

    return calculateAssetTotal(assets.activeFormAsset, 'lifeInsurance', lifeInsurancesForCalculation, lifeInsuranceTotal);
}

function getEquityCompensationTotal(assets: AssetsSummary & { activeFormAsset: ActiveFormAsset | null }) {
    const equityCompensationsForCalculation = assets.equityCompensations.data
        .map(asset => ({
            id: asset.id,
            value: calculateEquityCompensationValue(asset)
        }));
    const equityCompensationTotal = calculateInEstateEquityCompensationTotalValue(assets.equityCompensations.data);

    return calculateAssetTotal(assets.activeFormAsset, 'equityCompensation', equityCompensationsForCalculation, equityCompensationTotal);
}

function getEquityCompensationTotalCNW(assets: AssetsSummary & { activeFormAsset: ActiveFormAsset | null }) {
    const equityCompensationsForCalculation = assets.equityCompensations.data
        .map(asset => ({
            id: asset.id,
            value: asset.afterTaxVestedValue || 0
        }));
    const equityCompensationTotal = calculateInEstateCNWEquityCompensationTotalValue(assets.equityCompensations.data);

    return calculateAssetTotal(assets.activeFormAsset, 'equityCompensation', equityCompensationsForCalculation, equityCompensationTotal);
}

function getFutureInflowsTotal(assets: AssetsSummary & { activeFormAsset: ActiveFormAsset | null }) {
    const generalInflowsForCalculation = assets.generalInflows.data.map(asset => ({
        id: asset.id!,
        value: asset.presentValue
    }));
    const socialSecuritiesForCalculation = assets.socialSecurities.data.map(asset => ({
        id: asset.id!,
        value: asset.presentValue
    }));
    const allFutureInflowsForCalculation = generalInflowsForCalculation.concat(socialSecuritiesForCalculation);
    const futureInflowsBaseTotal = assets.generalInflows.totalPresentValue + assets.socialSecurities.totalPresentValue;
    return calculateAssetTotal(assets.activeFormAsset, 'futureInflow', allFutureInflowsForCalculation, futureInflowsBaseTotal);
}

export const {
    setClientAssets,
    resetClientAssets,
    setActiveFormAsset,
} = clientAssetsSlice.actions;

export const selectClientAssets = (state: RootState) => state.client.assets;

export const selectEquityCompensations = (state: RootState) => state.client.assets?.equityCompensations;

export const selectInEstateAssetTotals = (state: RootState) => getInEstateAssetTotals(state.client.assets!);

export const selectInEstateCnwAssetTotals = (state: RootState) => getInEstateCnwAssetTotals(state.client.assets!);

export const selectInEstateCnwAssetTotalsUsingDeathBenefit = (state: RootState) => getInEstateCnwAssetTotals(state.client.assets!, true);

export const selectActiveFormAsset = (state: RootState) => state.client.assets?.activeFormAsset;

export default clientAssetsSlice.reducer;
