import {ActiveFormAsset, defaultAssetsState, InEstateAssetTotals} from "../../../Assets/clientAssetsSlice";
import {AssetsSummary} from "../../../Assets/models/Assets";
import {InvestorGroupType, MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import {StandaloneAccount} from "../../../Assets/models/StandaloneAccount";
import {PersonalAsset} from "../../../Assets/models/PersonalAsset";
import {GeneralInflow} from "../../../Assets/models/GeneralInflow";
import {SocialSecurity} from "../../../Assets/models/SocialSecurity";
import {InvestmentProgram, LegalAgreement} from "../../../Assets/models/InvestmentProgram";
import {PersonalLiabilitySummary} from "../../../Assets/models/PersonalLiability";
import {LifeInsurance} from "../../../Assets/models/LifeInsurance";
import {assetListData, hasAssets, hasInEstateAssets, hasLiabilities} from "../../../Assets/AssetSummary/common/utils";
import React, {useEffect, useState} from "react";
import {formatCurrency} from "../../../utils/format";
import {
    calculateInEstateTotalValue,
    calculateOutOfEstateTotalValue,
} from "../../../Assets/AssetSummary/common/AssetSummaryCalculator";
import PrintViewWrapper from "../PrintViewWrapper";
import {BarChartSidebar} from "../../../components";
import {createAssetBarChartData} from "../../../Assets/AssetSummary/BarChart/AssetBarChartUtils";
import {AssetBarChartFooter} from "../../../Assets/AssetSummary/BarChart/AssetBarChartFooter";
import {AssetReportList} from "./AssetReportList";
import {LiabilityReportList} from "./LiabilityReportList";
import {LegalEntityReadModel} from "../../../Assets/models/Ownership";
import {
    createLegalEntityTypeSummaries,
    createNonClientMemberSummaries,
    LegalEntityTypeSummary,
    NonClientMemberSummary
} from "../../../Assets/mappers";
import OutOfEstateLegalEntityReportView from "./OutOfEstateLegalEntityReportView";
import OutOfEstateNonClientSummariesView from "./OutOfEstateNonClientSummariesView";
import {setAccordionPreferencesState} from "../../../Assets/common/accordionPreferencesSlice";
import {useAppDispatch} from "../../../store/hooks";
import {
    hasOutOfEstateLegalEntities,
    hasOutOfEstateNonClientSummaries,
    hasSufficientSpace,
    InAndOutEstateAssets,
    mergeAssetSummariesAndLegalEntitiesPages,
    mergeLegalEntitiesAndNonClientPages,
    PAGE_CONSTANTS,
    splitNonClientMemberSummaries,
    splitOutOfEstateLegalEntities
} from "./AssetsReportUtils";
import {EquityCompensationFormData} from "../../../Assets/models/EquityCompensation";

export type AssetSummaryReportViewProps = {
    inEstateAssetsTotals: InEstateAssetTotals,
    activeFormAsset: ActiveFormAsset,
    displayName: string,
    clientAssets: AssetsSummary,
    investorGroup: InvestorGroupType,
    profileId: string,
    memberGroup?: MemberGroup,
    legalEntities: LegalEntityReadModel[],
    isDetailed: boolean
}

type AssetTypeList =
    StandaloneAccount[]
    | PersonalAsset[]
    | GeneralInflow[]
    | SocialSecurity[]
    | LegalAgreement[]
    | PersonalLiabilitySummary[]
    | LifeInsurance[]
    | EquityCompensationFormData[]

export function AssetSummaryReportView({
                                           investorGroup,
                                           profileId,
                                           inEstateAssetsTotals,
                                           activeFormAsset,
                                           displayName,
                                           clientAssets,
                                           memberGroup,
                                           legalEntities,
                                           isDetailed
                                       }: AssetSummaryReportViewProps) {

    const dispatch = useAppDispatch();
    const [assetSummaryReportPages, setAssetSummaryReportPages] = useState<InAndOutEstateAssets[]>([]);

    const hasAccounts = (clientAssetsData: AssetsSummary) => {
        if (!clientAssets) return false;
        return clientAssetsData.accounts?.data?.length > 0;
    }

    const hasInvestmentPrograms = (clientAssetsData: AssetsSummary) => {
        if (!clientAssets) return false;
        return clientAssetsData.investmentProgram && clientAssetsData.investmentProgram.legalAgreements.length > 0
    }

    const hasGeneralInflows = (clientAssetsData: AssetsSummary) => {
        if (!clientAssets) return false;
        return clientAssetsData.generalInflows?.data?.length > 0;
    }

    const hasSocialSecurity = (clientAssetsData: AssetsSummary) => {
        if (!clientAssets) return false;
        return clientAssetsData.socialSecurities?.data?.length > 0;
    }

    const populateAssetsList = (assetType: keyof AssetsSummary, data: AssetTypeList): Partial<AssetsSummary> => {
        switch (assetType) {
            case 'investmentProgram':
                return {
                    investmentProgram: {
                        ...clientAssets.investmentProgram,
                        legalAgreements: data as LegalAgreement[]
                    } as InvestmentProgram
                }
            case 'personalLiabilities':
                return {
                    personalLiabilities: data as PersonalLiabilitySummary[]
                }
            case 'equityCompensations':
                return {
                    equityCompensations: {
                        ...clientAssets.equityCompensations,
                        data:data as EquityCompensationFormData[]
                    }
                }
            default:
                const assetTypeData = clientAssets[assetType];
                if (typeof assetTypeData != 'number') {
                    return {
                        [assetType]: {
                            ...assetTypeData,
                            data
                        }
                    }
                } else {
                    return {}
                }
        }
    };

    function getTotalRows(assetType: string, data: AssetTypeList) {
        let totalRows = 0;
        if (assetType === 'equityCompensations') {
            totalRows = data.length;

            data.forEach(elem => {
                totalRows += 1;
                if("stockOptionsAsOfDate" in elem && elem.stockOptionsAsOfDate != null){
                    totalRows += 1;
                }
                if ("stockGrantsAsOfDate" in elem && elem.stockGrantsAsOfDate != null) {
                    totalRows += 1;
                }
            })
        } else {
            return data.length;
        }
        return totalRows;
    }

    function getCountOfPagesThatCanFit(assetType: string ,data:AssetTypeList, remainingPageSpace: number) {
        if (assetType === 'equityCompensations') {
            let tempSpace = 0, i = 0;
            for (i; i < data.length; i++) {
                const elem = data[i];
                if (tempSpace > remainingPageSpace) break;
                tempSpace += PAGE_CONSTANTS.TABLE_ROW_HEIGHT;
                if ("stockOptionsAsOfDate" in elem && elem.stockOptionsAsOfDate != null) {
                    tempSpace += PAGE_CONSTANTS.TABLE_ROW_HEIGHT;
                }
                if ("stockGrantsAsOfDate" in elem && elem.stockGrantsAsOfDate != null) {
                    tempSpace += PAGE_CONSTANTS.TABLE_ROW_HEIGHT;
                }
            }
            return i;
        } else {
            return Math.floor(remainingPageSpace / PAGE_CONSTANTS.TABLE_ROW_HEIGHT);
        }
    }

    const setPageAssetData = (assetType: keyof AssetsSummary, pageAssets: AssetsSummary, pageScore: number, data: AssetTypeList, calculatedAssetsPages: AssetsSummary[], isNestedAssetType: boolean) => {
        const nestedAssetsHeaderLength = isNestedAssetType ? 1 : 0;
        let totalRows = getTotalRows(assetType, data);

        let updatedPageScore = pageScore + (totalRows + nestedAssetsHeaderLength) * PAGE_CONSTANTS.TABLE_ROW_HEIGHT;
        if (updatedPageScore < PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT) {
            pageAssets = {
                ...pageAssets,
                ...populateAssetsList(assetType, data)
            }
        } else {
            const remainingPageSpace = PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT - pageScore;
            let noOfAssetsPageCanFit = 0;
            if (remainingPageSpace > 0) {
                // If there is enough space to fit 3 assets, slice the 3 and push them to current page
                noOfAssetsPageCanFit = getCountOfPagesThatCanFit(assetType, data, remainingPageSpace);
                if (noOfAssetsPageCanFit > 0) {
                    const assetsThatCanFitInCurrentPage = data.slice(0, noOfAssetsPageCanFit);
                    pageAssets = {
                        ...pageAssets,
                        ...populateAssetsList(assetType, assetsThatCanFitInCurrentPage)
                    };
                }
            }
            // Push Current Page
            calculatedAssetsPages.push(pageAssets);

            // Creating New Page
            updatedPageScore = PAGE_CONSTANTS.PAGE_PADDING_HEIGHT + PAGE_CONSTANTS.SECTION_HEADER_HEIGHT + PAGE_CONSTANTS.ASSET_LIABILITY_BOTTOM_MARGIN;
            pageAssets = defaultAssetsState;

            // For rest of the assets, split the remaining data recursively until all assets are fit into 1 or more pages
            const remainingAssets = data.slice(noOfAssetsPageCanFit);

            if (remainingAssets.length > 0) {

                const result = setPageAssetData(assetType, pageAssets, updatedPageScore, remainingAssets, calculatedAssetsPages, isNestedAssetType);
                updatedPageScore = result.pageScore;
                pageAssets = result.pageAssets;
            }
        }

        return {pageScore: updatedPageScore, pageAssets};
    }

    const getEstimatesForInvestmentsAndAccounts = (assetsData: AssetsSummary, pageScore: number, pageAssets: AssetsSummary, calculatedAssets: AssetsSummary[]) => {
        if (hasAccounts(assetsData) || hasInvestmentPrograms(assetsData)) {
            pageScore = pageScore + PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
            if (hasAccounts(assetsData)) {

                const result = setPageAssetData('accounts', pageAssets, pageScore, assetsData.accounts.data, calculatedAssets, true);
                pageScore = result.pageScore;
                pageAssets = result.pageAssets;
            }

            if (hasInvestmentPrograms(assetsData)) {
                const result = setPageAssetData('investmentProgram', pageAssets, pageScore, (assetsData.investmentProgram?.legalAgreements || []), calculatedAssets, true);
                pageScore = result.pageScore;
                pageAssets = result.pageAssets;
            }
        }
        return {pageScore, pageAssets};
    }

    const getEstimatesForAssets = (assetsData: AssetsSummary, pageScore: number, pageAssets: AssetsSummary, calculatedAssets: AssetsSummary[]) => {
        if (hasAssets(assetsData)) {
            const __ret = getEstimatesForInvestmentsAndAccounts(assetsData, pageScore, pageAssets, calculatedAssets);
            pageScore = __ret.pageScore;
            pageAssets = __ret.pageAssets;

            if (assetsData.equityCompensations.data.length > 0) {
                pageScore = pageScore + PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
                const result = setPageAssetData('equityCompensations', pageAssets, pageScore, assetsData.equityCompensations.data, calculatedAssets, false);
                pageScore = result.pageScore;
                pageAssets = result.pageAssets;
            }

            if (hasGeneralInflows(assetsData) || hasSocialSecurity(assetsData)) {
                pageScore = pageScore + PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
                if (hasGeneralInflows(assetsData)) {
                    const result = setPageAssetData('generalInflows', pageAssets, pageScore, assetsData.generalInflows.data, calculatedAssets, true);
                    pageScore = result.pageScore;
                    pageAssets = result.pageAssets;
                }

                if (hasSocialSecurity(assetsData)) {
                    const result = setPageAssetData('socialSecurities', pageAssets, pageScore, assetsData.socialSecurities.data, calculatedAssets, true);
                    pageScore = result.pageScore;
                    pageAssets = result.pageAssets;
                }
            }

            if (assetsData.personalAssets.data.length > 0) {
                pageScore = pageScore + PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
                const result = setPageAssetData('personalAssets', pageAssets, pageScore, assetsData.personalAssets.data, calculatedAssets, false);
                pageScore = result.pageScore;
                pageAssets = result.pageAssets;
            }
            if (assetsData.lifeInsurances.data.length > 0) {
                pageScore = pageScore + PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
                const result = setPageAssetData('lifeInsurances', pageAssets, pageScore, assetsData.lifeInsurances.data, calculatedAssets, false);
                pageScore = result.pageScore;
                pageAssets = result.pageAssets;
            }

        }
        return {pageScore, pageAssets};
    }

    const splitAssets = (assetsData: AssetsSummary) => {
        const calculatedAssets: AssetsSummary[] = [];
        let pageScore = PAGE_CONSTANTS.PAGE_PADDING_HEIGHT + PAGE_CONSTANTS.SECTION_HEADER_HEIGHT;
        let pageAssets: AssetsSummary = {...defaultAssetsState};

        const __ret = getEstimatesForAssets(assetsData, pageScore, pageAssets, calculatedAssets);
        pageScore = __ret.pageScore;
        pageAssets = __ret.pageAssets;

        if (hasLiabilities(assetsData)) {
            if (hasAssets(assetsData)) {
                pageScore += PAGE_CONSTANTS.ASSET_LIABILITY_BOTTOM_MARGIN;
            }
            pageScore += PAGE_CONSTANTS.ASSET_LIABILITY_BOTTOM_MARGIN;
            pageScore += PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;

            const result = setPageAssetData('personalLiabilities', pageAssets, pageScore, assetsData.personalLiabilities, calculatedAssets, false);
            pageAssets = result.pageAssets;
        }

        if (pageAssets !== defaultAssetsState) {
            calculatedAssets.push(pageAssets);
        }

        let spaceLeft: number = 0;
        if (PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT < pageScore) spaceLeft = 2 * PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT - pageScore;
        else spaceLeft = PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT - pageScore;
        return {assetSummariesData: calculatedAssets, remainingSpaceAfterSplittingAssets: spaceLeft};
    }

    const [firstLegalEntityPageIndex, setFirstLegalEntityPageIndex] = useState<number>();
    const [legalEntityTypeSummaries, setLegalEntityTypeSummaries] = useState<LegalEntityTypeSummary[]>([]);
    const [nonClientMemberSummaries, setNonClientMemberSummaries] = useState<NonClientMemberSummary[]>([]);
    const [allItems, setAllItems] = useState<any[]>([]);

    function expandNonClientAccordions(nonClientNamesToExpand: string[]) {
        const nonClientAccordionNames = nonClientNamesToExpand.map((accordionName) => accordionName + "-report");
        dispatch(setAccordionPreferencesState({
            accordionId: "AssetSummaryOutOfEstateNonClientAccordionReport",
            state: {
                expandedItems: [...nonClientAccordionNames]
            }
        }));
    }

    useEffect(() => {
        if(!isDetailed) {
            // assets collapsed view
            setAssetSummaryReportPages([{
                assetsSummary: clientAssets,
                legalEntitySummary: undefined,
                nonClientMemberSummary: undefined
            }])
        } else {
            //asset summaries expanded view
            const { assetSummariesData, remainingSpaceAfterSplittingAssets } = splitAssets(clientAssets);
            const assetSummariesPages: InAndOutEstateAssets[] = [];

            assetSummariesData.forEach((summary) => {
                assetSummariesPages.push({
                    assetsSummary: summary,
                    legalEntitySummary: undefined,
                    nonClientMemberSummary: undefined,
                });
            });
            if (hasSufficientSpace(remainingSpaceAfterSplittingAssets)) {
                setFirstLegalEntityPageIndex(assetSummariesPages.length - 1);
            } else {
                setFirstLegalEntityPageIndex(assetSummariesPages.length);
            }
            // legal entities
            let tempLegalEntityTypeSummaries = createLegalEntityTypeSummaries('AssetSummary', clientAssets, legalEntities);
                const {
                    legalEntityTypeSummariesArray,
                remainingSpaceAfterLegalEntities
            } = splitOutOfEstateLegalEntities(tempLegalEntityTypeSummaries, remainingSpaceAfterSplittingAssets);
                const legalEntitySummariesPages: InAndOutEstateAssets[] = [];
                legalEntityTypeSummariesArray.forEach((summary) => {
                    legalEntitySummariesPages.push({
                        assetsSummary: undefined,
                        legalEntitySummary: summary,
                        nonClientMemberSummary: undefined
                    })
                });
            const legalEntityNames = [...tempLegalEntityTypeSummaries.map((summary: { entityType: any; }) => summary.entityType)];
            setLegalEntityTypeSummaries(tempLegalEntityTypeSummaries)
            // non client
            let tempNonClientMemberSummaries = createNonClientMemberSummaries('AssetSummary', clientAssets);
            const nonClientMemberSummariesArray: Array<NonClientMemberSummary[]> = splitNonClientMemberSummaries(tempNonClientMemberSummaries, remainingSpaceAfterLegalEntities);

            const nonClientMemberSummariesPages: InAndOutEstateAssets[] = [];
            nonClientMemberSummariesArray.forEach((summary) => {
                nonClientMemberSummariesPages.push({
                    assetsSummary: undefined,
                    legalEntitySummary: undefined,
                    nonClientMemberSummary: summary
                })
            })

            const nonClientNames = [
            ...tempNonClientMemberSummaries.map((summary: { memberName: any; }) => `${summary.memberName}-assets-summary`)
            ];
            setAllItems([...legalEntityNames, ...nonClientNames]);
            setNonClientMemberSummaries(tempNonClientMemberSummaries);
            const allAssetsPages: InAndOutEstateAssets[] = [...assetSummariesPages, ...legalEntitySummariesPages, ...nonClientMemberSummariesPages];

            if (assetSummariesPages.length > 0 && legalEntitySummariesPages.length > 0 && hasSufficientSpace(remainingSpaceAfterSplittingAssets)) {
                mergeAssetSummariesAndLegalEntitiesPages(assetSummariesPages, legalEntitySummariesPages, allAssetsPages);
                }
            if (legalEntitySummariesPages.length > 0 && nonClientMemberSummariesPages.length > 0 && hasSufficientSpace(remainingSpaceAfterLegalEntities)) {
                mergeLegalEntitiesAndNonClientPages(legalEntitySummariesPages, nonClientMemberSummariesPages, allAssetsPages, assetSummariesPages.length);
            }
            if (assetSummaryReportPages.length === 0) {
                setAssetSummaryReportPages(allAssetsPages);
                expandNonClientAccordions(nonClientNames);
            }
        }
    }, [clientAssets]);

    const getInestateTotalValue = (pageIndex: number) => {
        return (pageIndex === 0) ? <div>You
            have <b>{formatCurrency(calculateInEstateTotalValue(clientAssets))}</b> in
            assets:
        </div> : ""
    }

    return (
        <>
            {assetSummaryReportPages.map((clientAssetsPage, pageIndex) => (
                <PrintViewWrapper pageNumber={pageIndex} displayName={displayName} key={pageIndex}>
                    <article className="asset-summary-page layout-split-left">
                        <BarChartSidebar
                            data={createAssetBarChartData(inEstateAssetsTotals, activeFormAsset)}
                            noDataText='No assets have been captured yet.'
                            displayName={displayName}
                            title={`Your Assets${pageIndex > 0 ? ' (continued)' :''}`}>
                            <AssetBarChartFooter
                                clientAssets={clientAssets}
                                inEstateAssetsTotals={inEstateAssetsTotals}
                                activeFormAsset={activeFormAsset}
                                investorGroup={investorGroup}
                                title='Total Net Assets'
                            />
                        </BarChartSidebar>
                        <section>
                            <div data-testid="asset-summary-content">
                                {clientAssetsPage && <div className="asset-summary-content">
                                    {clientAssetsPage.assetsSummary && hasAssets(clientAssetsPage.assetsSummary!) &&
                                        <>
                                            <div className="section-header">
                                                <h3>
                                                    {getInestateTotalValue(pageIndex)}
                                                    {!hasInEstateAssets(clientAssetsPage.assetsSummary!) &&
                                                        <div className="condensed-subtitle">Begin by entering assets or
                                                            entities</div>}
                                                </h3>
                                            </div>
                                            <AssetReportList
                                                assetsDataForPage={clientAssetsPage.assetsSummary!}
                                                investorGroup={investorGroup}
                                                profileId={profileId}
                                                memberGroup={memberGroup}
                                                allAssets={clientAssets}
                                                isDetailed={isDetailed}
                                            />
                                        </>
                                    }
                                    {clientAssetsPage.assetsSummary && assetListData(clientAssetsPage.assetsSummary!).hasInEstatePersonalLiability &&
                                        <>
                                            {(clientAssets.personalLiabilities.findIndex(liab => liab.id === clientAssetsPage.assetsSummary!.personalLiabilities[0].id)) === 0 &&
                                                <h3>
                                                    You
                                                    have <b>{formatCurrency(clientAssets.liabilitiesValue.inEstateValue)}</b> in
                                                    personal
                                                    liabilities:
                                                </h3>
                                            }
                                            <LiabilityReportList
                                                allAssets={clientAssets}
                                                clientAssets={clientAssetsPage.assetsSummary!}
                                                isDetailed={isDetailed}
                                            />
                                        </>
                                    }
                                    {
                                        hasOutOfEstateLegalEntities(clientAssetsPage) &&
                                        <>
                                            {
                                                pageIndex === firstLegalEntityPageIndex &&
                                                <div className="section-header">
                                                    <h3>
                                                        Out of estate assets
                                                        total <b>{formatCurrency(calculateOutOfEstateTotalValue(clientAssets)).trim()}</b>:
                                                    </h3>
                                                </div>
                                            }

                                            <OutOfEstateLegalEntityReportView
                                                allItems={allItems}
                                                legalEntitiesSummariesForPage={clientAssetsPage.legalEntitySummary!}
                                                allLegalEntityTypeSummaries={legalEntityTypeSummaries}
                                                accordionIdForOutOfEstate={isDetailed ? "AssetSummaryOutOfEstateAccordionReport" : "AssetSummaryOutOfEstateCollapsedReport"}
                                            />
                                        </>
                                    }
                                    {
                                        hasOutOfEstateNonClientSummaries(clientAssetsPage) &&
                                        <OutOfEstateNonClientSummariesView
                                            allItems={allItems}
                                            nonClientMemberSummariesForPage={clientAssetsPage.nonClientMemberSummary!}
                                            allNonClientMemberSummaries={nonClientMemberSummaries}
                                            accordionIdForView={isDetailed ? "AssetSummaryOutOfEstateNonClientAccordionReport" : "AssetSummaryOutOfEstateNonClientCollapsedReport"}
                                        />
                                    }
                                </div>
                                }
                            </div>
                        </section>
                    </article>
                </PrintViewWrapper>
            ))}
        </>
    )
}
