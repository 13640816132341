import {Loader, MenuDivider, Table} from "xps-react";
import React, {useState} from "react";
import {ProfileResponse} from "../../models/ProfileResponse";
import {Link, useHistory} from "react-router-dom";
import {
    dashboardVariables,
    PARTNER_DASHBOARD_MIN_ROWS,
    PARTNER_DASHBOARD_PAGE_SIZES
} from "../../PartnerDashboard/partnerDashboardConstants";
import {wealthManagementApiClient} from "../../WealthManagementApiClient";
import {setProposals} from "../../Proposals/proposalsSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {clientManagementApiClient} from "../../ClientManagementApiClient";
import CustomModal from "../../../components/Modal/Custom/CustomModal";
import {Button, DropdownItem} from "../../../components";
import ErrorModal, {emptyErrorModalData, ErrorModalData} from "../../../components/Modal/Error/ErrorModal";
import {ProfileProposalActionMenu} from "./ProfileProposalActionMenu";
import {toDisplayDateFormat} from "../../../utils/dateUtils";
import {selectReleaseToggles} from "../../../ReleaseToggles/releaseTogglesSlice";

type ProfileDetailsProposalsProps = {
    profileId: string,
    onCreateNewProposal: () => void,
    onViewArchivedProposals: () => void,
    onUpdateProposal: (proposalId: string) => void,
    proposals: ProfileResponse[],
    isLoading: boolean,
    isArchiveReadOnly?: boolean
};

export const ProfileDetailsProposals: React.FC<ProfileDetailsProposalsProps> =
    ({
         profileId,
         onCreateNewProposal,
         onUpdateProposal,
         onViewArchivedProposals,
         proposals,
         isLoading,
         isArchiveReadOnly = false
     }) => {

        const dispatch = useAppDispatch();
        const [errorModalData, setErrorModalData] = useState<ErrorModalData>(emptyErrorModalData);
        const [selectedProposalId, setSelectedProposalId] = useState<string>('');
        const [openDeleteModal, setOpenDeleteModal] = useState(false);
        const [openArchiveProposalModal, setOpenArchiveProposalModal] = useState(false);
        const [openApproveProposalModal, setOpenApproveProposalModal] = useState(false);
        const history = useHistory();
        const releaseToggles = useAppSelector(selectReleaseToggles);

        function fetchProposals() {
            clientManagementApiClient.getProposals(profileId)
                .then(newProposals => dispatch(setProposals(newProposals)))
                .catch(error => console.error('Could not fetch proposals', error.message));
        }

        const handleDuplicateProposalRetry = () => {
            setErrorModalData(emptyErrorModalData);
            handleDuplicateProposal(selectedProposalId);
            setSelectedProposalId('');
        }

        const handleDuplicateProposal = (proposalId: string) => {
            return wealthManagementApiClient.cloneProposal(proposalId)
                .then((responseStatus) => {

                    if (responseStatus.isSuccessStatusCode) {
                        fetchProposals();
                    } else {
                        setSelectedProposalId(proposalId);
                        setErrorModalData({
                            isOpen: true,
                            header: "Unable to duplicate proposal",
                            message: "If this error remains after retrying to save, please contact the help desk",
                            operationId: responseStatus.traceId
                        })
                    }
                }).catch(error => {
                    console.error('Could not duplicate proposal', error.message)
                });
        }

        const handleArchiveProposal = () => {
            setOpenArchiveProposalModal(false);
            const proposalToArchive = proposals.find(proposal => proposal.id === selectedProposalId)
            if (proposalToArchive) {
                return clientManagementApiClient.putProfile({...proposalToArchive, archived: true}).then(() => {
                    fetchProposals();
                }).catch(error => {
                    console.error('Could not archive proposal', error.message)
                })
            }
        }

        const handleApproveProposal = () => {
            setOpenApproveProposalModal(false);
            const proposalToApprove = proposals.find(proposal => proposal.id === selectedProposalId)
            if (proposalToApprove) {
                return wealthManagementApiClient.promoteProposal(proposalToApprove.id, profileId).then(() => {
                    history.push(`/profile/${proposalToApprove.id}`);
                }).catch(error => {
                    console.error('Could not approve proposal', error.message)
                })
            }
        }

        const handleDeleteProposal = () => {
            setOpenDeleteModal(false);
            return wealthManagementApiClient.deleteProposal(selectedProposalId).then(() => {
                fetchProposals();
            }).catch(error => {
                console.error('Could not delete proposal', error.message)
            })
        }

        return (
            <React.Fragment>
                <ProposalList proposals={proposals} onUpdateProposal={onUpdateProposal}
                              handleDuplicateProposal={handleDuplicateProposal}
                              handleArchiveProposal={(proposalId: string) => {
                                  setSelectedProposalId(proposalId);
                                  setOpenArchiveProposalModal(true);
                              }}
                              handleApproveProposal={(proposalId: string) => {
                                  setSelectedProposalId(proposalId);
                                  setOpenApproveProposalModal(true);
                              }}
                              onDelete={(proposalId: string) => {
                                  setSelectedProposalId(proposalId);
                                  setOpenDeleteModal(true);
                              }}
                              isLoading={isLoading}
                              isArchiveReadOnly={isArchiveReadOnly}/>
                <Button
                    id="CreateNewProposal"
                    includeRef={false}
                    icon="left"
                    className="create-new-proposal"
                    kind="borderless"
                    iconName="add_circle_outline"
                    size="medium"
                    tabIndex={0}
                    type="button"
                    onClick={onCreateNewProposal}
                    disabled={isArchiveReadOnly}
                >
                    <span className="new-client-text">CREATE NEW PROPOSAL</span>
                </Button>
                <Button
                    id="ViewArchived"
                    includeRef={false}
                    icon="left"
                    className="create-new-proposal"
                    kind="borderless"
                    iconName="state_history"
                    size="medium"
                    tabIndex={0}
                    type="button"
                    onClick={onViewArchivedProposals}
                >
                    <span className="new-client-text">VIEW ARCHIVED</span>
                </Button>

                {isLoading ? <div className="loading-proposals"><Loader message="Loading" size="md"/></div> : null}

                <CustomModal isOpen={openDeleteModal}
                             title='Delete this proposal?'
                             content='The proposal and all associated data will be deleted permanently.'
                             onClickCancel={() => setOpenDeleteModal(false)}
                             onClickConfirm={() => handleDeleteProposal()}
                             cancelText='CANCEL'
                             confirmText='DELETE PROPOSAL'
                />

                <CustomModal isOpen={openArchiveProposalModal}
                             title='Archive this proposal?'
                             content='Are you sure you want to archive the selected proposal?'
                             onClickCancel={() => setOpenArchiveProposalModal(false)}
                             onClickConfirm={() => handleArchiveProposal()}
                             cancelText='CANCEL'
                             confirmText='ARCHIVE PROPOSAL'
                />
                {releaseToggles?.enableProposalApproval &&
                    <CustomModal isOpen={openApproveProposalModal}
                                 title={'Approve ' + (proposals.find(proposal => proposal.id === selectedProposalId)?.displayName || 'this Proposal') + '?'}
                                 content='Are you sure you want to approve this Proposal? The current Profile and other related Proposals will be archived and this Proposal will become the new active Profile.'
                                 onClickCancel={() => setOpenApproveProposalModal(false)}
                                 onClickConfirm={() => handleApproveProposal()}
                                 cancelText='CANCEL'
                                 confirmText='APPROVE PROPOSAL'
                    />
                }

                <ErrorModal onClickRetry={handleDuplicateProposalRetry} onClickCancel={() => {
                    setErrorModalData(emptyErrorModalData);
                }} errorModalData={errorModalData}/>
            </React.Fragment>
        );
    }

type ProposalListProps = {
    onUpdateProposal: (proposalId: string) => void,
    handleDuplicateProposal: (proposalId: string) => Promise<void>
    handleArchiveProposal: (proposalId: string) => void
    handleApproveProposal: (proposalId: string) => void
    onDelete: (proposalId: string) => void,
    proposals: ProfileResponse[],
    isLoading: boolean,
    isArchiveReadOnly?: boolean
};

const ProposalList: React.FC<ProposalListProps> = ({
                                                       onUpdateProposal,
                                                       handleDuplicateProposal,
                                                       handleArchiveProposal,
                                                       handleApproveProposal,
                                                       onDelete,
                                                       proposals,
                                                       isLoading,
                                                       isArchiveReadOnly = false
                                                   }) => {

    const isEnabledActivatePortfolioReserve = useAppSelector(selectReleaseToggles)?.enableActivePortfolioReserve;

    function loadStatus() {
        if (!isLoading) {
            return "This profile has no proposals"
        }
        return null;
    }

    function proposalActionMenu(proposal: ProfileResponse) {

        return (
            <ProfileProposalActionMenu
                dropdownItems={[
                    <DropdownItem
                        key={`viewProposalDetails ${proposal.id}`}
                        aria-label="viewProposalDetails"
                        className="viewProposalDetails"
                        onClick={() => onUpdateProposal(proposal.id)}
                        itemText="Proposal Settings"
                        value="ProposalSettings"
                    />,
                    <DropdownItem
                        key={`duplicateProposal ${proposal.id}`}
                        aria-label="duplicateProposal"
                        className="viewProposalDetails"
                        onClick={() => {
                            handleDuplicateProposal(proposal.id)
                        }}
                        itemText="Duplicate Proposal"
                        value="DuplicateProposal"
                        disabled={isArchiveReadOnly}
                    />,
                    <DropdownItem
                        key={`approveProposal ${proposal.id}`}
                        aria-label="approveProposal"
                        className="viewProposalDetails"
                        onClick={() => {
                            handleApproveProposal(proposal.id)
                        }}
                        itemText="Approve Proposal"
                        value="ApproveProposal"
                        disabled={isArchiveReadOnly}
                    />,
                    <MenuDivider
                        data-testid="menuDivider"
                        className="menuItem"
                        itemText=""
                        value="dontuseme"
                    />,
                    <DropdownItem
                        key={`archiveProposal ${proposal.id}`}
                        aria-label="archiveProposal"
                        className="viewProposalDetails"
                        onClick={() => {
                            handleArchiveProposal(proposal.id)
                        }}
                        itemText="Archive Proposal"
                        value="ArchiveProposal"
                        disabled={isArchiveReadOnly}
                    />,
                    <DropdownItem
                        key={`deleteProposal ${proposal.id}`}
                        aria-label="deleteProposal"
                        className="viewProposalDetails"
                        onClick={() => {
                            onDelete(proposal.id)
                        }}
                        itemText="Delete Proposal"
                        value="DeleteProposal"
                        disabled={isArchiveReadOnly}
                    />
                ]}
            />
        );
    }

    return (
        <div className="table-spacing">
            <Table
                id="CurrentProposalsTable"
                caption="Current Proposals"
                data={proposals}
                minRows={PARTNER_DASHBOARD_MIN_ROWS}
                filterable={false}
                noDataText={loadStatus()}
                pageSizeDropUp={true}
                pageSizeOptions={PARTNER_DASHBOARD_PAGE_SIZES}
                rowHeight="medium"
                showPagination={false}
                sortable={false}
                defaultPageSize={dashboardVariables.PARTNER_DASHBOARD_DEFAULT_ROW}
                columns={[
                    {
                        Header: () => (
                            <span>Current Proposals</span>
                        ),
                        accessor: "displayName",
                        Cell: ({original}: { original: ProfileResponse }) => (
                            <span className={"proposal-name"}>
                               <Link role="link" to={`/Profile/${original.id}/ClientProfile/FamilyTree`}>
                                   {original.displayName}
                               </Link>
                           </span>
                        ),
                        minWidth: 448
                    },
                    {
                        Header: () => (
                            <span>Portfolio Reserve</span>
                        ),
                        show: isEnabledActivatePortfolioReserve,
                        Cell: ({original}: { original: ProfileResponse }) => (
                            <span data-testid="portfolio-reserve" className="display-flex align-items-center ">
                            {original.isActivePortfolioReserve ?
                                <><span style={{backgroundColor: "#05676e", marginRight: "6px"}}
                                        className="portfolio-reserve-status-bullet"/> <span
                                    className="paddingleft-sm">ON</span></>
                                :
                                <><span style={{backgroundColor: "grey", marginRight: "6px"}}
                                        className="portfolio-reserve-status-bullet"/>
                                    <span className="paddingleft-sm">OFF</span></>
                            }
                            </span>
                        ),
                    },
                    {
                        Header: () => (
                            <span>Updated</span>
                        ),
                        accessor: "lastModifiedByName",
                        Cell: ({original}: { original: ProfileResponse }) => (
                            <React.Fragment>{original.lastModifiedByName + ", " + toDisplayDateFormat(original.lastModifiedDate)}</React.Fragment>
                        ),
                        minWidth: 155
                    },
                    {
                        Header: () => (
                            <span>Created</span>
                        ),
                        accessor: "createdByName",
                        Cell: ({original}: { original: ProfileResponse }) => (
                            <React.Fragment>{original.createdByName + ", " + toDisplayDateFormat(original.createdDate)}</React.Fragment>
                        ),
                        minWidth: 155
                    },
                    {
                        Cell: ({original}: { original: ProfileResponse }) =>
                            proposalActionMenu(original),
                        className: "overflow-visible",
                        accessor: "proposalId",
                        minWidth: 32,
                        maxWidth: 32,
                    }]}
            />
        </div>
    );
};
