import React, {useContext, useEffect, useMemo, useState} from "react";
import {assetsApiClient} from "../AssetsApiClient";
import {useHistory, useParams} from "react-router-dom";
import {LifeInsuranceForm} from "./LifeInsuranceForm";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {
    LifeInsurance,
    LifeInsuranceFormData,
    lifeInsuranceTypes,
    UpdateLifeInsuranceRequest
} from "../models/LifeInsurance";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import EditFormContainer from "../EditFormContainer";
import {RouteWithAssetId} from "../../routes/types";
import {mapToOwnershipDetailsFormData} from "../Ownership/mappers";
import {LegalEntityFormData, OwnershipWriteModel} from "../models/Ownership";
import AssetsViewContext from "../common/AssetsViewContext";

const mapToLifeInsuranceFormData = (lifeInsurance: LifeInsurance) => {
    const {ordinal, ...lifeInsuranceWithoutOrdinal} = lifeInsurance;
    return lifeInsuranceWithoutOrdinal;
}

const EditLifeInsurance: React.FC = () => {
    const history = useHistory();
    const {assetId: lifeInsuranceAssetId} = useParams<RouteWithAssetId>();
    const profile = useAppSelector(selectProfile);
    const viewType = useContext(AssetsViewContext);    const [memberGroup, setMemberGroup] = useState<MemberGroup>();
    const [lifeInsurance, setLifeInsurance] = useState<LifeInsurance>();
    const [legalEntities, updateLegalEntities] = useState<LegalEntityFormData[]>([]);

    const defaultLifeInsurance = useMemo(
        (): LifeInsuranceFormData => {
            return {
                id:'',
                description: "Life Insurance",
                type: lifeInsuranceTypes.TERM,
                insuredMemberId: memberGroup?.primaryMember.id!,
                deathBenefitValue: 0,
                insuranceCompanyName: '',
                issueDate: '',
                cashValue: 0,
                isCashValueWillFundGoals: false,
                expirationDate:'',
                term: 0,
                beneficiaries: [],
            }
        },
        [memberGroup]
    );

    useEffect(() => {
        if (!profile) return;
        Promise.all([clientManagementApiClient.getMemberGroup(profile.id),
            assetsApiClient.getLegalEntities(profile.id)])
            .then(([memberGroupResponse, legalEntitiesResponse]) => {
                setMemberGroup(memberGroupResponse);
                updateLegalEntities(legalEntitiesResponse);
            }).catch(error => console.error('Could not fetch life insurance data', error.message));
    }, [profile]);

    useEffect(() => {
        if (!profile) return;
        assetsApiClient.getLifeInsurance(profile.id, lifeInsuranceAssetId)
            .then((lifeInsuranceAsset) => {
                setLifeInsurance({...lifeInsuranceAsset})
            });
    }, [profile.id, lifeInsuranceAssetId])

    if (!memberGroup || !profile || !lifeInsurance) {
        return <LoadingIndicator/>;
    }

    const onSaveClick = async (formData: LifeInsuranceFormData & OwnershipWriteModel) => {
        const request: UpdateLifeInsuranceRequest = {
            ...formData,
            id: lifeInsurance.id,
            profileId: lifeInsurance.profileId
        }
        await assetsApiClient.putLifeInsurance(request);
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
    };

    const onDelete = async () => {
        const response = await assetsApiClient.deleteLifeInsurance(profile.id, lifeInsuranceAssetId);
        if (response.status === 200) {
            history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
        }
    }

    return <div className='life-insurance'>
        <EditFormContainer
            modalTitle="Asset"
            assetType="Life Insurance"
            handleDelete={onDelete}
            assetDescription={lifeInsurance.description}
            form={(handleCancel) => (
                <LifeInsuranceForm
                    initialLifeInsurance={mapToLifeInsuranceFormData(lifeInsurance)}
                    defaultLifeInsurance={defaultLifeInsurance}
                    initialOwnershipDetails={mapToOwnershipDetailsFormData(lifeInsurance)}
                    handleSave={onSaveClick}
                    memberGroup={memberGroup}
                    profileId={profile.id}
                    formatTitle={description => `Edit ${description}`}
                    handleCancel={handleCancel}
                    legalEntities={legalEntities}
                    updateLegalEntities={updateLegalEntities}
                />
            )}
        />
    </div>;
};

export default EditLifeInsurance;
