import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {RouteWithId} from "../../../routes/types";
import {wealthManagementApiClient} from "../../../ClientManagement/WealthManagementApiClient";
import {resetAccordionTableState} from "../../../components/AccordionTable/AccordionTableSlice";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import AssetRelianceContentReport from "./AssetRelianceContentReport";
import {clientManagementApiClient} from "../../../ClientManagement/ClientManagementApiClient";
import {selectAssetRelianceReportResponse, setAssetRelianceReportResponse} from "./AssetRelianceReportSlice";

interface AssetRelianceWrapperProps {
    displayName: string,
    id: string,
    isFutureValueOfExcessRequired: boolean
}


const AssetRelianceWrapper = ({displayName, id, isFutureValueOfExcessRequired}: AssetRelianceWrapperProps) => {

    return (<>
            <div className="asset-reliance">
                <AssetRelianceContentReport
                    id={id}
                    displayName={displayName}
                    isFutureValueOfExcessRequired={isFutureValueOfExcessRequired}
                />
            </div>
        </>
    )
}

type assetRelianceContainerProps = {
    displayName: string,
    isFutureValueOfExcessRequired: boolean
}

const AssetRelianceContainer = ({displayName,isFutureValueOfExcessRequired}: assetRelianceContainerProps) => {
    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const [isLoading, setIsLoading] = useState(true);
    const assetRelianceData = useAppSelector(selectAssetRelianceReportResponse);
    useEffect(() => {
        if (id) {
            setIsLoading(true);
            Promise.all([
                wealthManagementApiClient.getAssetReliance(id),
                clientManagementApiClient.getInvestorGroup(id),
            ]).then(([
                         assetRelianceResponse,
                         investorGroupResponse,
                     ]) => {
                dispatch(setAssetRelianceReportResponse({...assetRelianceResponse,investorGroupResponse:investorGroupResponse}));
                setIsLoading(false);
            }).catch((error) => {
                console.error('Could not fetch asset reliance', error.message)
            });
        }
        return () => {
            dispatch(resetAccordionTableState());
        };
    }, [id]);

    useEffect(() => {
        if (isLoading && assetRelianceData.profileId === id) {
            setIsLoading(false);
        }
    }, [assetRelianceData])

    if (isLoading) {
        return <LoadingIndicator/>
    }

    return <AssetRelianceWrapper displayName={displayName}
                                 id={id}  isFutureValueOfExcessRequired={isFutureValueOfExcessRequired}/>
}

export default AssetRelianceContainer;