import {get, post} from "../core/api";
import {
    DisclaimerTableData,
    GenerateReportRequest,
    ReportConfig,
    SendReportRequest,
    SendReportResponse
} from "./models/Reporting";
import {App_Conf} from "../core/app_conf";


const generateReport = async (reqBody: GenerateReportRequest, profileId: string): Promise<Blob> => {
    const response = await post(`/advisory/reporting/generateReport/${profileId}`, reqBody);
    return response.blob();
}

const sendReport = async (profileId: string, reqBody: SendReportRequest): Promise<SendReportResponse> => {
    const response = await post(`/advisory/reporting/upload/${profileId}`, reqBody);
    return response.json();
}

const getReportConfig = async (): Promise<ReportConfig> => {
    const response = await fetch(`${App_Conf.APP_URL}/advisory/reportConfig`);
    return response.json();
}

const getBlobFromReportObjectUrl = async (generatedReportUrl: string): Promise<Blob> => {
    const response = await fetch(generatedReportUrl);
    return response.blob();
}

export const getDisclaimerData = async () : Promise<DisclaimerTableData> => {
    const response = await get(`/advisory/reporting/getDisclaimerData`);
    return response.json();
}

export const reportingApiClient = {
    generateReport,
    getReportConfig,
    sendReport,
    getBlobFromReportObjectUrl,
    getDisclaimerData
};
