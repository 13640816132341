import {Icon, Popover} from "../../../components";
import React, {ChangeEvent, FocusEvent, useEffect, useState} from "react";
import {DataDisplayView, ProposedAllocationEntry} from "../ReviseAssetAllocation";
import {isDollarView} from "./reviseAssetAllocationUtils";
import {ProposedAllocationInput} from "./ProposedAllocationInput";

export type ReviseAllocationInputProps = {
    proposedInvestableValue: number;
    assetSubclassName: string;
    isLocked?:boolean;
    showIpcRangeWarning: boolean;
    onUpdateProposedAllocationEntry: (value: ProposedAllocationEntry) => void;
    disabled: boolean;
    displayView: DataDisplayView;
    minValue: number,
    totalProposedAllocationValue: number,
    isProposedAllocationValuesLoadedFirstTime: boolean
};

const ReviseAllocationInput = (props: ReviseAllocationInputProps) => {
    const {
        proposedInvestableValue,
        assetSubclassName,
        isLocked,
        showIpcRangeWarning,
        onUpdateProposedAllocationEntry,
        disabled,
        displayView,
        minValue,
        totalProposedAllocationValue,
        isProposedAllocationValuesLoadedFirstTime
    } = props;
    const [proposedAllocationValue, setProposedAllocationValue] = useState<string | null>("0");
    const [showProposedAllocationValueWithTwoDecimalPoints, setIsProposedAllocationValuesLoadedFirstTime] = useState<boolean>(isProposedAllocationValuesLoadedFirstTime);

    useEffect(() => {
        setProposedAllocationValue(proposedInvestableValue?.toString() || null);
    }, [proposedInvestableValue]);

    let exactValue: string | null = "";
    if (isDollarView(displayView)) {
        exactValue = showProposedAllocationValueWithTwoDecimalPoints ?  Number(proposedAllocationValue).toFixed(0) : proposedAllocationValue
    } else {
        exactValue = showProposedAllocationValueWithTwoDecimalPoints ? Number(proposedAllocationValue).toFixed(2) : proposedAllocationValue
    }


    const handleChangeValue = (_event: ChangeEvent<HTMLInputElement>, value: number | string) => {
        setProposedAllocationValue(value as string);
        setIsProposedAllocationValuesLoadedFirstTime!(false);
    }

    const calculateProposedPercentage = (proposedAllocationAmount: number) => {
        if (totalProposedAllocationValue !== 0) {
            let quotient = (proposedAllocationAmount / totalProposedAllocationValue);
            return (+quotient * 100);
        }
        return 0;
    }

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        const {value} = event.target;
        const formattedValue = value.replace(/[$,]/g, '');

        if (isDollarView(displayView)) {
            if (Number(formattedValue).toFixed(0) === Number(proposedInvestableValue).toFixed(0)) return;
        } else {
            if (Number(formattedValue).toFixed(2) === Number(proposedInvestableValue).toFixed(2)) return;
        }

        setProposedAllocationValue(formattedValue);

        if (parseFloat(formattedValue) === proposedInvestableValue) return;

        let proposedAllocationEntry: ProposedAllocationEntry;
        if (isDollarView(displayView)) {
            proposedAllocationEntry = {
                assetSubClass: assetSubclassName,
                proposedAllocationPercent: null,
                proposedAllocationAmount: parseFloat(formattedValue) || 0,
                excludeSubclass: false,
                excludedProposedAllocation: calculateProposedPercentage(parseFloat(formattedValue)),
                editedManually: true
            };
        } else {
            proposedAllocationEntry = {
                assetSubClass: assetSubclassName,
                proposedAllocationPercent: parseFloat(value.trim()) || 0,
                proposedAllocationAmount: null,
                excludeSubclass: false,
                excludedProposedAllocation: parseFloat(value.trim()) || 0,
                editedManually: true
            };
        }
        onUpdateProposedAllocationEntry(proposedAllocationEntry);
    }

    return (
        <div className="display-flex justify-content-between align-content-center">
            {(disabled && isLocked)?
                <Popover
                    aria-label={`${assetSubclassName} Locked Message`}
                    content="This value is locked. To change this value, unlock the asset subclass, then change it manually."
                    direction="top"
                    width="259px"
                >
                    <ProposedAllocationInput
                        displayView={displayView}
                        assetSubclassName={assetSubclassName}
                        minValue={minValue}
                        totalProposedAmount={totalProposedAllocationValue}
                        value={exactValue}
                        onChangeValue={handleChangeValue}
                        onBlur={handleBlur}
                        showIpcRangeWarning={showIpcRangeWarning}
                        disabled={disabled}/>
                </Popover> :
                <>
                    <ProposedAllocationInput
                        displayView={displayView}
                        assetSubclassName={assetSubclassName}
                        minValue={minValue}
                        totalProposedAmount={totalProposedAllocationValue}
                        value={exactValue}
                        onChangeValue={handleChangeValue}
                        onBlur={handleBlur}
                        showIpcRangeWarning={showIpcRangeWarning}
                        disabled={disabled}
                    />
                    {(showIpcRangeWarning && !disabled) &&
                        <Popover
                            aria-label={`${assetSubclassName} IPC Out Of Range Warning Message`}
                            content="This value is out of IPC range. Proposed allocation will still be able to be saved."
                            direction="bottom"
                            width="203px"
                        >
                            <Icon className="ipc-range-info-icon" name="status_warning" ariaHidden={false}
                                  ariaLabel="IPC Out Of Range Info Icon"/>
                        </Popover>
                    }
                </>
            }
        </div>
    );
};


export default ReviseAllocationInput;
