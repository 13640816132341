import React, {ReactElement} from "react";
import {TableDisplay, TableRow} from "./TableDisplay";
import {COLOR_NT_GREY} from "../../constants/colors";
import {ExpandableTableRow} from "./ExpandableTableRow";
import {CellClassParameters, TableRowComponent} from "./TableRowComponent";
import useAccordionTableToggle from "./useAccordionTableToggle";
import {Mapping as ClassNamesMapping} from "classnames";

const DEFAULT_CONTENT_DECORATOR = <ValueType, TR extends TableRow<ValueType>>(
    decoratedRows: DecoratedTableRow<ValueType, TR>[]
): ReactElement => {
    return <>{decoratedRows.map(decoratedRow => decoratedRow.reactElement)}</>;
}

const DEFAULT_ROW_DECORATOR = <ValueType, TR extends TableRow<ValueType>>(
    _index: number,
    decoratedRow: DecoratedTableRow<ValueType, TR>
): DecoratedTableRow<ValueType, TR> => {
    return {
        row: decoratedRow.row,
        reactElement: decoratedRow.reactElement
    }
};

export type DecoratedTableRow<ValueType, TR extends TableRow<ValueType> = TableRow<ValueType>> = {
    row: TR;
    reactElement: ReactElement;
}

export type AccordionTableProps<ValueType, TR extends TableRow<ValueType>> = {
    accordionTableId: string;
    additionalCellClasses?: (params: CellClassParameters) => ClassNamesMapping[];
    collapsedAccentColor: string;
    expandedAccentColor?: string;
    contentDecorator?: (decoratedRows: DecoratedTableRow<ValueType, TR>[]) => ReactElement;
    ariaLabel: string;
    titleCellWidth: number;
    tableDisplayData: TableDisplay<ValueType, TR>;
    onTitleCellMouseEnter?: (row: TR, rowIndex?: number) => void;
    onTitleCellMouseLeave?: (row: TR) => void;
    rowDecorator?: (index: number, decoratedRow: DecoratedTableRow<ValueType, TR>) => DecoratedTableRow<ValueType, TR>;
    valueColumnSize?: number
};

export const AccordionTable = <ValueType, TR extends TableRow<ValueType>>({
                                                                              accordionTableId,
                                                                              additionalCellClasses,
                                                                              collapsedAccentColor,
                                                                              contentDecorator = DEFAULT_CONTENT_DECORATOR,
                                                                              ariaLabel,
                                                                              titleCellWidth,
                                                                              tableDisplayData,
                                                                              onTitleCellMouseEnter,
                                                                              onTitleCellMouseLeave,
                                                                              valueColumnSize,
                                                                              rowDecorator = DEFAULT_ROW_DECORATOR,
                                                                              expandedAccentColor = COLOR_NT_GREY,
                                                                          }: AccordionTableProps<ValueType, TR>) => {
    const {isExpanded, toggleExpanded} = useAccordionTableToggle(accordionTableId);

    const renderChildRows = () => tableDisplayData.rows.map((row, rowIndex) =>
        rowDecorator(
            rowIndex,
            {
                row: row,
                reactElement: <ExpandableTableRow key={row.uniqueIdentifier}
                                                  accordionTableId={accordionTableId}
                                                  additionalCellClasses={additionalCellClasses}
                                                  ariaLabel={ariaLabel}
                                                  row={row}
                                                  rowIndex={rowIndex}
                                                  titleCellWidth={titleCellWidth}
                                                  onTitleCellMouseEnter={onTitleCellMouseEnter}
                                                  onTitleCellMouseLeave={onTitleCellMouseLeave}
                                                  valueColumnSize={valueColumnSize}
                />
            }
        )
    );
    const childRows = contentDecorator(renderChildRows());
    return <>
        <TableRowComponent
            row={{
                accentColor: isExpanded ? expandedAccentColor : collapsedAccentColor,
                label: tableDisplayData.header.label,
                subtitle: tableDisplayData.header.subtitle,
                values: tableDisplayData.header.values,
                uniqueIdentifier: 'header-row',
                children: []
            }}
            additionalCellClasses={additionalCellClasses}
            isSummaryRow={true}
            ariaLabel={ariaLabel}
            titleCellWidth={titleCellWidth}
            onClick={tableDisplayData.rows.length > 0 ? toggleExpanded : undefined}
            valueColumnSize={valueColumnSize}
            isExpanded={isExpanded}
        />
        {isExpanded && childRows}
    </>;
}